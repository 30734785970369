import { BaseSerializer } from "./baseSerializer";
import {PersonSerializer} from "./personSerializer";
import { Inspection } from "../inspection.model";
import { LocationSerializer } from "./locationSerializer";
import { InspectedEntitySerializer } from "./inspectedEntitySerializer";

export class InspectionSerializer extends BaseSerializer {

    public deserialize( data ): any {

        let inspection = new Inspection();

        this._copyAttributes(inspection, data);

        let serializer = new PersonSerializer();
        const personData = (data.inspected_by.person != null)
          ? data.inspected_by.person
          : data.inspected_by;
        const person = serializer.deserialize(personData);
        inspection.inspected_by = person;

        inspection.is_baseline = data.is_baseline;

        serializer = new LocationSerializer();
        const location = serializer.deserialize(data.location);
        inspection.location = location;

        serializer = new InspectedEntitySerializer();
        const entities = serializer.deserializeList(data.inspected_entities);
        inspection.inspected_entities = entities;

        return inspection;

    }

}