import React, { useEffect, useState } from "react";
import EmptyEditPage from '../../shared/components/forms/empty/EmptyEditPage';
import EmptyList from '../../shared/components/forms/empty/EmptyList';
import { ConfirmationDialog } from '../../shared/components/page/popup-dialog/ConfirmationDialog';
import PageHeader from "../../shared/components/page/headers/PageHeader";
import SurveyCampaignList from "./components/employee-survey-campaign-list/SurveyCampaignList";
import { SurveyService } from "../../shared/services/surveys/surveyService";
import useAppStore from "../../appStore";
import { useTranslation } from "react-i18next";


const SurveysPage = () => {

    // The spaces-relationship-card-list of Posts retrieved from the Server
    const [items, setItems] = useState<any[]>([]);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [triggerRefresh, setTriggerRefresh] = useState(0)
    const {selectedLocation} = useAppStore()
    const {t} = useTranslation();

    // The Post being edited.
    const [itemToEdit, setItemToEdit] = useState(null);
    const [pageTitle, setPageTitle] = useState('New Work Item');

    // Controls the display of the Edit form.
    const [isEditing, setEditing] = useState(false);

    //  Called when the User clicks the Send Survey button.
    const handlerSendSurveyButtonClicked = () => {
        setIsConfirmOpen(true)
    }

    const handleSendSurveys = () => {

        const service = new SurveyService()
        service.sendSurveys(selectedLocation.id).then((response) =>{
            setTriggerRefresh(triggerRefresh + 1)
        })

    }


    //  Called from the Edit Post form when the user takes a final action {Create, Save as Draft, Cancel}
    const handleItemEditorAction = (action) => {
        setEditing(false)
    }

    return (
        <>
            {!isEditing && (
                <>
                    <PageHeader title={t('surveys')}
                                actionButtonName={t('send-survey')}
                                onActionClicked = {handlerSendSurveyButtonClicked} />

                    <ConfirmationDialog isOpen={isConfirmOpen}
                                        setIsOpen={setIsConfirmOpen}
                                        message='Send Surveys?'
                                        description='This will send the survey to the employees at the selected site.'
                                        onConfirmClicked={handleSendSurveys}
                    />
                    <SurveyCampaignList triggerRefresh={triggerRefresh}/>
                </>
            )}
            {isEditing && (
                <>
                    <PageHeader title={pageTitle}
                                showAction={false} />

                    <EmptyEditPage inputItem = {itemToEdit}
                                   onActionClicked = {handleItemEditorAction} />
                </>
            )}
        </>
    )

}

export default SurveysPage
