import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme, useMediaQuery
} from "@mui/material";
import useAppStore from "../../../../../../appStore";
import SinglePhotoUploaderViewer from "../../../../../../shared/components/media/media-viewers/photos/SinglePhotoUploaderViewer";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";
import { Work } from "../../../../../../shared/models/work.model";
import InputMask from "react-input-mask";
import countryCodes from "./country_codes";
import UserPermissionsPanel from "./permissions/UserPermissionsPanel";
import DepartmentPanel from "./departments/DepartmentPanel";
import Grid from "@mui/material/Grid";
import ModalFooterActions from "../../../../../../shared/components/buttons/ModalFooterActions";
import { useTranslation } from "react-i18next";


const EmployeeForm = (props) => {
  const {
    employeeToEdit,
    onSave,
    onClose,
    positions,
    error,
    isEdit,
    profileMode,
    quickCreate = false,
  } = props;

  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { selectedLocation } = useAppStore();
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState(employeeToEdit.person?.first_name);
  const [lastName, setLastName] = useState(employeeToEdit.person?.last_name);
  const [personalEmail, setPersonalEmail] = useState(employeeToEdit.person?.email);
  const [workEmail, setWorkEmail] = useState(employeeToEdit.email);
  const [selectedPersonCountryCode, setPersonSelectedCountryCode] = useState(employeeToEdit.user?.country_code || '1');
  const [selectedEmployeeCountryCode, setEmployeeSelectedCountryCode] = useState(employeeToEdit?.country_code || '1');
  const [employeePhoneNumber, setEmployeePhoneNumber] = useState(employeeToEdit?.user?.phone_number);
  const [personPhoneNumber, setPersonPhoneNumber] = useState(employeeToEdit.person?.phone_number);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const [formError, setFormError] = useState('');
  const [employeeMedia, setEmployeeMedia] = useState(employeeToEdit.person.photo);
  const [photoUrl, setPhotoUrl] = useState(employeeToEdit.person.photo_url);
  const uploadUrl = process.env.REACT_APP_API_FACILITIES_SERVICE_URL + '/media/upload/';

  useEffect(() => {
    employeeToEdit.person.photo = employeeMedia || null;
  }, [employeeMedia]);

  useEffect(() => {
    if (employeeToEdit.works_at.length !== 0) {
      setSelectedPosition(employeeToEdit.works_at[0].position.id);
    } else {
      employeeToEdit.setLocation(selectedLocation.id);
    }
  }, []);

  const handleFirstNameChange = (event) => {
    employeeToEdit.person.first_name = event.target.value;
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    employeeToEdit.person.last_name = event.target.value;
    setLastName(event.target.value);
  };

  const handlePersonCountryCodeChange = (event) => {
      employeeToEdit.user.country_code = event.target.value;
      setPersonSelectedCountryCode(event.target.value);
  };

  const handlePersonPhoneNumberChange = (event) => {
      employeeToEdit.person.phone_number = event.target.value.replace(/[\s()-]/g, '');
      setPersonPhoneNumber(event.target.value);
  };

  const handleEmployeeCountryCodeChange = (event) => {
      employeeToEdit.country_code = event.target.value;
      setEmployeeSelectedCountryCode(event.target.value);
  };

  const handleEmployeePhoneNumberChange = (event) => {
      employeeToEdit.phone_number = event.target.value.replace(/[\s()-]/g, '');
      setEmployeePhoneNumber(event.target.value);
  };


  const handlePersonalEmailChange = (event) => {
      employeeToEdit.person.email = event.target.value;
      setPersonalEmail(event.target.value);
  };

  const handleWorkEmailChange = (event) => {
    employeeToEdit.email = event.target.value;
    setWorkEmail(event.target.value);
  };

  const handlePositionChange = (event) => {
    if (employeeToEdit.works_at.length === 0) {
      employeeToEdit.works_at.push(new Work());
      employeeToEdit.works_at[0].position.id = event.target.value.id;
      employeeToEdit.works_at[0].location.id = selectedLocation.id;
    } else {
      employeeToEdit.setPosition(event.target.value);
      employeeToEdit.setLocation(selectedLocation.id);
    }
    setSelectedPosition(event.target.value);
  };

  const getPositionValue = () => {
    return employeeToEdit.works_at.length > 0 ? employeeToEdit.works_at[0].position.id : 0;
  };

  const handleSaveWithValidation = () => {
    if (!firstName) {
      setFormError('Name is required.');
      return;
    }
    if (!lastName) {
      setFormError('Last name is required.');
      return;
    }
    if (!personPhoneNumber) {
      setFormError('Phone Number is required.');
      return;
    }
    if (!selectedPosition) {
      setFormError('Position is required.');
      return;
    }
    if (
      employeeToEdit.user.is_active &&
      employeeToEdit.user.permission_profiles[0].role.id === 0
    ) {
      setFormError('Role is required for active users.');
      return;
    }
    setFormError('');
    onSave();
  };

  return (
    <>
      <div
        className="d-flex flex-column flex-column-fluid mt-5 mx-5"
        style={{ height: 'calc(100vh - 100px)' }}
      >
        {formError && <CustomError error={formError} />}
        {error && <CustomError error={error} />}
        <div className="d-flex flex-row">
          <div
            className="profile-picture-container"
            style={{
              position: 'relative',
              width: '130px',
              height: '130px',
              marginTop: '12px',
              marginRight: '10px',
            }}
          >
            <img
              src={photoUrl || `/media/avatars/blank.png`}
              alt="Profile Picture"
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '15%',
                objectFit: 'cover',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              }}
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.onerror = null;
                target.src = '/media/avatars/blank.png';
              }}
            />
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                backgroundColor: 'white',
                opacity: '65%',
              }}
            >
              <SinglePhotoUploaderViewer
                setMedia={setEmployeeMedia}
                currentUser={user}
                uploadUrl={uploadUrl}
                setPhotoUrl={setPhotoUrl}
              />
            </div>
          </div>

          <div className="d-flex flex-column flex-grow-1">
            <TextField
              label={t('first-name')}
              required
              name="First Name"
              value={firstName}
              onChange={handleFirstNameChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label={t('last-name')}
              required
              name="Last Name"
              value={lastName}
              onChange={handleLastNameChange}
              fullWidth
              margin="normal"
            />
          </div>
        </div>
        <div
          className="d-flex flex-row align-items-end"
          style={{ marginBottom: '8px' }}
        >
          <FormControl
            style={{ width: '38%', maxWidth: '100px', marginRight: '1%' }}
            margin="normal"
          >
            <Select
              value={selectedPersonCountryCode}
              onChange={handlePersonCountryCodeChange}
              disabled={false}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              {countryCodes.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.flag} (+{country.code})
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <InputMask
            mask="(999) 999-9999"
            value={personPhoneNumber}
            onChange={handlePersonPhoneNumberChange}
            disabled={false}
            maskChar=" "
          >
            {() => (
              <TextField
                label={'Personal Phone'}
                required
                name="Person Phone Number"
                fullWidth
                margin="normal"
                disabled={false}
              />
            )}
          </InputMask>
        </div>

        <div
          className="d-flex flex-row align-items-end"
          style={{ marginBottom: '8px' }}
        >
          <FormControl
            style={{ width: '38%', maxWidth: '100px', marginRight: '1%' }}
            margin="normal"
          >
            <Select
              value={selectedEmployeeCountryCode}
              onChange={handleEmployeeCountryCodeChange}
              disabled={false}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              {countryCodes.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.flag} (+{country.code})
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <InputMask
            mask="(999) 999-9999"
            value={employeePhoneNumber}
            onChange={handleEmployeePhoneNumberChange}
            disabled={false}
            maskChar=" "
          >
            {() => (
              <TextField
                label={'Work Phone'}
                required
                name="Work Phone Number"
                fullWidth
                margin="normal"
                disabled={false}
              />
            )}
          </InputMask>
        </div>

        <TextField
          label={'Personal Email'}
          name="Personal Email"
          value={personalEmail}
          onChange={handlePersonalEmailChange}
          fullWidth
          margin="normal"
          disabled={false}
        />

        <TextField
          label={'Work Email'}
          name="Work Email"
          value={workEmail}
          onChange={handleWorkEmailChange}
          fullWidth
          margin="normal"
          disabled={false}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="position-label">{t('position')}</InputLabel>
          <Select
            labelId="position-label"
            required
            name={isEdit ? 'works_at.position' : 'position_id'}
            value={getPositionValue()}
            onChange={handlePositionChange}
            label={t('position')}
          >
            {positions.map((position) => (
              <MenuItem key={position.id} value={position.id}>
                {position.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {(profileMode || quickCreate) && (
          <DepartmentPanel employee={employeeToEdit} />
        )}

        {/* USER PERMISSIONS PANEL */}
        {!profileMode && !quickCreate && (
          isMobile ? (
            <div style={{ paddingBottom: '200px' }}>
              <DepartmentPanel employee={employeeToEdit} />
              <UserPermissionsPanel profile={employeeToEdit} />
            </div>
          ) : (
            <div style={{ paddingBottom: '100px' }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DepartmentPanel employee={employeeToEdit} />
                </Grid>
                <Grid item xs={6}>
                  <UserPermissionsPanel profile={employeeToEdit} />
                </Grid>
              </Grid>
            </div>
          )
        )}
      </div>
      <ModalFooterActions onSave={handleSaveWithValidation} onClose={onClose} />
    </>
  );
};

export default EmployeeForm;

