import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemText, TextField, Box, Tabs, Tab } from '@mui/material';
import { EmployeeService } from "../../../../services/employee/employeeService";
import { Employee } from "../../../../models/employee.model";
import useAppStore from "../../../../../appStore";
import CustomLoadingDots from "../../../page/popup-dialog/CustomLoadingDots";

const OrgChartSelect = ({ employees, onEmployeeSelect, selectedEmployee }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const [searchQuery, setSearchQuery] = useState('');
  const [allEmployees, setAllEmployees] = useState<Employee[]>([]);
  const {selectedLocation} = useAppStore();
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState('unassigned');

  useEffect(() => {
    const fetchEmployees = async () => {
      setIsLoading(true);
      try {
        const service = new EmployeeService();
        const employees = await service.getEmployees(user.organization.id, selectedLocation.id);
        setAllEmployees(employees);
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (tab === 'all') {
      fetchEmployees().then();
    }

  }, [tab]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const filteredEmployees = (tab === 'all' ? allEmployees : employees).filter((employee) => {
    const fullName = `${employee.person.first_name} ${employee.person.last_name}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase());
  });

  if (isLoading) {
    return <CustomLoadingDots />
  }

  return (
    <Box>
      <Tabs value={tab} onChange={handleTabChange} aria-label="employee tabs">
        <Tab value="unassigned" label="Unassigned" />
        <Tab value="all" label="All" />
      </Tabs>
      <TextField
        margin="dense"
        variant="outlined"
        fullWidth
        placeholder="Search employees..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <List>
        {filteredEmployees.map((employee) => (
          <ListItem
            key={employee.id}
            button
            selected={selectedEmployee && selectedEmployee.id === employee.id}
            onClick={() => onEmployeeSelect(employee)}
          >
            <ListItemText primary={employee.person.first_name + " " + employee.person.last_name} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default OrgChartSelect;
