import React, { useState } from "react";
import useAppStore from "../../../../appStore";
import PageHeader from "../../../../shared/components/page/headers/PageHeader";
import ContactsList from "./components/contacts-list/ContactsList";
import { LocationContact } from "../../../../shared/models/locationContact.model";
import EditLocationContact
  from "../../../inspections/components/edit-inspection/components/joint-inspection/components/EditLocationContact";


const LocationContacts = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { toggleMasterRefresh } = useAppStore();
  const [contactToEdit, setContactToEdit] = useState<LocationContact | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogClose = (action = '') => {
    setIsDialogOpen(false);
    setContactToEdit(null);
    if (action === 'save') {
      toggleMasterRefresh();
    }
  };

  const handleNew = () => {
    let contact = new LocationContact();
    contact.organization = user?.organization;
    setContactToEdit(contact);
    setIsDialogOpen(true);
  };

  const handleEdit = (equipment) => {
    setContactToEdit(equipment);
    setIsDialogOpen(true);
  };


  const handleSaveAndRefresh = (response) => {
    handleDialogClose('save');
  }


  return (
    <div className="card w-100">
      <div className="card-body d-flex flex-column" style={{ width: '100%' }}>
        <PageHeader
          title="Location Contacts"
          actionButtonName="New Contact"
          onActionClicked={handleNew}
          divider={false}
        />
        <ContactsList
          canEdit={true}
          onEdit={handleEdit}
        />
        {isDialogOpen && (
          <EditLocationContact contactToEdit={contactToEdit} setContactToEdit={setContactToEdit} open={isDialogOpen} onClose={() => setIsDialogOpen(false)} onSave={handleSaveAndRefresh} />
        )}
      </div>
    </div>
  );
}

export default LocationContacts;




