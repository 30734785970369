import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  AppBar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  TextField,
  Box,
  Chip, Checkbox
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import { Employee } from "../../../../models/employee.model";
import { EmployeeService } from "../../../../services/employee/employeeService";
import useAppStore from "../../../../../appStore";
import EditEmployee from "../../../../../modules/employees/components/edit-employee/EditEmployee";
import EmployeeDetailRow from "../../../page/tables/EmployeeDetailRow";
import { useTranslation } from "react-i18next";

const EmployeeQuickSelect = ({ open, onClose, handleSave, item, caseEmployees=false }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const {t} = useTranslation();
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>(caseEmployees ? item.related_to_employees : item.assigned_to_employees);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [employeeToEdit, setEmployeeToEdit] = useState<Employee | null>(null);
  const [newEmployeeOpen, setNewEmployeeOpen] = useState(false);
  const [employeeSearchResult, setEmployeeSearchResult] = useState<any>([]);
  const [searchFor, setSearchFor] = useState<any>('');


  useEffect(() => {
    const fetchEmployees = async () => {
      setIsLoading(true);
      try {
        const service = new EmployeeService();
        const employees = await service.searchEmployees(user.organization.id, searchFor);
        setEmployeeSearchResult(employees);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (searchFor !== '') {
      fetchEmployees().then();
    }

  }, [searchFor, user.organization.id]);

  const handleSearch = () => {
    setSearchFor(searchQuery);
  };

  const handleRemoveEmployee = (employeeId) => {
    setSelectedEmployees(selectedEmployees.filter(e => e.id !== employeeId));
  };

  const handleNewEmployee = () => {
    let newEmployee = new Employee();
    setEmployeeToEdit(newEmployee);
    setNewEmployeeOpen(true);
  }

  const handlerCloseEditModal = (action, newEmployee) => {
    setNewEmployeeOpen(false);
    setEmployeeSearchResult([]);
    setSearchFor('');
    setSearchQuery('');
    setEmployeeToEdit(null);
    if (action === 'save' && newEmployee) {
      setSelectedEmployees(prevEmployees => [...prevEmployees, newEmployee]);
    }
  }

  const renderSelectedEmployees = () => {
    return selectedEmployees.map(employee => (
      <Chip
        key={employee.id}
        label={employee.person.first_name + " " + employee.person.last_name}
        onDelete={() => handleRemoveEmployee(employee.id)}
        deleteIcon={<CancelIcon />}
        sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
      />
    ));
  };

  const handleListItemClick = (employee) => {
    if (selectedEmployees.find((e) => e.id === employee.id)) {
      setSelectedEmployees(selectedEmployees.filter((e) => e.id !== employee.id));
    } else {
      setSelectedEmployees([...selectedEmployees, employee]);
    }
  };

  const handleSubmit = () => {
    handleSave(item, selectedEmployees);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <div style={{ flexGrow: 1 }}></div>
          <Button color="inherit" variant="contained" onClick={handleNewEmployee} sx={{ color: '#444444' }}>
            {t('create-employee')}
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Box sx={{ border: '1px dashed lightgrey', p: 2, borderRadius: '5px' }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>{t("current-selection")}:</Typography>
              {selectedEmployees.length > 0 ? (
                <Box>
                  {renderSelectedEmployees()}
                </Box>
              ) : (
                <Typography>{t('no-employees-selected')}</Typography>
              )}
            </Box>
              <>

                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    placeholder=""
                    value={searchQuery}
                    style={{width: "80%"}}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyPress={(e) => { if (e.key === 'Enter') handleSearch(); }}
                  />
                  <Button variant="contained" onClick={handleSearch} style={{width: '20%', marginLeft: '5px', height: '45px'}}>{t('search')}</Button>
                </div>




                {employeeSearchResult.length > 0 && (
                  <List>
                    {employeeSearchResult.map((employee) => {
                      const isSelected = !!selectedEmployees.find((e) => e.id === employee.id);
                      return (
                        <ListItem
                          key={employee.id}
                          button
                          selected={isSelected}
                          onClick={() => handleListItemClick(employee)}
                        >
                          {/* Add Checkbox */}
                          <Checkbox
                            edge="start"
                            checked={isSelected}
                            tabIndex={-1}
                            disableRipple
                          />
                          {/* Employee details */}
                          <EmployeeDetailRow employee={employee} />
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </>


          </>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      {newEmployeeOpen && (
        <EditEmployee
          isEdit={false}
          open={open}
          employee_id={employeeToEdit?.id}
          onActionClicked={handlerCloseEditModal}
          quickCreate={true}
        />
      )}
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <Button
          onClick={handleSubmit}
          color="primary"
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeQuickSelect;
