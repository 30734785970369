import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import CaseForm from "./components/details/CaseForm";
import {
    AppBar,
    Button,
    Dialog,
    IconButton,
    Toolbar,
    useMediaQuery,
    useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import useAppStore from "../../../../appStore";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import CaseReadOnly from "./components/details/read-only/CaseReadOnly";
import { CasesService } from "../../../../shared/services/cases/casesService";
import CommentsSection from "../../../../shared/components/comments/CommentsSection";
import ModalFooterActions from "../../../../shared/components/buttons/ModalFooterActions";
import useCasesStore from "../../caseStore";
import ModalFooterSubmitProgress from "../../../../shared/components/buttons/ModalFooterSubmitProgress";


const EditCase = (props) => {

    const {
        editDefault,
        open,
        onActionClicked,
        onDelete,
        incident=false
    } = props

    const {caseToEdit, setCaseToEdit} = useCasesStore();

    const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
    const admin = user.permissions.is_super_user || user?.permissions.hasAccess("Cases", 8);
    const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
    const [error, setError] = useState('');
    const [formErrors, setFormErrors] = useState({
        department: false,
        caseType: false
    });
    const [isEditing, setIsEditing] = useState(editDefault);
    const [isLoading, setIsLoading] = useState(false);
    const [newComments, setNewComments] = useState<any>([])
    const type = 'cases'
    const item = caseToEdit;

    useEffect(() => {
        const fetchDetailCase = async () => {
            try {
                const service = new CasesService();
                const detailCase = await service.getDetailCase(caseToEdit.id);
                setCaseToEdit(detailCase);
            } catch (error) {
                console.error('Failed to fetch case:', error);
            } finally {

            }
        }

        if (caseToEdit.id === null || caseToEdit.id === 0) {
            setCaseToEdit(prevWorkItem => ({
                ...prevWorkItem,
                created_by: user.employee,
                provider: {
                    ...prevWorkItem.provider,
                    id: user.organization?.id || 0
                }
            }));

        } else {
            fetchDetailCase().then();
        }

    }, [caseToEdit.id]);


    useEffect(() => {
        const fetchType = async () => {
            try {
                const service = new CasesService();
                const safetyCaseType = await service.lookupCaseType(1, "safety_incident");
                setCaseToEdit(prev => ({ ...prev, case_type: safetyCaseType[0] }));
                setCaseToEdit(prev => ({ ...prev, department: safetyCaseType[0]?.department }));
            } catch (error) {
                console.error('Failed to fetch case type:', error);
            } finally {

            }
        }

        if (incident) {
            fetchType().then();
        }

    }, [incident]);


    const handleAction = async (action) => {
        caseToEdit.action.name = action;
        try {
            setIsLoading(true)
            const service = new CasesService();
            const isCreating = caseToEdit.id === 0;
            if (isCreating) {
                caseToEdit.status = 'Working';
            }
            caseToEdit.department = caseToEdit.case_type.department;
            const response = isCreating
              ? await service.createCase(caseToEdit)
              : await service.updateCase(caseToEdit);

            setNewComments([]);
            onActionClicked('close')
        } catch (e) {
            console.error('Error in handleSave:', e);
            setError(`Failed to update case: ${e instanceof Error ? e.message : JSON.stringify(e)}`);
            onActionClicked('close')
        } finally {
            setIsLoading(false);
        }
    };

    const validateForm = () => {
        let valid = true;
        if (!caseToEdit.case_type || caseToEdit.case_type.name === '') {
            setFormErrors(prevErrors => ({ ...prevErrors, caseType: true }));
            valid = false;
        }
        return valid;
    };

    const handleSave = () => {
        if (validateForm()) {
            handleAction("submit").then();
        }
    };


    const renderCard = (Component, props = {}) => (
      <Card style={{boxShadow: '0 1px 2px rgba(0,0,0,0.1)', margin: '5px', marginBottom: '10px', borderRadius: '0px'}}>
          <Card.Body>
              <Component {...props} />
          </Card.Body>
      </Card>
    );


    if (error) {
        return <CustomError error={error} />;
    }

    return (
      <Dialog fullScreen open={open} onClose={() => onActionClicked('close')} TransitionComponent={ModalTransition} PaperProps={{ style: { overflowX: 'hidden', backgroundColor: '#e9e9e9' } }}>
          <AppBar sx={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
              <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
                      <CloseIcon />
                  </IconButton>
                  {!isEditing ? (
                    <Button
                      color="inherit"
                      variant="contained"
                      onClick={() => setIsEditing(true)}
                      sx={{ ml: 'auto', color: '#444444' }}
                    >
                        {"Edit"}
                    </Button>
                    ) : (
                      <div style={{marginLeft: 'auto'}}>
                          {caseToEdit.id !== 0 && (
                            <>
                                <Button
                                  color={'inherit'}
                                  variant="contained"
                                  sx={{ color: '#444444' }}
                                  onClick={() => handleAction(caseToEdit.status === 'Closed' ? 'reopen' : 'complete')}
                                >
                                    {caseToEdit.status === 'Closed' ? 'Re-Open' : 'Mark Completed'}
                                </Button>
                            </>
                          )}
                      </div>
                  )}
                  {admin && caseToEdit.id !== 0 && (
                      <Button
                        color={'inherit'}
                        variant="contained"
                        sx={{ color: '#444444', ml: 1 }}
                        onClick={() => onDelete(caseToEdit)}
                      >
                          {'Delete'}
                      </Button>
                    )}
              </Toolbar>
          </AppBar>

          {/* Main Content */}
          <Row className="edit-case-page mt-20 mb-20">
              {isMobile ? (
                <Col xs={12}>
                    {isEditing ? (
                        renderCard(CaseForm, { handleAction, formErrors, setFormErrors, incident })
                        ) : (
                        renderCard(CaseReadOnly, {  })
                        )}
                    {caseToEdit.id !== 0 &&
                      renderCard(CommentsSection, { item, newComments, setNewComments, type})
                    }
                </Col>
              ) : (
                <>
                    <Col xs={12}>
                        {isEditing ? (
                          renderCard(CaseForm, { handleAction, formErrors, setFormErrors, incident })
                        ) : (
                          renderCard(CaseReadOnly, {  })
                        )}
                    </Col>
                    {caseToEdit.id !== 0 &&
                      renderCard(CommentsSection, { item, newComments, setNewComments, type})
                    }
                </>
              )}
          </Row>

          {error && <CustomError error={error} />}
          {isLoading &&
            <ModalFooterSubmitProgress />
          }
          {isEditing && !isLoading &&
            <ModalFooterActions onSave={handleSave} onClose={() => onActionClicked('close')} text={caseToEdit.id === 0 ? "Submit" : "Save"} />
          }
      </Dialog>
    );

}

export default EditCase
