import { UserPermissions } from "./userPermissions.model";
import { Role } from "./role.model";

export class AppUserModel {
  id: number;
  role: Role | null;
  country_code: string;
  phone_number: string;
  is_active: boolean;
  permission_profiles: UserPermissions[];


  constructor() {
    this.id = 0;
    this.role = null;
    this.country_code = '';
    this.phone_number = '';
    this.is_active = false;
    this.permission_profiles = [];
  }

}

