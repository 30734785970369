import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, IconButton, Typography, Box, Dialog } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { TreeView } from '@mui/x-tree-view/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import WindowRoundedIcon from '@mui/icons-material/WindowRounded';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import EditProjectDrawer from "./components/EditProjectDrawer";
import { Part, Task } from "../../../../shared/models/project.model";
import { useSnapshot } from "valtio";
import { projectStore } from "../../projectsStore";

const PlanProject = ({ open, onActionClicked }) => {
  const snap = useSnapshot(projectStore);
  const { selectedProject } = snap;

  const [selectedItem, setSelectedItem] = useState<Part | Task | null>(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleTreeItemClick = (event, nodeId) => {
    event.preventDefault();
    // Ensure we are not clicking on the expand/collapse icon container
    if (!event.target.closest('.MuiTreeItem-iconContainer')) {
      const itemData = findItem(selectedProject?.parts, nodeId);
      setSelectedItem(itemData);
      setDrawerOpen(true);
    }
  };

  const findItem = (parts, id: string) => {
    if (!parts) return null;

    // Check parts
    const part = parts.find(p => `part-${p.name}` === id && p.type === 'part');
    if (part) return part;

    // Check tasks inside each part
    for (const p of parts) {
      if (p.tasks) {
        const task = p.tasks.find(t => `task-${t.name}` === id && t.type === 'task');
        if (task) return task;

        // Check subtasks inside each task
        for (const t of p.tasks) {
          if (t.subtasks) {
            const subtask = t.subtasks.find(st => `subtask-${st.name}` === id && st.type === 'subtask');
            if (subtask) return subtask;
          }
        }
      }
    }

    return null;
  };

  const handleCloseDrawer = () => setDrawerOpen(false);

  const renderTreeItem = (nodes, level = 0) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={renderLabel(nodes.name, getIcon(level), level)}
    >
      {Array.isArray(nodes.children) && nodes.children.map(node => renderTreeItem(node, level + 1))}
    </TreeItem>
  );

  const renderLabel = (name, icon, level) => (
    <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
      {icon}
      <Typography
        variant="body2"
        sx={{ ml: 1, fontWeight: levelStyle(level).fontWeight, fontSize: '1rem' }}
      >
        {name}
      </Typography>
    </Box>
  );

  const levelStyle = (level) => ({ fontWeight: level === 0 ? 'bold' : 'normal' });
  const getIcon = (level) => level === 0 ? <AppsRoundedIcon /> : level === 1 ? <WindowRoundedIcon /> : <SquareRoundedIcon />;

  const handleNewSegment = () => {
    const newPart: Part = {
      name: '',
      tasks: [],
      type: 'part',
    };

    setSelectedItem(newPart);
    setDrawerOpen(true);
  };

  return (
    <Dialog fullScreen open={open} onClose={() => onActionClicked('close')} TransitionComponent={ModalTransition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6">
            Project Planning Tool
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <Typography sx={{ mt: 3, fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '10px' }} variant="h5">
          {selectedProject?.name}
        </Typography>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeSelect={handleTreeItemClick}
          sx={treeViewStyle}
        >
          {selectedProject?.parts?.map(part =>
            renderTreeItem({
              id: `part-${part.name}`,
              name: part.name,
              children: part.tasks.map(task => ({
                id: `task-${task.name}`,
                name: task.name,
                children: task.subtasks?.map(subtask => ({
                  id: `subtask-${subtask.name}`,
                  name: subtask.name
                })) || []
              }))
            })
          )}
          <TreeItem
            key="add-new-stage"
            nodeId="add-new-stage"
            label={<AddNewSegmentLabel />}
            onClick={handleNewSegment}
          />
        </TreeView>
        <EditProjectDrawer selectedItem={selectedItem} isDrawerOpen={isDrawerOpen} handleCloseDrawer={handleCloseDrawer} />
      </Box>
    </Dialog>
  );
};

const AddNewSegmentLabel = () => (
  <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
    <AddCircleOutlineRoundedIcon sx={{ mr: 1 }} />
    <Typography variant="body2" sx={{ flexGrow: 1, fontWeight: 'lighter', fontSize: '.90rem' }}>
      Add New Segment
    </Typography>
  </Box>
);

const treeViewStyle = {
  height: 'calc(100vh - 64px)',
  flexGrow: 1,
  overflowY: 'auto',
  '& .MuiTreeItem-root': {
    borderBottom: '1px solid rgba(0, 0, 0, .1)'
  },
  '& .MuiTreeItem-content': {
    padding: '4px 0',
    '&:hover': { backgroundColor: 'rgba(0, 0, 0, .04)' }
  },
  '& .MuiTreeItem-group': {
    marginLeft: 0,
    '& .MuiTreeItem-root': { paddingLeft: '20px' }
  }
};

export default PlanProject;






