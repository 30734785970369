import React, { useEffect, useState, useCallback, useRef } from "react";
import PageHeader from "../../shared/components/page/headers/PageHeader";
import useInspectionsStore from "./inspectionsStore";
import EditAreaInspectionPage from "./components/edit-inspection/EditAreaInspection";
import { Inspection } from "../../shared/models/inspection.model";
import InspectionSummaryTiles from "./components/inspections-list/components/summary/InspectionSummaryTiles";
import { InspectionService } from "../../shared/services/inspections/inspectionService";
import useAppStore from "../../appStore";
import { InspectionSummary } from "../../shared/models/inspectionSummary.model";
import CustomLoadingDots from "../../shared/components/page/popup-dialog/CustomLoadingDots";
import InspectionsCardList from "./components/inspections-list/InspectionsCardList";
import LoadMoreButton from "../../shared/components/buttons/LoadMoreButton";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch, Typography, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";

const InspectionsPage = ( {jointOnly=false} ) => {
    const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
    const { selectedLocation, masterRefresh, toggleMasterRefresh } = useAppStore();
    const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
    const service = new InspectionService();
    const { reset, setHandleEditItem } = useInspectionsStore();
    const { t } = useTranslation();
    const [includeRegular, setIncludeRegular] = useState<boolean>(!jointOnly ? true : false);
    const [includeJoint, setIncludeJoint] = useState<boolean>(true);
    const [includeBaseline, setIncludeBaseline] = useState<boolean>(!jointOnly ? true : false);


    const [state, setState] = useState<any>({
        inspections: [],
        inspectionToEdit: null,
        open: false,
        isLoading: false,
        summary: new InspectionSummary(),
        page: 0
    });

    const showMoreButtonRef = useRef<HTMLDivElement>(null);

    const fetchData = async () => {
        const limit = 10;
        const offset = state.page * limit;

        setState(prevState => ({ ...prevState, isLoading: true }));
        try {
            const summaryData = await service.getInspectionSummary(user.organization.id, selectedLocation.id);
            const inspectionsData = await service.getInspections(user.organization.id, selectedLocation.id, offset, limit, includeRegular, includeJoint, includeBaseline);

            setState(prevState => ({
                ...prevState,
                summary: summaryData,
                inspections: state.page === 0 ? inspectionsData : [...prevState.inspections, ...inspectionsData],
                isLoading: false
            }));
        } catch (error) {
            console.error(error);
            setState(prevState => ({ ...prevState, isLoading: false }));
        }
    };

    useEffect(() => {
        setHandleEditItem(handleEditItem);
    }, []);

    useEffect(() => {


        if (selectedLocation.id !== 0) {
            fetchData().then();
        }

    }, [user, state.page, masterRefresh]);

    useEffect(() => {

        setState(prevState => ({
            ...prevState,
            summary: new InspectionSummary(),
            inspections: [],
            isLoading: true,
            page: 0
        }));

        if (selectedLocation.id !== 0) {
            fetchData().then();
        }

    }, [selectedLocation.id, includeRegular, includeJoint, includeBaseline]);



    const handleNewInspectionClicked = useCallback(() => {
        reset();
        setState(prevState => ({ ...prevState, inspectionToEdit: new Inspection(), open: true }));
    }, [reset]);

    const handleEditItem = useCallback((inspection) => {
        setState(prevState => ({ ...prevState, inspectionToEdit: inspection, open: true }));
    }, []);

    const handleShowMore = useCallback(() => {
        setState(prevState => ({ ...prevState, page: prevState.page + 1 }));
    }, []);

    const handleDeleteItem = useCallback(async (inspectionToDelete) => {
        if (inspectionToDelete) {
            try {
                await service.deleteInspection(inspectionToDelete.id);
                toggleMasterRefresh();
            } catch (error) {
                console.error(error);
            }
        }
    }, [service]);

    return (
      <div>
          {!jointOnly &&
            <PageHeader
              title={t('inspections')}
              actionButtonName={t('new-inspection')}
              onActionClicked={handleNewInspectionClicked}
            />
          }

          {selectedLocation.id === 0 || (state.isLoading && state.inspections.length === 0) ? (
            <div style={{ minHeight: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CustomLoadingDots />
            </div>
          ) : (
            <>
                <div style={{ marginBottom: "120px", width: "100%" }} ref={showMoreButtonRef}>
                    {!jointOnly &&
                      <InspectionSummaryTiles summary={state.summary} />
                    }
                    <div className={'d-flex flex-row justify-content-between align-items-center mt-10 mb-5'}>
                        {!isMobile &&
                          <div>
                              <h3 style={{ margin: 0, color: "#444" }}>Recent Inspections</h3>
                          </div>
                        }
                        <Box display="flex" alignItems="center" gap={2}>
                            <FormControlLabel
                              control={
                                  <Switch
                                    checked={includeRegular}
                                    onChange={() => setIncludeRegular(!includeRegular)}
                                    color="primary"
                                  />
                              }
                              label={t("regular")}
                            />
                            <FormControlLabel
                              control={
                                  <Switch
                                    checked={includeJoint}
                                    onChange={() => setIncludeJoint(!includeJoint)}
                                    color="primary"
                                  />
                              }
                              label={t("joint")}
                            />
                            <FormControlLabel
                              control={
                                  <Switch
                                    checked={includeBaseline}
                                    onChange={() => setIncludeBaseline(!includeBaseline)}
                                    color="primary"
                                  />
                              }
                              label={t("baseline")}
                            />
                        </Box>
                    </div>
                    <InspectionsCardList inspections={state.inspections} />
                    {state.inspections.length % 10 === 0 &&
                      <LoadMoreButton onClick={handleShowMore} isLoading={state.isLoading} />
                    }
                </div>
                <EditAreaInspectionPage
                  onDelete={handleDeleteItem}
                  state={state}
                  setState={setState}
                />
            </>
          )}
      </div>
    );
}

export default InspectionsPage;



