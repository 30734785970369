// src/stores/traceStore.ts
import { proxy } from "valtio";

const traceStore = proxy({
  selectedTrace: null as any,
  open: false,

  selectTrace(trace: any) {
    traceStore.selectedTrace = trace;
    traceStore.open = true;
  },

  closeDialog() {
    traceStore.open = false;
    traceStore.selectedTrace = null;
  },
});

export default traceStore;
