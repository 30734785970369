import React, { useEffect, useState } from 'react';
import useAppStore from '../../../../../../appStore';
import InspectionDualBarChart from "./components/InspectionDualBarChart";
import { fetchScoresBySpace } from "../../../../../../shared/utils/data-fetch/analyticDataFetchHelper";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";

type ChartHeaderRow = [string, string, string];
type ChartDataRow = [string, number, number];
type ChartDataState = Array<ChartHeaderRow | ChartDataRow>;

const ScoresByTopLevelSpace = ({ location_id, startDate=null, endDate=null }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { whiteLabelProps } = useAppStore();
  const [chartData, setChartData] = useState<ChartDataState>([['Space Name', 'Average', 'ID']]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setIsLoading(true);
    setError('');
    fetchScoresBySpace(user.organization.id, location_id, startDate, endDate)
      .then(data => {
        setChartData([...data]);
      })
      .catch(error => {
        setError(error instanceof Error ? error.message : String(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [location_id, user.organization.id, startDate, endDate]);


  if (isLoading) {
    return <CustomLoadingDots />
  }

  return (
    <InspectionDualBarChart
      chartData={chartData}
      chartTitle={"Building Scores"}
      primaryColor={whiteLabelProps.primary_color}
      max={5}
      sortOrder={'asc'}
      type={'space'}
      location_id={location_id}
    />
  );
};

export default ScoresByTopLevelSpace;
