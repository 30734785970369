import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { LocationContactSerializer } from "../../../models/serializers/locationContactSerializer";


export class CommandUpdateSalesContact {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id) {
    return `${this.SERVICE}/crm/sales_contact/${id}/`;
  }

  async run(contact) {

    const url = this.getUrl(contact.id)
    const tracer = useTracer.getState()
    tracer.trace('Relationships', 'Api', `PUT ${url}`)

    function omitDeep(obj, keysToOmit) {
      if (Array.isArray(obj)) {
        return obj.map(i => omitDeep(i, keysToOmit));
      } else if (typeof obj === 'object' && obj !== null) {
        return Object.keys(obj).reduce((acc, key) => {
          if (!keysToOmit.includes(key)) {
            acc[key] = omitDeep(obj[key], keysToOmit);
          }
          return acc;
        }, {});
      }
      return obj;
    }

    try {
      let sanitizedContact = omitDeep(contact, ['uuid']);
      let data = LocationContactSerializer.serialize(sanitizedContact);
      const response = await httpClient.put(url, data);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Relationships', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    }finally {
      tracer.trace('Relationships', 'Api', 'Success')
    }

  }

  deserialize(data) {
    const serializer = new LocationContactSerializer()
    const item = serializer.deserialize(data)
    return item
  }

}