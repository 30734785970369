import React, { useState } from 'react';
import PageHeader from "../../../shared/components/page/headers/PageHeader";
import { Tabs, Tab, Box } from '@mui/material';
import ProjectsRankTab from "../components/tabs/rank/ProjectsRankTab";
import ProjectsPlanTab from "../components/tabs/plan/ProjectsPlanTab";
import ProjectsLaunchTab from "../components/tabs/launch/ProjectsLaunchTab";
import ProjectsTrackTab from "../components/tabs/track/ProjectsTrackTab";
import ProjectsCloseTab from "../components/tabs/close/ProjectsCloseTab";
import { projectStore } from "../projectsStore";

const ProjectsManagePage = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    const modes = ['Rank', 'Plan', 'Launch', 'Track', 'Close'];
    projectStore.mode = modes[newValue];
    projectStore.open = false;
    projectStore.isEditing = false
  };

  return (
    <>
      <PageHeader
        title='Projects'
        showAction={false}
      />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange}>
          <Tab label="RANK" />
          <Tab label="PLAN" />
          <Tab label="LAUNCH" />
          <Tab label="TRACK" />
          <Tab label="CLOSE" />
        </Tabs>
      </Box>

      <Box sx={{ p: 2 }}>
        {tabValue === 0 && <ProjectsRankTab />}
        {tabValue === 1 && <ProjectsPlanTab />}
        {tabValue === 2 && <ProjectsLaunchTab />}
        {tabValue === 3 && <ProjectsTrackTab />}
        {tabValue === 4 && <ProjectsCloseTab />}
      </Box>
    </>
  );
};

export default ProjectsManagePage;

