import React from 'react';
import { inspectionButtonContainerStyle } from "../../utils/table-modifier-util/tableDataUtil";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const ModalFooterSubmitProgress = () => {

  const { t } = useTranslation();

  return (
    <div style={inspectionButtonContainerStyle(true)}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%"
        }}
      >
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center"
          }}
        >
          <Button
            onClick={undefined}
            style={{ fontSize: "1.3rem", color: 'darkgreen'  }}
          >
            {t('submitting')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalFooterSubmitProgress;