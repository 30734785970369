import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const primaryColor = "#15aa78";
const colorPalette = [primaryColor, "#1ec48c", "#13cc70", "#149f5b", "#128d4f"];

const TraceAnalytics = () => {


  const successVsFailOptions: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: { show: false },
      foreColor: "#000"
    },
    colors: [primaryColor, "#d9534f"],
    plotOptions: {
      bar: { horizontal: false, columnWidth: "50%" }
    },
    dataLabels: {
      enabled: true,
      style: { colors: ["white"] }
    },
    xaxis: {
      categories: ["Requests"],
      labels: { style: { colors: "#000" } }
    },
    yaxis: {
      labels: { style: { colors: "#000" } }
    },
    legend: {
      labels: { colors: "#000" }
    },
    title: {
      text: "Successful vs Unsuccessful",
      align: "left",
      style: { color: "#000", fontSize: "16px" }
    }
  };
  const successVsFailSeries = [
    { name: "Successful", data: [2300] },
    { name: "Unsuccessful", data: [450] }
  ];

  const longestDurationOptions: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: { show: false },
      foreColor: "#000"
    },
    colors: [primaryColor],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "50%",
      }
    },
    dataLabels: {
      enabled: true,
      style: { colors: ['white'] }
    },
    xaxis: {
      categories: ["GET /items", "POST /cases", "GET /inspections", "PUT /workitems", "GET /status"],
      labels: { style: { colors: "#000" } }
    },
    yaxis: {
      labels: { style: { colors: "#000" } }
    },
    title: {
      text: "Longest Duration Requests (ms)",
      align: "left",
      style: { color: "#000", fontSize: "16px" }
    }
  };
  const longestDurationSeries = [
    { name: "Duration (ms)", data: [1200, 1100, 1050, 1000, 950] }
  ];

  // Request count per date (Line chart)
  const perDateOptions: ApexOptions = {
    chart: {
      type: "line",
      toolbar: { show: false },
      foreColor: "#000"
    },
    stroke: {
      curve: 'smooth'
    },
    colors: [primaryColor],
    xaxis: {
      categories: ["2024-12-01", "2024-12-02", "2024-12-03", "2024-12-04", "2024-12-05", "2024-12-06", "2024-12-07"],
      labels: { style: { colors: "#000" } }
    },
    yaxis: {
      labels: { style: { colors: "#000" } }
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: "Request Count Per Date",
      align: "left",
      style: { color: "#000", fontSize: "16px" }
    },
    grid: {
      borderColor: "#ddd",
      strokeDashArray: 4
    }
  };
  const perDateSeries = [
    { name: "Requests", data: [300, 450, 500, 700, 650, 800, 900] }
  ];

  const perModuleOptions: ApexOptions = {
    chart: {
      type: "pie",
      toolbar: { show: false },
      foreColor: "white"
    },
    colors: colorPalette,
    labels: ["Inspections", "Cases", "Work Items"],
    legend: {
      labels: { colors: "#000" },
      position: "bottom"
    },
    title: {
      text: "Requests Per Module",
      align: "left",
      style: { color: "#000", fontSize: "16px" }
    },
    dataLabels: {
      style: {
        colors: ["white"],
      }
    }
  };
  const perModuleSeries = [450, 300, 150];

  return (
    <div className="card mb-3 mt-5" style={{ background: "#fff", padding: "20px", borderRadius: "8px" }}>
      <div className="card-body">
        <Typography variant="h5" color="#000" gutterBottom>
          API Trace Thread Analytics
        </Typography>
        <Typography variant="body2" color="#000" sx={{ mb: 3 }}>
          This is currently all dummy data
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box sx={{ background: "#fff", padding: 2, borderRadius: 2, boxShadow: 1 }}>
              <Chart options={successVsFailOptions} series={successVsFailSeries} type="bar" height={300} />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ background: "#fff", padding: 2, borderRadius: 2, boxShadow: 1 }}>
              <Chart options={longestDurationOptions} series={longestDurationSeries} type="bar" height={300} />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ background: "#fff", padding: 2, borderRadius: 2, boxShadow: 1 }}>
              <Chart options={perDateOptions} series={perDateSeries} type="line" height={300} />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ background: "#fff", padding: 2, borderRadius: 2, boxShadow: 1 }}>
              <Chart options={perModuleOptions} series={perModuleSeries} type="pie" height={300} />
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TraceAnalytics;


