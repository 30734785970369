import React from 'react';
import ProjectsList from "../../project-list/ProjectsList";
import EditProject from "../../edit-project/PlanProject";
import { useSnapshot } from 'valtio';
import { projectStore } from "../../../projectsStore";

const ProjectsPlanTab = () => {
    const snap = useSnapshot(projectStore);
    const { selectedProject, open, isEditing } = snap;

    const handleNewProject = () => {
        projectStore.selectedProject = null;
        projectStore.isEditing = true;
    };

    const handleEditProject = (project) => {
        projectStore.selectedProject = project;
        projectStore.isEditing = true;
        projectStore.open = true;
    };

    const handlerDeleteItem = async (itemToDelete) => {
        // implement this as needed
    };

    const handlerItemEditorAction = (action) => {
        projectStore.isEditing = false;
        projectStore.open = false;
    };

    return (
      <>
            <ProjectsList
              onEdit={handleEditProject}
              onDelete={handlerDeleteItem}
            />
          {open && (
            <EditProject
              open={open}
              onActionClicked={handlerItemEditorAction}
            />
          )}
      </>
    );
};

export default ProjectsPlanTab;
