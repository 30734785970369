import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CsatModal from "./modal/CsatModal";
import { Grid } from "@mui/material";
import useAppStore from "../../../../../appStore";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { AnalyticsService } from "../../../../../shared/services/analytics/analyticsService";
import { SurveySummary } from "../../../../../shared/models/surveySummary.model";

const CsatTile = () => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { selectedLocation, appImages, whiteLabelProps } = useAppStore();
  const service = new AnalyticsService();
  const [summary, setSummary] = useState<SurveySummary>(new SurveySummary());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCsatSummary = async () => {
      setIsLoading(true);
      setError('');
      try {
        const overview = await service.getSurveySummary(
          user.organization,
          'CSat',
          selectedLocation
        );
        setSummary(overview);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchCsatSummary().then();
  }, [user, selectedLocation.id]);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = (action) => {
    setOpen(false);
  };

  return (
    <Card variant="outlined" sx={{
      boxShadow: "0 1px 2px rgba(0,0,0,0.1)", borderTopLeftRadius: '0', borderTopRightRadius: '0', border: '1px solid #dcdcdc', borderTop: 'none'
    }}>
      <CardContent>
        {summary && summary.received_count !== 0 ? (
          <>
            <Grid container alignItems="center" justifyContent="space-between" sx={{marginBottom: '25px'}}>
              <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
                {t('csat-survey')}
              </Typography>
            </Grid>
            <Box sx={{ '& > :not(style)': { mt: "1px" } }}>
              {summary && (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={4} textAlign="center">
                      <div style={{ height: "40px", alignItems: 'center', justifyContent: 'center' }}>
                        <img src={appImages.iconAvgScore} style={{ width: "37px", height: "27px", marginTop: '5px' }} />
                      </div>
                      <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
                        {summary.avg_score?.toFixed(1) ?? 'N/A'}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">{t('average')}</Typography>
                    </Grid>
                    <Grid item xs={4} textAlign="center">
                      <div style={{ height: "40px", alignItems: 'center' }}>
                        <img src={appImages.iconSent} style={{ width: "32px", height: "25px", justifyContent: 'center', marginTop: '6px' }} />
                      </div>              <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
                      {summary.sent_count}
                    </Typography>
                      <Typography variant="body2" color="textSecondary">{t('sent')}</Typography>
                    </Grid>
                    <Grid item xs={4} textAlign="center">
                      <div style={{ height: "40px", alignItems: 'center' }}>
                        <img src={appImages.iconReceived} style={{ width: "42px", height: "25px", justifyContent: 'center', marginTop: '7px' }} />
                      </div>
                      <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
                        {summary.received_count}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">{t('received')}</Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>          </>
        ) : (
          <><Grid container alignItems="center" justifyContent="space-between">
            <Typography variant="h6" component="div"
                        sx={{ marginTop: "5px", fontWeight: "bold" }}>
              CSAT Survey
            </Typography>
          </Grid>
            <div style={{ flex: 1, textAlign: "center", padding: "20px", marginTop: "10px" }}>
              <p style={{
                fontSize: "22px",
                fontWeight: "semibold",
                marginBottom: "0px",
                color: whiteLabelProps.primary_color
              }}>No Survey Data</p>
              <p style={{ fontSize: "14px", color: "gray" }}>There is no data for this timeframe</p>
            </div>
          </>
        )}
      </CardContent>
      {open && (
        <CsatModal
          open={open}
          onActionClicked={handleCloseModal}
        />
      )}
    </Card>
  );
};

export default CsatTile;
