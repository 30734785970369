import React, { useEffect, useState } from "react";
import { AppBar, Dialog, IconButton, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import useAppStore from "../../../../appStore";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import Box from "@mui/material/Box";
import { WorkItem } from "../../../../shared/models/workItem.model";
import { WorkItemsService } from "../../../../shared/services/work-items/workItemsService";
import { PrintableWorkItemsTable } from "./components/PrintableWorkItemsTable";
import LoadMoreButton from "../../../../shared/components/buttons/LoadMoreButton";

interface PrintWorkItemsProps {
  open: boolean;
  onActionClicked: (action: string) => void;
}

const PrintableWorkItems: React.FC<PrintWorkItemsProps> = ({ open, onActionClicked }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const service = new WorkItemsService();
  const { selectedLocation} = useAppStore();
  const [error, setError] = useState('');
  const [workItems, setWorkItems] = useState<WorkItem[]>([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(() => parseInt(localStorage.getItem("activeStep") || '0', 10));


  useEffect(() => {
    const fetchItems = async () => {
      const limit = 15;
      const offset = page * limit;

      if (page === 0) {
        setWorkItems([])
      }

      setIsLoading(true);
      try {
        const items = await service.getWorkItems(user.organization?.id, selectedLocation.id, activeStep, limit, offset);
        setWorkItems(prevItems => [...prevItems, ...items]);
      } catch (error) {
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedLocation.id !== 0) {
      fetchItems().then();
    }

  }, [selectedLocation, page, activeStep]);

  const handleStepChange = (step: number) => {
    setPage(0);
    setActiveStep(step);
  };

  const handleShowMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  if (error) return <CustomError error={error} />;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => onActionClicked('close')}
      TransitionComponent={ModalTransition}
      PaperProps={{
        style: {
          overflowX: 'hidden',
          backgroundColor: '#f9f9f9',
          overflowY: 'auto',
        },
      }}
    >
      <AppBar sx={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
      </AppBar>

      <div style={{ marginBottom: 50 }}>
        <PrintableWorkItemsTable workItems={workItems} onClose={() => onActionClicked('close')} handleStepChange={handleStepChange} activeStep={activeStep} />
        {((workItems.length !== 0 && workItems.length % 15 === 0) || isLoading) &&
          <LoadMoreButton onClick={handleShowMore} isLoading={isLoading} />
        }
      </div>

    </Dialog>
  );
};

export default PrintableWorkItems;