import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SubHeader from "../../../../../../../../shared/components/page/headers/SubHeader";
import { Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Suggestions = ({ suggestions }) => {

  const {t} = useTranslation();

  return (
    <Card variant="outlined">
      <CardContent>
        <SubHeader title={t('suggestions')} showAction={false} />
        {suggestions.length > 0 ? (
          <List>
            {suggestions.map((item, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider component="li" />}
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={item.building_name}
                    secondary={
                      item.days_since_last_inspection === null
                        ? "No inspections run yet"
                        : `Last inspected ${item.days_since_last_inspection} days ago`
                    }
                    primaryTypographyProps={{ fontWeight: "fontWeightMedium" }}
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        ) : (
          <Typography variant="subtitle1" style={{ margin: "20px 0" }}>
            No suggestions. All inspections are up to date.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default Suggestions;

