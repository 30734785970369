import React, { useEffect, useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  TextField,
  Box,
  Chip, Divider, Checkbox
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import useAppStore from "../../../../../../appStore";
import { EmployeeService } from "../../../../../../shared/services/employee/employeeService";
import { Employee } from "../../../../../../shared/models/employee.model";
import { TextFieldButton } from "../../../../../../shared/components/buttons/TextFieldButton";
import { KeyboardReturn } from "@mui/icons-material";
import EmployeeDetailRow from "../../../../../../shared/components/page/tables/EmployeeDetailRow";

const RelatedEmployeesField = ({ itemToEdit, onSubmit }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>(itemToEdit.related_to_employees || []);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [employeeSearchResult, setEmployeeSearchResult] = useState<any>([]);
  const [searchFor, setSearchFor] = useState<any>('');

  useEffect(() => {
    const fetchEmployees = async () => {
      setIsLoading(true);
      try {
        const service = new EmployeeService();
        const employees = await service.searchEmployees(user.organization.id, searchFor);
        setEmployeeSearchResult(employees);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (searchFor !== '') {
      fetchEmployees().then();
    }

  }, [searchFor, user.organization.id]);

  const handleSearch = () => {
    setSearchFor(searchQuery);
  };

  const handleRemoveEmployee = (employeeId) => {
    const updatedSelectedEmployees = selectedEmployees.filter(e => e.id !== employeeId);
    setSelectedEmployees(updatedSelectedEmployees);
    itemToEdit.related_to_employees = updatedSelectedEmployees;
  };

  const handleListItemClick = (employee) => {
    if (selectedEmployees.find((e) => e.id === employee.id)) {
      const updatedSelectedEmployees = selectedEmployees.filter((e) => e.id !== employee.id);
      setSelectedEmployees(updatedSelectedEmployees);
      itemToEdit.related_to_employees = updatedSelectedEmployees;
    } else {
      const updatedSelectedEmployees = [...selectedEmployees, employee];
      setSelectedEmployees(updatedSelectedEmployees);
      itemToEdit.related_to_employees = updatedSelectedEmployees;
    }
  };


  const renderSelectedEmployees = () => {
    return selectedEmployees.map(employee => (
      <Chip
        key={employee.id}
        label={employee.person.first_name + " " + employee.person.last_name}
        onDelete={() => handleRemoveEmployee(employee.id)}
        deleteIcon={<CancelIcon />}
        sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
      />
    ));
  };

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <Box className="d-flex flex-row justify-content-between align-items-center">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.1rem', ml: 1, color: '#999' }}>Involved
            Employees</Typography>
        </Box>
        <Divider sx={{ backgroundColor: '#999', mb: 1 }} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            {renderSelectedEmployees()}
          </Box>
        </Box>
      </Box>
      <div style={{ height: '100vh', display: 'flex', flexDirection: 'column'}}>
        <div className="d-flex flex-row mt-5">
          <TextField
            variant="outlined"
            autoFocus={true}
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleSearch();
            }}
            sx={{
              width: '90%',
              '& .MuiOutlinedInput-root': {
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                height: '45px',
                '& .MuiInputBase-input': {
                  height: '100%',
                  padding: '16.5px 14px',
                },
              },
            }}
          />
          <TextFieldButton onClick={handleSearch}>
            <KeyboardReturn />
          </TextFieldButton>
        </div>
        <div style={{ flex: 1, overflow: 'auto' }}>
          {employeeSearchResult.length > 0 && (
            <List>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {employeeSearchResult.map((employee) => {
                    const isSelected = !!selectedEmployees.find((e) => e.id === employee.id);
                    return (
                      <ListItem
                        key={employee.id}
                        button
                        selected={isSelected}
                        onClick={() => handleListItemClick(employee)}
                      >
                        {/* Add Checkbox */}
                        <Checkbox
                          edge="start"
                          checked={isSelected}
                          tabIndex={-1}
                          disableRipple
                        />
                        {/* Employee details */}
                        <EmployeeDetailRow employee={employee} />
                      </ListItem>
                    );
                  })}
                </>
              )}
            </List>
          )}
        </div>
      </div>

      {error && <Typography color="error">{error}</Typography>}
    </>
  );
};

export default RelatedEmployeesField;
