import React from 'react';
import PageHeader from "../../../shared/components/page/headers/PageHeader";
import { Box, Typography, Grid, Card, CardContent, CardHeader, LinearProgress } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

const ProjectsDashboardPage = () => {
  // Define chart options with a proper ApexOptions type annotation
  const chartOptions: ApexOptions = {
    chart: {
      type: 'donut', // 'donut' is a valid ApexCharts type
      toolbar: { show: false }
    },
    labels: ['Completed', 'In Progress', 'On Hold'],
    legend: {
      position: 'bottom',
      fontSize: '14px',
      markers: {
        width: 12,
        height: 12,
      }
    },
    dataLabels: {
      enabled: true,
      dropShadow: { enabled: false }
    },
    // Updated colors to various shades of green
    colors: ['#15aa78', '#39bd91', '#64d5ae']
  };

  const chartSeries = [25, 10, 7]; // Dummy data corresponding to Completed, In Progress, On Hold

  return (
    <>
      <PageHeader
        title='Projects Dashboard'
        showAction={false}
      />

      {/* Main Container */}
      <Box
        sx={{
          p: 4,
          background: (theme) => theme.palette.background.default,
          minHeight: 'calc(100vh - 64px)'
        }}
      >
        {/* "Coming Soon" Message */}
        <Box textAlign="center" mb={4}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
            Coming Soon!
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            We're working hard to build the best experience for managing your projects.
            In the meantime, explore these sample analytics to get a glimpse of what's ahead.
          </Typography>
        </Box>

        {/* Placeholder Analytics Grid */}
        <Grid container spacing={3}>
          {/* Total Projects Card */}
          <Grid item xs={12} md={4}>
            <Card elevation={3}>
              <CardHeader title="Total Projects" />
              <CardContent>
                <Typography variant="h3" align="center" sx={{ fontWeight: 700 }}>42</Typography>
                <Typography variant="body2" color="text.secondary" align="center">
                  Across all teams
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Completed Projects Card */}
          <Grid item xs={12} md={4}>
            <Card elevation={3}>
              <CardHeader title="Completed" />
              <CardContent>
                <Typography variant="h3" align="center" sx={{ fontWeight: 700 }}>25</Typography>
                <Typography variant="body2" color="text.secondary" align="center">
                  Successfully delivered
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* In Progress Card */}
          <Grid item xs={12} md={4}>
            <Card elevation={3}>
              <CardHeader title="In Progress" />
              <CardContent>
                <Typography variant="h3" align="center" sx={{ fontWeight: 700 }}>10</Typography>
                <Typography variant="body2" color="text.secondary" align="center">
                  Currently in development
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Completion Rate Card with Progress Bar */}
          <Grid item xs={12} md={6}>
            <Card elevation={3}>
              <CardHeader title="Project Completion Rate" />
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography variant="h3" sx={{ fontWeight: 700 }}>59%</Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Average completion of ongoing projects
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={59}
                  sx={{ mt: 2, height: 10, borderRadius: 2 }}
                />
              </CardContent>
            </Card>
          </Grid>

          {/* Donut Chart Card for Status Breakdown */}
          <Grid item xs={12} md={6}>
            <Card elevation={3}>
              <CardHeader title="Project Status Breakdown" />
              <CardContent>
                <ReactApexChart
                  options={chartOptions}
                  series={chartSeries}
                  type="donut"
                  height={300}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ProjectsDashboardPage;



