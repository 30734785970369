import React, { useState } from 'react';
import ScoresBySpaceType from './components/charts/ScoresBySpaceType';
import ScoresByTopLevelSpace from "./components/charts/ScoresByTopLevelSpace";
import IssuesByServiceItem from "./components/charts/IssuesByServiceItem";
import {
  Grid,
  Tabs,
  Tab,
  Box,
  useMediaQuery,
  useTheme,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions, IconButton
} from "@mui/material";
import ScoresByLocation from "./components/charts/ScoresByLocation";
import PageHeader from "../../../../shared/components/page/headers/PageHeader";
import { useAuth } from "../../../auth";
import useAppStore from "../../../../appStore";
import CustomDatePicker from "../../../../shared/components/forms/date-time/date-picker/CustomDatePicker";
import { DateTime } from 'luxon';
import MonthScoreLineChart from "./components/charts/MonthScoreLineChart";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Dialog from "@mui/material/Dialog";
import { DateRangeIcon } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import InspectionListDrillDown
  from "../site-health/components/tabs/inspections/components/components/InspectionListDrillDown";
import { useSnapshot } from "valtio";
import { drilldownState, resetDrilldown } from "./drilldownStore";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const InspectionAnalytics = () => {
  const snap = useSnapshot(drilldownState);

  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const { selectedLocation } = useAppStore();
  const defaultLocationId = currentUser?.permissions.locations?.[0]?.id || 0;
  const selectedLocationId = selectedLocation.id || defaultLocationId;
  const [activeTab, setActiveTab] = useState(0);
  const [startDate, setStartDate] = useState(DateTime.now().minus({months: 6}));
  const [endDate, setEndDate] = useState(DateTime.now());
  const [open, setOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  const handleCloseModal = () => {
    resetDrilldown();
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue)
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue)
  };

  const handlePreset = (days) => {
    setEndDate(DateTime.now());
    setStartDate(DateTime.now().minus({days: days}));
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className='row mb-3'>
        <PageHeader title={t('inspection-charts')}
                    divider={false}
                    showAction={false}
        />

        <div
          className='d-flex pt-1'
          style={{
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: isMobile ? '' : 'space-between',
          }}
        >
          <Tabs value={activeTab} onChange={handleChange} aria-label='inspection tabs'>
            <Tab label={t('overall')} />
            <Tab label={t('selected-location')} />
          </Tabs>
          <div
            className='d-flex flex-row align-items-center justify-content-center'
            style={{ maxWidth: '500px', paddingTop: isMobile ? '20px' : '', marginRight: isMobile ? '' : '25px' }}
          >
            <div style={{ marginRight: '16px', display: 'flex', flexWrap: 'wrap' }}>
              <Button variant='outlined' onClick={() => handlePreset(30)}>
                30 {t('days')}
              </Button>
              <Button variant='outlined' onClick={() => handlePreset(60)} style={{ marginLeft: '5px' }}>
                60 {t('days')}
              </Button>
              <Button variant='outlined' onClick={() => handlePreset(90)} style={{ marginLeft: '5px' }}>
                90 {t('days')}
              </Button>
              <IconButton onClick={() => setOpen(true)} style={{ marginLeft: '5px' }}>
                <DateRangeIcon />
              </IconButton>
            </div>
          </div>
        </div>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Select Date Range</DialogTitle>
          <DialogContent>
            <div className="d-flex flex-row align-items-center"
                 style={{ maxWidth: "400px", paddingTop: isMobile ? "10px" : "", marginRight: "25px" }}>
              <CustomDatePicker value={startDate} onChange={handleStartDateChange} quickPick={false} minimal={true} />
              <ArrowRightAltIcon sx={{ color: "#999" }} />
              <CustomDatePicker value={endDate} onChange={handleEndDateChange} quickPick={false} minimal={true} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <TabPanel value={activeTab} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MonthScoreLineChart
                location_id={0}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            <Grid item xs={12}>
              <ScoresByLocation startDate={startDate} endDate={endDate} />
            </Grid>
            <Grid item xs={12}>
              <ScoresBySpaceType
                location_id={0}
                sortOrder={"title"}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MonthScoreLineChart
                location_id={selectedLocationId}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            <Grid item xs={12}>
              <ScoresByTopLevelSpace
                location_id={selectedLocationId}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            <Grid item xs={12}>
              <ScoresBySpaceType
                location_id={selectedLocationId}
                sortOrder={'asc'}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            <Grid item xs={12}>
              <IssuesByServiceItem
                location_id={selectedLocationId}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
          </Grid>
        </TabPanel>
      </div>

      {snap.drilldownOpen &&  (
        <InspectionListDrillDown
          open={snap.drilldownOpen}
          title={'Relevant'}
          onActionClicked={handleCloseModal}
          timeframe={undefined}
          location_id={snap.sourceId}
          include_regular={snap.include_regular}
          include_joint={snap.include_joint}
          include_baseline={snap.include_baseline}
          from_date={snap.from_date}
          to_date={snap.to_date}
          space_id={snap.space}
          space_type_id={snap.space_type}
          headers={false}
        />
      )}
    </>
  );
}

export default InspectionAnalytics;
