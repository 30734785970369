import React, { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import SubHeader from "../../../../../shared/components/page/headers/SubHeader";
import { ActionPlanItem } from "../../../../../shared/models/actionPlanItem.model";
import EditActionItem from "./components/EditActionItem";
import RelationshipTimelineEntry from "./components/RelationshipTimelineEntry";


const RelationshipActionTimeline = (props) => {
  const {
    relationship, isEditing=true
  } = props;

  const [open, setOpen] = useState(false);
  const [actionItemToEdit, setActionItemToEdit] = useState<ActionPlanItem>(new ActionPlanItem());
  const [exists, setExists] = useState<boolean>(false);
  const [actionItems, setActionItems] = React.useState<ActionPlanItem[]>(() => {
    return relationship.action_entries.sort((b, a) => new Date(a.due_date).getTime() - new Date(b.due_date).getTime());
  });

  const handleAddItem = () => {
    setActionItemToEdit(new ActionPlanItem());
    setExists(false);
    setOpen(true);
  }

  const handleEditItem = (item) => {
    setActionItemToEdit(item);
    setExists(true);
    setOpen(true);
  }

  const handleClose = () => {
    setActionItemToEdit(new ActionPlanItem());
    setOpen(false);
  }

  const handleSaveAndRefresh = () => {
    handleClose();
    if (!exists) {
      const updated =  [...relationship.action_entries, actionItemToEdit]
      relationship.action_entries = updated
      setActionItems(updated);
    }
  }


  return (
    <>
        <Box sx={{border: '1px solid #c7c7c7', borderRadius: '5px', margin: 1}}>
          <div style={{padding: '10px'}}>
            <SubHeader title={'Action Items'} actionButtonName={'+ Item'} onActionClicked={handleAddItem} showAction={isEditing ? true : false}/>
          </div>
          <Divider sx={{backgroundColor: '#b3b3b3'}} />
          <>
            {actionItems.length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "150px",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                {relationship.id !== 0 ? (
                  <Typography variant="h6" color="textSecondary">
                    No action items
                  </Typography>
                ) : (
                  <Typography variant="h6" color="textSecondary">
                    Create the relationship to start making an action plan
                  </Typography>
                )}
              </Box>
            ) : (
              actionItems.map((item, index) => (
                <RelationshipTimelineEntry
                  key={index}
                  actionItem={item}
                  onEdit={handleEditItem}
                  isEditing={isEditing}
                />
              ))
            )}
          </>
        </Box>
      <EditActionItem open={open} onSave={handleSaveAndRefresh} onClose={handleClose} relationship={relationship} actionItem={actionItemToEdit} isEditing={exists} />
    </>
  );
}

export default RelationshipActionTimeline;