import React from "react";
import CsatTile from "./csat-summary/CsatTile";
import JointInspectionTile from "./joint-inspection-summary/JointInspectionTile";

const ClientSummary = () => {

  return (
    <>
      <JointInspectionTile />
      <CsatTile />
    </>
);

}



export default ClientSummary;