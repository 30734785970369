import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Divider,
  Toolbar,
  IconButton,
  AppBar
} from "@mui/material";
import { TraceService } from "../../../shared/services/trace-thread/traceService";
import traceStore from "../traceStore";
import CloseIcon from "@mui/icons-material/Close";

const TraceDetails = ({ open, onClose, trace }) => {
  useEffect(() => {
    if (trace) {
      const fetchTraceDetails = async () => {
        try {
          const service = new TraceService();
          const detailTrace = await service.getTrace(trace.id);
          traceStore.selectTrace(detailTrace);
        } catch (error) {
          console.error("Failed to fetch trace details:", error);
        }
      };

      fetchTraceDetails().then();
    }
  }, [trace?.id]);

  const getStatusColor = (status) => {
    if (status?.startsWith("S")) return "green";
    return "red";
  };

  if (!trace) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <AppBar sx={{ position: "relative", background: "#333" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogTitle sx={{ pb: 0 }}>
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            padding: "16px",
            borderRadius: "8px",
            overflowX: "auto",
            fontFamily: "monospace",
            fontSize: "14px",
            whiteSpace: "pre-wrap",
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* Status indicator box */}
          <Box
            sx={{
              backgroundColor: getStatusColor(trace.status),
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              marginRight: "8px",
              flexShrink: 0
            }}
          />
          <Typography
            variant="body1"
            sx={{ fontFamily: "monospace", fontSize: "14px", fontWeight: 600 }}
          >
            {trace.http_method} {trace.url}
          </Typography>
        </Box>
      </DialogTitle>
      <Divider sx={{ backgroundColor: "#000", mt: 1, mb: 2 }} />
      <DialogContent sx={{ paddingBottom: "24px" }}>

        {/* Query Parameters Section */}
        {trace.query_params && Object.keys(trace.query_params).length > 0 && (
          <Box mb={4}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1, textTransform: "uppercase", color: "#555" }}>
              Parameters
            </Typography>
            <Box
              sx={{
                backgroundColor: "#fafafa",
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "16px"
              }}
            >
              {Object.entries(trace.query_params).map(([key, value], index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: index === Object.entries(trace.query_params).length - 1
                      ? "none"
                      : "1px solid #eee",
                    py: 1
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {key}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#333" }}>
                    {String(value)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        {/* Messages Section */}
        {trace.messages && trace.messages.length > 0 && (
          <Box mb={4}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1, textTransform: "uppercase", color: "#555" }}>
              Messages
            </Typography>
            <Box
              sx={{
                backgroundColor: "#fafafa",
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "16px",
                maxHeight: "200px",
                overflowY: "auto"
              }}
            >
              {trace.messages.map((message, index) => (
                <Box key={index} mb={1}>
                  <Typography variant="body2" sx={{ color: "#333" }}>
                    - {message}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        {/* Response Section */}
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1, textTransform: "uppercase", color: "#555" }}>
            Response
          </Typography>
          <Box
            component="pre"
            sx={{
              border: "1px dashed #c7c7c7",
              padding: "16px",
              borderRadius: "8px",
              overflowX: "auto",
              whiteSpace: "pre-wrap",
              backgroundColor: "#fafafa",
            }}
          >
            Not implemented yet
            {/* {JSON.stringify(trace.response, null, 2)} */}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TraceDetails;

