import React, { useState } from "react";
import PageHeader from "../../shared/components/page/headers/PageHeader";
import { EquipmentModel } from "../../shared/models/equipment.model";
import { EquipmentService } from "../../shared/services/equipment/equipmentService";
import EquipmentList from "./components/equipment-list/EquipmentList";
import ItemEditDialog from "../../shared/components/page/popup-dialog/ItemEditDialog";
import useAppStore from "../../appStore";

const EquipmentPage = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { toggleMasterRefresh } = useAppStore();
  const [equipmentToEdit, setEquipmentToEdit] = useState<EquipmentModel | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogClose = (action = '') => {
    setIsDialogOpen(false);
    setEquipmentToEdit(null);
    if (action === 'save') {
      toggleMasterRefresh();
    }
  };

  const handleNewEquipment = () => {
    let newEquipment = new EquipmentModel();
    newEquipment.organization = user?.organization;
    setEquipmentToEdit(newEquipment);
    setIsDialogOpen(true);
  };

  const handleEditEquipment = (equipment: EquipmentModel) => {
    setEquipmentToEdit(equipment);
    setIsDialogOpen(true);
  };


  const handleSubmit = async (equipment: EquipmentModel) => {
    try {
      if (equipment?.id) {
        await new EquipmentService().updateEquipment(equipment);
      } else {
        await new EquipmentService().createEquipment(equipment);
      }
      handleDialogClose('save');
    } catch (e) {
      console.error('Error in handleSubmit:', e);
    }
  };


  return (
    <div style={{backgroundColor: 'white', padding: '20px', minHeight: '100vh'}}>
      <PageHeader
        title="Protective Equipment"
        actionButtonName="New Equipment"
        onActionClicked={handleNewEquipment}
      />

      <EquipmentList onEdit={handleEditEquipment} />

      {isDialogOpen && (
        <ItemEditDialog
          isOpen={isDialogOpen}
          onClose={() => handleDialogClose()}
          itemToEdit={equipmentToEdit}
          setItemToEdit={setEquipmentToEdit}
          onSubmit={(updatedEquipment) => handleSubmit(updatedEquipment)}
        />
      )}
    </div>
  );
}

export default EquipmentPage;




