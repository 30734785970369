import React from "react";
import useAppStore from "../../../../appStore";

const TableHeader = (props) => {
  const {
    tableName='',
    tableSubtitle='',
    onAction=null,
    onAltAction=null,
    actionButtonName='',
    altButtonName= '',
  } = props;

  const {whiteLabelProps} = useAppStore();

  return (
    <div className="d-flex flex-row justify-content-between mb-7">
        {/*begin::Title*/}
        <h3 className="d-flex card-title align-items-start flex-column">
          <span className="card-label fw-bold text-gray-800">{tableName}</span>
          <span className="text-gray-400 mt-1 fw-semibold fs-6">{tableSubtitle}</span>
        </h3>
        {/*begin::Toolbar*/}
        <div className="card-toolbar">
          {altButtonName !== "" &&
            <a className="btn btn-sm btn-light" onClick={(e) => {
              onAltAction(e);
            }}>
              {altButtonName}
            </a>
          }
          {actionButtonName !== "" &&
            <a className="btn btn-sm btn-primary ms-3"
               style={{ backgroundColor: whiteLabelProps.primary_color }}
               onClick={(e) => {
                 onAction(e);
               }}>
              {actionButtonName}
            </a>
          }

      </div>
    </div>
  );
};

export default TableHeader;