import React, { useEffect, useState } from 'react';
import useAppStore from '../../../../../../appStore';
import InspectionDualBarChart from "./components/InspectionDualBarChart";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { fetchScoresOverallChart } from "../../../../../../shared/utils/data-fetch/analyticDataFetchHelper";
import { useTranslation } from "react-i18next";

type ChartHeaderRow = [string, string, string, string];
type ChartDataRow = [string, number, number, number];
type ChartDataState = Array<ChartHeaderRow | ChartDataRow>;

const ScoresByLocation = ({ startDate=null, endDate=null }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { t } = useTranslation();
  const { whiteLabelProps } = useAppStore();
  const [chartData, setChartData] = useState<ChartDataState>([
    ['Location', 'Average', 'Joint Average', 'ID']
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError('');
      try {
        const data = await fetchScoresOverallChart(user.organization.id, startDate, endDate);
        setChartData(data);
      } catch (error) {
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user.organization.id, startDate, endDate]);

  if (isLoading) {
    return <CustomLoadingDots />
  }

  return (
    <InspectionDualBarChart
      chartData={chartData}
      chartTitle={t('overall-averages')}
      primaryColor={whiteLabelProps.primary_color}
      secondaryColor={whiteLabelProps.primary_color_light}
      max={5}
      sortOrder={'title'}
      type={'location'}
      location_id={0}
    />
  );
};

export default ScoresByLocation;

