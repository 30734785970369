import { Box, Divider, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import React from "react";
import useAppStore from "../../../appStore";
import { Trans, useTranslation } from "react-i18next";

const SafetyHeader = ({handleNewIncident}) => {
  const {whiteLabelProps} = useAppStore();
  const {t} = useTranslation();

  return (
    <Box sx={styles.container}>
      {/* Emergency Contact Numbers */}
      <List>
        <ListItem>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography sx={styles.primaryText}>{t('dial')}</Typography>}
            secondary={
              <Typography variant="h6" sx={{ color: whiteLabelProps.primary_color }}>
                911
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography sx={styles.primaryText}>{t('reach-medcor')}</Typography>}
            secondary={
              <Typography variant="h6" sx={{ color: whiteLabelProps.primary_color }}>
                1-844-842-2540
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography sx={styles.primaryText}>{t('reach-verde')}</Typography>}
            secondary={
              <>
                <Typography variant="body2" sx={styles.secondaryText}>
                  {t('via-sage')}
                </Typography>
                <Typography variant="body2" sx={styles.secondaryText}>
                  <Link href="mailto:EHS@verdeclean.com">EHS@verdeclean.com</Link>
                </Typography>
                <Typography variant="body2" sx={styles.secondaryText}>
                  <Link href="tel:1-888-Verde-19">1-888-Verde-19</Link>
                </Typography>
              </>
            }
          />
        </ListItem>
      </List>

      <Divider sx={{ marginY: 2 }} />

      {/* Incident Response Steps */}
      <Typography variant="h6" sx={{ ...styles.sectionHeader, fontSize: '1rem', color: '#333', fontWeight: 'bold' }}>
        {t('incident-steps')}
      </Typography>
      <Box sx={styles.stepsContainer}>
        <Typography variant="body1" sx={{ ...styles.description, marginBottom: "10px" }}>
          <Trans
            i18nKey="non-life-threatening-emergencies"
            components={{
              strong: <strong />,
            }}
            values={{
              phone: "1-844-842-2540",
            }}
          />
        </Typography>

        <Typography variant="body1" sx={{ ...styles.description, marginBottom: "10px" }}>
          <Trans
            i18nKey="report-all-incidents"
            components={{
              strong: <strong />,
            }}
          />
        </Typography>

        <Typography variant="body1" sx={{ ...styles.description, marginBottom: "10px" }}>
          <Trans
            i18nKey="submit-incident-intake"
            components={{
              strong: <strong />,
              link: (
                <Link href="#incident-intake" onClick={handleNewIncident}>
                  Incident Intake
                </Link>
              ),
            }}
          />
        </Typography>

        <Typography variant="body2" sx={{ ...styles.note, fontSize: "0.875rem" }}>
          <Trans i18nKey="notify-verde-ehs" />
        </Typography>

        <Typography variant="body2" sx={{ ...styles.note, fontSize: "0.875rem" }}>
          <Trans
            i18nKey="upload-docs"
            components={{
              emailLink: (
                <Link href="mailto:EHS@verdeclean.com">EHS@verdeclean.com</Link>
              ),
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    fontFamily: "'Arial', sans-serif",
    color: "#333",
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    padding: '20px',
    backgroundColor: '#fafafa',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '30px',
    marginTop: '10px',
  },
  primaryText: {
    fontWeight: '500',
  },
  secondaryText: {
    color: '#666',
  },
  noteHeader: {
    marginBottom: '10px',
    fontWeight: 'bold',
    color: '#555',
  },
  description: {
    lineHeight: '1.6',
  },
  sectionHeader: {
    marginBottom: '10px',
    fontWeight: 'bold',
    color: '#999'
  },
};

export default SafetyHeader;
