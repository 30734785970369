import React from "react";
import useAppStore from "../../../../../appStore";
import { projectStore } from "../../../projectsStore";
import { useSnapshot } from "valtio";
import ProjectsRow from "./row/ProjectsRow";
import { columnConfig } from "./config/ProjectTableConfig";

const ProjectsTable = (props) => {
    const {
        onEdit,
        onDelete,
    } = props;

    const { whiteLabelProps } = useAppStore();
    const snap = useSnapshot(projectStore);

    const columns = columnConfig[snap.mode] || [];

    return (
      <>
          <div className="card card-flush h-md-100 mt-5">
              <div className="card-header pt-7">
                  <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-gray-800">{snap.mode}</span>
                      <span className="text-gray-400 mt-1 fw-semibold fs-6">{snap.mode} projects</span>
                  </h3>
                  <div className="card-toolbar">
                      <a className="btn btn-sm btn-light">Sort By</a>
                      {snap.mode === "Plan" &&
                        <a className="btn btn-sm btn-primary ms-3"
                           style={{ backgroundColor: whiteLabelProps.primary_color }}>+ Create Project</a>
                      }
                  </div>
              </div>
              <div className="card-body pt-6">
                  <div className="table-responsive ">
                      <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                          <thead>
                          <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                              {columns.map((col, colIndex) => (
                                <th key={colIndex} className={`p-0 pb-3 ${col.className}`}>
                                    {col.header}
                                </th>
                              ))}
                          </tr>
                          </thead>
                          <tbody>
                          {snap.projects.map((project, index) => (
                            <ProjectsRow
                              key={index}
                              project={project}
                              columns={columns}
                              onEdit={onEdit}
                              onDelete={onDelete}
                            />
                          ))}
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
      </>
    )
}

export default ProjectsTable;