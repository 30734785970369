import DuotoneCategory from "../../../../../../shared/components/page/row-icons/DuotoneCategory";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";
import React from "react";
import { projectStore } from "../../../../projectsStore";

export const columnConfig = {
  "Rank": [
    {
      header: "PROJECT NAME",
      className: "min-w-150px text-start",
      render: (project, onEdit) => (
        <div className="d-flex align-items-center" onClick={() => onEdit(project)}>
          <DuotoneCategory />
          <div className="d-flex justify-content-start flex-column">
            <a href="#" className="text-gray-800 fw-bold mb-1 fs-6">{project.name}</a>
          </div>
        </div>
      )
    },
    {
      header: "IMPACT",
      className: "min-w-100px text-start",
      render: (project) => project.impact || "-"
    },
    {
      header: "DIFFICULTY",
      className: "min-w-100px text-start",
      render: (project) => project.difficulty || "-"
    },
    {
      header: "CHANGE",
      className: "min-w-25px text-end",
      render: (project, onEdit, onDelete, mode) => (
        <div className="d-flex justify-content-end flex-shrink-0">
          <ActionButton iconType="rank" onClick={(e) => {
            e.preventDefault();
            onEdit(project);
          }} />
        </div>
      )
    },
    {
      header: "SHOW",
      className: "min-w-50px text-end",
      render: (project) => (
        <div className="d-flex justify-content-end">
          <input
            type="checkbox"
            checked={project.show ?? true}
            onChange={(e) => {
              projectStore.projects = projectStore.projects.map(p =>
                p.name === project.name ? { ...p, show: e.target.checked } : p
              );
            }}
          />
        </div>
      )
    }
  ],
  "Plan": [
    {
      header: "PROJECT NAME",
      className: "p-0 pb-3 min-w-150px text-start",
      render: (project, onEdit) => (
        <div className="d-flex align-items-center" onClick={() => onEdit(project)}>
          <DuotoneCategory />
          <div className="d-flex justify-content-start flex-column">
            <a href="#" className="text-gray-800 fw-bold mb-1 fs-6">{project.name}</a>
          </div>
        </div>
      )
    },
    {
      header: "DURATION",
      className: "p-0 pb-3 min-w-125px text-start",
      render: (project) => project.duration
    },
    {
      header: "BUDGET",
      className: "p-0 pb-3 min-w-75px text-start",
      render: (project) => `$${project.budget}`
    },
    {
      header: "PRIORITY",
      className: "p-0 pb-3 min-w-75px text-start",
      render: (project) => project.priority
    },
    {
      header: "STATUS",
      className: "p-0 pb-3 pe-3 min-w-75px text-start",
      render: (project) => <span className="badge py-3 fs-7 badge-light-primary">{project.status}</span>
    },
    {
      header: "ACTIONS",
      className: "p-0 pb-3 min-w-25px text-end",
      render: (project, onEdit, onDelete, mode) => (
        <div className="d-flex justify-content-end flex-shrink-0">
          <ActionButton iconType="pencil" onClick={(e) => {
            e.preventDefault();
            onEdit(project);
          }} />
          {/* Plan mode allows deletion */}
          <ActionButton iconType="trash" onClick={(e) => {
            e.preventDefault();
            onDelete(project);
          }} />
        </div>
      )
    }
  ],
  "Launch": [
    // Add columns specific to Launch mode here
    {
      header: "PROJECT NAME",
      className: "text-start",
      render: (project, onEdit) => (
        <div className="d-flex align-items-center" onClick={() => onEdit(project)}>
          <DuotoneCategory />
          <div className="d-flex justify-content-start flex-column">
            <a href="#" className="text-gray-800 fw-bold mb-1 fs-6">{project.name}</a>
          </div>
        </div>
      )
    },
    {
      header: "LAUNCH DATE",
      className: "text-start",
      render: (project) => project.launchDate || "-"
    },
    {
      header: "STATUS",
      className: "text-start",
      render: (project) => <span className="badge py-3 fs-7 badge-light-primary">{project.status}</span>
    },
    {
      header: "ACTIONS",
      className: "text-end",
      render: (project, onEdit, onDelete, mode) => (
        <div className="d-flex justify-content-end flex-shrink-0">
          <ActionButton iconType="pencil" onClick={(e) => {
            e.preventDefault();
            onEdit(project);
          }} />
          {/* maybe no delete in Launch */}
        </div>
      )
    }
  ],
  "Track": [
    // Add columns for Track mode similarly
    {
      header: "PROJECT NAME",
      className: "text-start",
      render: (project, onEdit) => (
        <div className="d-flex align-items-center" onClick={() => onEdit(project)}>
          <DuotoneCategory />
          <div className="d-flex justify-content-start flex-column">
            <a href="#" className="text-gray-800 fw-bold mb-1 fs-6">{project.name}</a>
          </div>
        </div>
      )
    },
    {
      header: "PROGRESS",
      className: "text-start",
      render: (project) => `${project.progress}%`
    },
    {
      header: "STATUS",
      className: "text-start",
      render: (project) => <span className="badge py-3 fs-7 badge-light-primary">{project.status}</span>
    },
    {
      header: "ACTIONS",
      className: "text-end",
      render: (project, onEdit, onDelete, mode) => (
        <div className="d-flex justify-content-end flex-shrink-0">
          <ActionButton iconType="view" onClick={(e) => {
            e.preventDefault();
            onEdit(project);
          }} />
          {/* Track mode allows deletion */}
          <ActionButton iconType="trash" onClick={(e) => {
            e.preventDefault();
            onDelete(project);
          }} />
        </div>
      )
    }
  ],
  "Close": [
    // Add columns for Close mode similarly
    {
      header: "PROJECT NAME",
      className: "text-start",
      render: (project, onEdit) => (
        <div className="d-flex align-items-center" onClick={() => onEdit(project)}>
          <DuotoneCategory />
          <div className="d-flex justify-content-start flex-column">
            <a href="#" className="text-gray-800 fw-bold mb-1 fs-6">{project.name}</a>
          </div>
        </div>
      )
    },
    {
      header: "CLOSURE DATE",
      className: "text-start",
      render: (project) => project.closureDate || "-"
    },
    {
      header: "MARK COMPLETE",
      className: "text-end",
      render: (project, onEdit, onDelete, mode) => (
        <div className="d-flex justify-content-end flex-shrink-0">
          <ActionButton iconType="done" onClick={(e) => {
            e.preventDefault();
            onEdit(project);
          }} />
          {/* maybe no delete in Close */}
        </div>
      )
    }
  ]
};
