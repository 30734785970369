import React, { useEffect, useState } from "react";
import { Typography, Box, Button, Divider, Tabs, Tab, Snackbar, useTheme } from "@mui/material";
import useAppStore from "../../../../appStore";
import { useNavigate } from "react-router-dom";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import { WorkItemsService } from "../../../../shared/services/work-items/workItemsService";
import SubHeader from "../../../../shared/components/page/headers/SubHeader";
import httpClient from "../../../../shared/utils/http-clients/djangoHttpClient";
import WorkItemMobileCard from "../../../work-items/components/work-item-list/components/table/components/mobile/WorkItemMobileCard";
import { useWorkItem } from "../../../work-items/context/workItemContext";
import CloseWorkItemModal from "./components/components/CloseWorkItemModal";
import EditWorkItem from "../../../work-items/components/edit-work-item/EditWorkItem";
import { WorkItem } from "../../../../shared/models/workItem.model";
import { useTranslation } from "react-i18next";

const UserWorkItemsList = ({ manager = false }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { appImages, masterRefresh, toggleMasterRefresh } = useAppStore();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const {
    workItemToEdit, setWorkItemToEdit,
    setEditing, open, setOpen,
    closingDialogOpen, setClosingDialogOpen, setSnackbarOpen
  } = useWorkItem();

  const [newWorkItems, setNewWorkItems] = useState<any>([]);
  const [existingWorkItems, setExistingWorkItems] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const service = new WorkItemsService();
  const [status, setStatus] = useState('Assigned');
  const [snackbar, setSnackbar] = useState<any>({ open: false, message: 'Acknowledged' });

  useEffect(() => {
    setIsLoading(true);
    setError("");
    service.getWorkItemsForEmployee(user.organization?.id, user?.employee.id, status)
      .then(items => {
        const sortedItems = items.sort((a, b) => b.urgency - a.urgency);
        if (status === 'Assigned') {
          setNewWorkItems(sortedItems.filter(item => !item.is_viewed));
          setExistingWorkItems(sortedItems.filter(item => item.is_viewed));
        } else {
          setExistingWorkItems(sortedItems);
        }
      })
      .catch(error => {
        setError(error instanceof Error ? error.message : String(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user, masterRefresh, status]);

  const handleTabChange = (event, newValue) => {
    setStatus(newValue);
  };

  const handleAcknowledgeClicked = async () => {
    try {
      await httpClient.post( `${process.env.REACT_APP_API_FACILITIES_SERVICE_URL}/workitems/viewed/`, user);
    } catch (error) {
      console.error('Error:', error);
      throw error;
    } finally {
      toggleMasterRefresh();
      setSnackbar(prev => ({
        ...prev,
        open: true,
        message: 'Acknowledged'
      }));
    }
  };

  const handleCloseModals = (action) => {
    setEditing(false);
    setOpen(false);
    setClosingDialogOpen(false);
    setWorkItemToEdit(new WorkItem());
    if (action === 'save') {
      setSnackbarOpen(true);
    }
  }

  function renderWorkItems() {
    return (
      <div>
        <div style={{marginTop: '25px'}}>
          <SubHeader title={t('work-items')} color="#777" margin={false} showAction={false} />
          <Divider sx={{ bgcolor: 'black' }} />
          <Tabs value={status} onChange={handleTabChange}>
            <Tab label={t('open')} value="Assigned" />
            <Tab label={t('closed')} value="Closed" />
          </Tabs>
          <Divider sx={{ bgcolor: "black", marginBottom: "10px" }} />
          {isLoading && (
            <div style={{ minHeight: "400px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CustomLoadingDots />
            </div>
          )}

          {existingWorkItems.map((workItem, index) => (
            <WorkItemMobileCard
              key={index}
              workItem={workItem}
              manager={manager}
              detailCard={true}
            />
          ))}

          {existingWorkItems.length === 0 &&
            <Box textAlign="center" p={5} pt={2}>
              <img
                src={appImages.iconWorkDone}
                alt="None"
                style={{ width: "105px", height: "110px" }} />
              <Typography variant="subtitle1" color="text.secondary">
                {t('no-assigned-items')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => navigate("/facilities/work-items")}
              >
                {t('view-all-work-items')}
              </Button>
            </Box>
          }

        </div>

      </div>
    );
  }

  function renderNewWorkItems() {
    return (
      <>
        {newWorkItems.length > 0 && (
          <div style={{marginTop: '25px'}}>
            <SubHeader title={'New Items'} color="#777" showAction={true} actionButtonName="Acknowledge" onActionClicked={handleAcknowledgeClicked} margin={false} />
            <Divider sx={{ bgcolor: 'black', marginBottom: '10px' }} />
            {isLoading && (
              <div style={{ minHeight: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CustomLoadingDots />
              </div>
            )}
            {newWorkItems.map((workItem, index) => (
              <WorkItemMobileCard
                key={index}
                workItem={workItem}
                manager={manager}
                detailCard={true}
              />
            ))}
            <div style={{marginBottom: '50px'}} />
          </div>
        )}
      </>
    );
  }

  return (
  <div>

      {error && <CustomError error={error} />}

      {!error && (
        <>
          <div>
              <>
                {renderNewWorkItems()}
                {renderWorkItems()}
              </>

          </div>

          <Snackbar
            open={snackbar.open}
            autoHideDuration={1000}
            onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
            message={snackbar.message}
          />
        </>
      )}

    {open && workItemToEdit &&
      <EditWorkItem
        open={open}
        onActionClicked={handleCloseModals}
      />
    }
    {closingDialogOpen && workItemToEdit &&
      <CloseWorkItemModal
        open={closingDialogOpen}
        onActionClicked={handleCloseModals}
      />
    }
    </div>
  );
};

export default UserWorkItemsList;
