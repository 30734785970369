import React, { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import EditOrgChartEmployee from "./EditOrgChartEmployee";
import { useTranslation } from "react-i18next";

const NodeTemplate = ({ node, setSelected, childrenCount, onSave }) => {
  const {t} = useTranslation();
  const [showDirectReports, setShowDirectReports] = useState(false);
  const [open, setOpen] = useState(false);

  const [modalNode, setModalNode] = useState(node);

  const toggleDirectReportsVisibility = () => setShowDirectReports(!showDirectReports);

  const handleEditAssignment = (nodeToEdit) => {
    setModalNode(nodeToEdit);
    setOpen(true);
  }

  return (
    <div className={`node-container`}>
      <div style={{ position: 'absolute', top: 0, right: 25, padding: '10px' }} onClick={() => handleEditAssignment(node)}>
        <EditIcon sx={{ color: '#777', fontSize: 'medium' }} />
      </div>
      <div className="node-content" onClick={() => setSelected(node)}>
        <img
          src={node.employee.person.photo_url || "/media/avatars/blank.png"}
          alt={`Employee`}
          style={{ width: '75px', height: '75px', borderRadius: '50%', objectFit: 'cover' }}
          onError={(e) => {
            const target = e.target;
            target.onerror = null;
            target.src = "/media/avatars/blank.png";
          }}
        />
        <div className="mt-1 fw-bold">
          {node.employee && node.employee.id !== -1
            ? `${node.employee.person.first_name} ${node.employee.person.last_name}`
            : "Open"}
        </div>
        <div className="text-muted fs-7">{node.name}</div>
        {node.children.length === 0 && childrenCount > 0 &&
          <AddCircleOutlineIcon sx={{ color: '#777'}} />
        }
      </div>
      {node.leaves && node.leaves.length > 0 && (
        <>
          <div style={{fontSize: '.8rem', color: "steelblue"}}>
            <button onClick={toggleDirectReportsVisibility} style={{ background: "none", border: "none", padding: 0, color: "inherit", font: "inherit", cursor: "pointer", outline: "inherit" }}>
              {showDirectReports ? 'Hide' : `${t('show-direct-reports')}`}
            </button>
          </div>
          {showDirectReports && (
            <div className="horizontal-stack" style={{ marginBottom: "10px" }}>
              {node.leaves.map((leaf) => (
                <div key={leaf.id} className="leaf-node text-muted d-flex flex-row justify-content-center" onClick={() => handleEditAssignment(leaf)}>
                  <div className="leaf-node-text">
                    {leaf.employee && leaf.employee.id !== -1
                      ? `${leaf.employee.person.first_name} ${leaf.employee.person.last_name} `
                      : "Open"}
                  </div>
                  <div style={{color: "steelblue", fontSize: '.75rem', marginLeft: '2px'}}>
                    (Edit)
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
      {open && (
        <EditOrgChartEmployee
          open={open}
          node={modalNode}
          onActionClicked={() => setOpen(false)}
          onSave={onSave}
        />
      )}
    </div>
  );
};

export default NodeTemplate;