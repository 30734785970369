import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  AppBar,
  IconButton,
  Typography,
  Toolbar,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomDatePicker from "../../../../../../shared/components/forms/date-time/date-picker/CustomDatePicker";
import { RelationshipService } from "../../../../../../shared/services/relationships/relationshipService";
import useAppStore from "../../../../../../appStore";
import { Employee } from "../../../../../../shared/models/employee.model";
import { AppDate } from "../../../../../../shared/utils/date-time/AppDate";

const EditActionItem = ({ open, onSave, onClose, relationship, actionItem, isEditing }) => {

  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const [dateCreated, setDateCreated] = useState<string | null>(actionItem.date_created);
  const [createdBy, setCreatedBy] = useState<Employee>(actionItem.created_by);
  const [dueDate, setDueDate] = useState<string | null>(actionItem.due_date);
  const [actionToTake, setActionToTake] = useState<string>(actionItem.action_to_take);
  const [resolution, setResolution] = useState<string>(actionItem.resolution);
  const [resolvedBy, setResolvedBy] = useState<Employee>(actionItem.resolved_by);
  const [dateResolved, setDateResolved] = useState<string | null>(actionItem.date_resolved);

  useEffect(() => {
    setDateCreated(actionItem.date_created);
    setCreatedBy(actionItem.created_by);
    setDueDate(actionItem.due_date);
    setActionToTake(actionItem.action_to_take);
    setResolution(actionItem.resolution);
    setResolvedBy(actionItem.resolved_by);
    setDateResolved(actionItem.date_resolved);
  }, [actionItem]);

  const handleSubmit = async () => {
    try {
      actionItem.date_created = actionItem.id ? dateCreated : new AppDate().dateTime;
      actionItem.created_by = createdBy.id !== -1 ? createdBy : user?.employee;
      actionItem.action_to_take = actionToTake;
      actionItem.due_date = dueDate;
      actionItem.resolution = resolution;
      actionItem.resolved_by = null;
      actionItem.date_resolved = dateResolved;

      const service = new RelationshipService();
      if (actionItem.id) {
        await service.updateActionPlanItem(relationship?.id, actionItem);
      } else {
        await service.createActionPlanItem(relationship?.id, actionItem);
      }
      onSave();
    } catch (error) {
      console.error('Failed to create action item:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Edit Action Item
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <Typography variant="h6">Action to Take</Typography>
          <TextField
            margin="dense"
            name="action_to_take"
            type="text"
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            value={actionToTake}
            onChange={(e) => setActionToTake(e.target.value)}
          />
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" sx={{marginBottom: '6px'}}>Due Date</Typography>
          <CustomDatePicker
            value={dueDate}
            onChange={(date) => setDueDate(date)}
            quickPick={false}
            prefill={false}
          />
        </Box>

        {isEditing &&
          <>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Resolution</Typography>
              <TextField
                margin="dense"
                name="resolution"
                type="text"
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                value={resolution}
                onChange={(e) => setResolution(e.target.value)}
              />
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" sx={{marginBottom: '6px'}}>Date Resolved</Typography>
              <CustomDatePicker
                value={dateResolved}
                onChange={(date) => setDateResolved(date)}
                quickPick={false}
                prefill={false}
              />
            </Box>
          </>
        }

      </DialogContent>
      <DialogActions sx={{ padding: '16px' }}>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditActionItem;

