// TellSageModal.jsx

import React, { useEffect, useRef, useState } from "react";
import SagePrompter from "./SagePrompter";
import { Button, useMediaQuery, useTheme, Box } from "@mui/material";
import { CasesService } from "../../../../shared/services/cases/casesService";
import {
  vaButtonContainerStyles,
  tellSageContainerStyles,
} from "../../../../shared/utils/table-modifier-util/tableDataUtil";
import SageCaseCreate from "./case-creation-assist/SageCaseCreate";
import useCasesStore from "../../caseStore";
import ChatComponent from "./chat-with-sage/ChatComponent";
import { useTranslation } from "react-i18next";

const TellSageModal = ({ onClose, fullScreen = false, incident = false }) => {
  const service = new CasesService();
  const isMobile = useMediaQuery(useTheme().breakpoints.only("xs"));
  const modalRef = useRef<any>(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const {
    sageResponse,
    setSageResponse,
    caseToEdit,
    setCaseToEdit,
    isTyping,
    setIsTyping,
    activeSection,
    setActiveSection,
    reset,
    initialSteps,
    endingSteps
  } = useCasesStore();

  const [sections, setSections] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const fetchType = async () => {
      try {
        const service = new CasesService();
        const safetyCaseType = await service.lookupCaseType(1, "safety_incident");
        setCaseToEdit(prev => ({ ...prev, case_type: safetyCaseType[0] }));
        setCaseToEdit(prev => ({ ...prev, department: safetyCaseType[0]?.department }));
      } catch (error) {
        console.error('Failed to fetch case type:', error);
      }
    }

    if (incident) {
      setActiveSection(2);
      fetchType().then();
    }

  }, [incident]);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const dynamicForm = await service.getDynamicForm(caseToEdit.case_type);
        const fetchedSections = dynamicForm.form.sections;
        setSections(fetchedSections);
      } catch (error) {
        console.error("Error fetching dynamic form:", error);
      }
    };

    if (caseToEdit.case_type.id !== 0) {
      fetchSections().then();
    }
  }, [caseToEdit.case_type]);

  useEffect(() => {
    const getResponseMessage = () => {
      const currentStepInfo = getCurrentStepInfo();
      return currentStepInfo?.sage_prompt || "";
    };

    setSageResponse(getResponseMessage());
  }, [activeSection, sections]);

  const handleNextStep = () => {
    setActiveSection(activeSection + 1);
  }

  const handlePrevStep = () => {
    setError('');
    setActiveSection(activeSection - 1);
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      setError('');
      await service.createCase(caseToEdit);
      handleNextStep();
    } catch (error) {
      setError(error instanceof Error ? error.message : String(error));
    } finally {
      setIsLoading(false);
    }
  };


  const handleRestart = () => {
    reset();
    setError('');
    setActiveSection(0);
  };

  const sectionsCount = initialSteps + sections.length + endingSteps;

  const getCurrentStepInfo = () => {

    if (activeSection < initialSteps) {
      return getInitialStepInfo(activeSection);
    } else if (activeSection < initialSteps + sections.length) {
      const sectionIndex = activeSection - initialSteps;
      return sections[sectionIndex];
    } else {
      return getEndingStepInfo(
        activeSection - initialSteps - sections.length
      );
    }
  };

  const getInitialStepInfo = (step) => {
    const initialPrompts = [
      {
        name: "Department Selection",
        sage_prompt: `${t('sage-prompt-1')}`,
      },
      {
        name: "Case Type Selection",
        sage_prompt: `${t('sage-prompt-2')}`,
      },
      {
        name: "Involved Employees",
        sage_prompt: `${t('sage-prompt-3')}`,
      },
      {
        name: "Details",
        sage_prompt: `${t('sage-prompt-4')}`,
      },
    ];

    return initialPrompts[step];
  };

  const getEndingStepInfo = (step) => {
    const endingPrompts = [
      {
        name: "Summary",
        sage_prompt: `${t('sage-prompt-5')}`,
      },
      {
        name: "Action",
        sage_prompt: `${t('sage-prompt-6')}`,
      },
    ];

    return endingPrompts[step];
  };

  const isSummaryStep = () => {
    return activeSection === initialSteps + sections.length;
  };

  const isActionStep = () => {
    return (
      activeSection === initialSteps + sections.length + endingSteps - 1
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div style={tellSageContainerStyles(isMobile, fullScreen)} ref={modalRef}>
      {/* Tab Navigation */}
      {/*<Box sx={{ borderBottom: 1, borderColor: "divider" }}>*/}
      {/*  <Tabs*/}
      {/*    value={tabValue}*/}
      {/*    onChange={handleTabChange}*/}
      {/*    aria-label="Assist and Chat Tabs"*/}
      {/*  >*/}
      {/*    <Tab label="REPORT" />*/}
      {/*    <Tab label="CHAT" />*/}
      {/*  </Tabs>*/}
      {/*</Box>*/}
      {/* ASSIST Tab Content */}
      {tabValue === 0 && (
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div style={{ overflowY: "auto", flex: 1 }}>
            <SagePrompter
              sageResponse={error === '' ? sageResponse : t('sage-error')}
              setIsTyping={setIsTyping}
            />
            {!isTyping && (
              <Box
                sx={{
                  justifyContent: "center",
                  marginTop: "10px",
                  marginBottom: "200px",
                }}
              >
                <SageCaseCreate
                  sections={sections}
                  error={error}
                />
              </Box>
            )}
          </div>
          <div style={vaButtonContainerStyles(activeSection)}>
            {/* Navigation Buttons */}
            {activeSection > 0 && !isSummaryStep() && !isActionStep() && (
              <Button
                onClick={handlePrevStep}
                style={{
                  marginBottom: "20px",
                  marginRight: "20px",
                  fontSize: "1.2rem",
                }}
              >
                Prev
              </Button>
            )}
            {activeSection > 1 && activeSection < sectionsCount - 1 && !isSummaryStep() && (
              <Button
                onClick={handleNextStep}
                style={{ marginBottom: "20px", fontSize: "1.2rem" }}
              >
                Next
              </Button>
            )}
            {!isTyping && isSummaryStep() && (
              isLoading ? (
                <Button
                  onClick={undefined}
                  style={{ marginBottom: "20px", fontSize: "1.25rem", color: 'darkgreen' }}
                >
                  Submitting...
                </Button>
              ) : (
                <Button
                  onClick={handleSubmit}
                  style={{ marginBottom: "20px", fontSize: "1.2rem" }}
                >
                  Submit
                </Button>
              )
            )}

            {!isTyping && isActionStep() && (
              <>
                <Button
                  onClick={handleRestart}
                  style={{
                    marginBottom: "20px",
                    marginRight: "20px",
                    fontSize: "1.2rem",
                  }}
                >
                  YES
                </Button>
                <Button
                  onClick={onClose}
                  style={{ marginBottom: "20px", fontSize: "1.2rem" }}
                >
                  NO
                </Button>
              </>
            )}
          </div>
        </div>
      )}
      {/* CHAT Tab Content */}
      {tabValue === 1 && (
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <ChatComponent />
        </div>
      )}
    </div>
  );
};

export default TellSageModal;



