/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import { KTIcon } from '../../../../../../_metronic/helpers/components/KTIcon'
import { useTranslation } from "react-i18next";


const CommentsReply = (props) => {

    const {
        onSubmitComment
    } = props

    const {t} = useTranslation();
    const [text, setText] = useState('');

    const handleTextareaChange = (event) => {
        setText(event.target.value);
    }

    const submitCommentHandler = () => {
        onSubmitComment(text);
        setText('');
    };


    return (

        <form className='position-relative mb-6'>
            <textarea
                className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                rows={1}
                placeholder={t('reply')}
                value={text}
                onChange={handleTextareaChange}
            ></textarea>

            <div className='position-absolute top-0 end-0 me-n5'>
                <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2'
                      onClick={submitCommentHandler}
                >
                    <KTIcon iconName='send' className='fs-3 mb-3' />
                </span>
            </div>
        </form>
    )

}

export {CommentsReply}
