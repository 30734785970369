import React from 'react';
import { List, ListItem, ListItemText, Button, Box } from "@mui/material";
import { useTranslation } from 'react-i18next';
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";
import useAppStore from "../../../../../../appStore";

const ServiceItemList = ({ items, onEditItem, isLoading }) => {
  const { t } = useTranslation();
  const {appImages} = useAppStore();

  if (isLoading) {
    return <p>{t('loading')}</p>;
  }

  if (!items || items.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        textAlign="center"
        p={2}
        mt={15}
      >
        <div>
          <img
            src={appImages.iconService}
            style={{ width: '175px', height: '175px', marginBottom: '10px' }}
          />
          <p
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              marginBottom: '15px',
            }}
          >
            {t('no-services')}
          </p>
          <p>{t('add-service-prompt')}</p>
        </div>
      </Box>
    );
  }

  return (
    <List>
      {items.map((item) => (
        <ListItem key={item.id} divider>
          <ListItemText primary={item.entity_type?.name} />
          <ActionButton iconType="view" onClick={(e) => {
            e.preventDefault();
            onEditItem(item);
          }} />
        </ListItem>
      ))}
    </List>
  );
};

export default ServiceItemList;
