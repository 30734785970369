import { BaseSerializer } from "./baseSerializer";
import { Trace } from "../trace.model";

export class TraceSerializer extends BaseSerializer {


  public deserialize( data ): any {

    const item = new Trace();

    this._copyAttributes(item, data);

    return item;

  }

}