import React from "react";
import { Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ScoreRow from "./ScoreRow";

const ScoreTable = (props) => {
  const {
    title,
    data,
    columns,
    showComments = true,
  } = props;

  const sortedData = [...data].sort((a, b) => a.question_number - b.question_number);

  return (
    <>
      <h3 style={{ color: '#444', margin: 0 }}>{title}</h3>
      <div className="mt-1">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
              <tbody>
              {sortedData.map((row, index) => (
                <ScoreRow
                  key={row.question_id || index}
                  row={row}
                  columns={columns}
                  showComments={showComments}
                />
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScoreTable;