import React, { useEffect, useState } from "react";
import { Box, CardMedia, Typography, Divider } from "@mui/material";
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { Media } from "../../../../models/media.model";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import PdfViewer from "../PdfViewer";
import { getMediaType } from "../../../../utils/table-modifier-util/tableDataUtil";
import { useTranslation } from "react-i18next";

const MediaDisplay = ({ itemMedia, captions = false, module }) => {
  const { t } = useTranslation();
  const [photoOpen, setPhotoOpen] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [defaultMedia, setDefaultMedia] = useState<Media[]>([]);
  const [closingMedia, setClosingMedia] = useState<Media[]>([]);
  const [tab, setTab] = useState(1);

  useEffect(() => {
    const sortedMedia = itemMedia.sort((a, b) => b.id - a.id);
    const before = sortedMedia.filter(media => media.workitem_status !== 'Closed');
    const after = sortedMedia.filter(media => media.workitem_status === 'Closed');
    setDefaultMedia(before);
    setClosingMedia(after);
  }, [itemMedia]);

  const handleOpen = (url) => {
    if (getMediaType(url) === 'pdf') {
      setPdfUrl(url);
      setPdfOpen(true);
    } else {
      setPhotoOpen(true);
    }
  };

  const handleClose = () => {
    setPhotoOpen(false);
  };

  const handleTabClick = (type) => {
    if (type === 'Before') {
      setTab(1);
    } else {
      setTab(2);
    }
  };

  const getDividerText = (module) => {
    switch (module) {
      case 'inspections':
        return t('photos');
      case 'workitems':
        return t('photos');
      case 'supplies':
        return 'SDS';
      case 'contracts':
        return 'Scope of Work';
      case 'cases':
        return 'Documents';
      default:
        return '';
    }
  };


  const displayPhotos = tab === 1 ? [...defaultMedia, new Media(), new Media(), new Media()].slice(0, 3) : [...closingMedia, new Media(), new Media(), new Media()].slice(0, 3);

  return (
    <div className="mb-5">
      {captions ? (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ mb: 1 }}>
          <Divider sx={{ alignSelf: "center", height: "1px", flex: '1', bgcolor: '#ccc', marginRight: '5px' }} />
            <Typography
              onClick={() => handleTabClick('Before')}
              style={{ fontWeight: tab === 1 ? 'bold' : '', cursor: 'pointer', marginRight: '5px', color: tab === 1 ? '#666' : '#999' }}
            >
              {t('before')} ({defaultMedia.length})
            </Typography>
            <Divider sx={{ alignSelf: 'center', height: '1px', flex: '1', bgcolor: '#ccc' }} />
            <Typography
              onClick={() => handleTabClick('After')}
              style={{ fontWeight: tab === 2 ? 'bold' : '', cursor: 'pointer', marginLeft: '5px', color: tab === 2 ? '#666' : '#999' }}
            >
              {t('after')} ({closingMedia.length})
            </Typography>
            <Divider sx={{ alignSelf: 'center', height: '1px', flex: '1', bgcolor: '#ccc', marginLeft: '5px' }} />
          </Box>
        ) : (
        <Divider textAlign="center" className="text-muted" sx={{ borderStyle: 'dotted', marginTop: '10px' }}>{getDividerText(module)}</Divider>
      )}
      <Box display="flex" justifyContent="center" alignItems="center">
        {displayPhotos.map((photo, index) => (
          <Box
            key={index}
            width={100}
            height={100}
            margin={1}
            border={1}
            borderColor={"grey.300"}
            onClick={() => photo.url && handleOpen(photo.url)}
            sx={{
              borderRadius: 2,
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            {photo.url ? (
              <CardMedia
                component="img"
                image={getMediaType(photo.url) === 'image' ? photo.url : "/white-labeling/verde/media/menu-icons/pdf-icon.png"}
                alt={`${index + 1}`}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Box width="100%" height="100%" />
            )}
            {photo.url && captions &&
              <Typography
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  padding: '2px 6px',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  color: 'white',
                  fontSize: '0.75rem'
                }}
              >
                {captions ? (photo.workitem_status === 'Closed' ? `${t('after')}` : `${t('before')}`) : ''}
              </Typography>
            }
          </Box>
        ))}
      </Box>
      {photoOpen && (
        <Lightbox
          open={photoOpen}
          close={() => handleClose()}
          plugins={[Captions]}
          slides={[...defaultMedia, ...closingMedia].map(photo => ({ src: photo.url, description: captions ? (photo.workitem_status === 'Closed' ? `${t('after')}` : `${t('before')}`) : '' }))}
        />
      )}
      {pdfOpen && (
        <PdfViewer open={pdfOpen} onClose={() => setPdfOpen(false)} url={pdfUrl} />
      )}
    </div>
  );
};

export default MediaDisplay;



