import { proxy } from 'valtio';
import { ProjectModel } from "../../shared/models/project.model";
import { dummyData } from "./data/dummyData";

interface ProjectStore {
  projects: ProjectModel[];
  selectedProject: ProjectModel | null;
  open: boolean;
  isEditing: boolean;
  mode: string;
}

export const projectStore = proxy<ProjectStore>({
  projects: [],
  selectedProject: null,
  open: false,
  isEditing: false,
  mode: 'Rank',
});

export const fetchProjects = async (): Promise<ProjectModel[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(dummyData); // Return dummy data after a delay
    }, 500); // Simulate network delay
  });
};
