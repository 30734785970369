import { BaseSerializer } from "./baseSerializer";
import { LocationContact } from "../locationContact.model";
import { PersonSerializer } from "./personSerializer";
import { EmployeeSerializer } from "./employeeSerializer";

export class LocationContactSerializer extends BaseSerializer {

  public deserialize(data): LocationContact {
    const contact = new LocationContact();
    this._copyAttributes(contact, data);

    let personSerializer = new PersonSerializer();
    contact.person = personSerializer.deserialize(data.person);
    let employeeSerializer = new EmployeeSerializer();
    contact.employee = employeeSerializer.deserialize(data.employee);

    return contact;
  }


}