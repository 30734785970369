import React, { useState, useEffect } from "react";
import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import MediaUploader from "../../media-uploader/MediaUploader";
import MediaDisplay from "./MediaDisplay";
import { useAuth } from "../../../../../modules/auth";

const PhotoUploaderViewer = ({ item, setMedia, closing=false, captions=true, module }) => {
  const {currentUser} = useAuth();
  const uploadUrl = process.env.REACT_APP_API_FACILITIES_SERVICE_URL + '/media/upload/';

  const [index, setIndex] = useState(-1);
  const [itemMedia, setItemMedia] = useState(item.media);
  const [mediaAdded, setMediaAdded] = useState(false)

  useEffect(() => {
    let newArray = item.media.map(obj => ({
      ...obj,
      src: obj.url
    }));
    setItemMedia(newArray);
  }, [item.media]);

  const mediaUploadCompleteHandler = (uploadedItem) => {
    let newItem = { ...uploadedItem, src: uploadedItem.url };
    const updatedMedia = [...itemMedia, newItem];
    setItemMedia(updatedMedia);
    setMedia(updatedMedia);
    setMediaAdded(true);
  };

  return (
    <div className="mb-10">
      <MediaUploader
        uploadUrl={uploadUrl}
        onUploadComplete={mediaUploadCompleteHandler}
        organization_id={3}
        person_id={currentUser?.employee.person.id}
        module={module}
      />
      {(!closing || mediaAdded) && itemMedia.length > 0 &&
        <div className="mt-3">
          <MediaDisplay itemMedia={itemMedia} captions={closing ? false : captions} module={module} />
        </div>
      }
      <Lightbox
        slides={itemMedia}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </div>
  );
};

export default PhotoUploaderViewer;
