import React, { useState } from "react";
import PageHeader from "../../shared/components/page/headers/PageHeader";
import WorkItemList from './components/work-item-list/WorkItemList';
import EditWorkItem from './components/edit-work-item/EditWorkItem';
import { WorkItem } from "../../shared/models/workItem.model";
import { WorkItemsService } from "../../shared/services/work-items/workItemsService";
import { ConfirmationDialog } from "../../shared/components/page/popup-dialog/ConfirmationDialog";
import { Box, Snackbar, useMediaQuery, useTheme } from "@mui/material";
import { useWorkItem } from "./context/workItemContext";
import useAppStore from "../../appStore";
import PrintableWorkItems from "./components/print-work-items/PrintableWorkItems";
import CloseWorkItemModal from "../dashboard/components/user-work-items/components/components/CloseWorkItemModal";
import { useTranslation } from "react-i18next";


const WorkItemsPage = () => {

    const workItemService = new WorkItemsService();

    const { t } = useTranslation();
    const { toggleMasterRefresh } = useAppStore();
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    const {
        workItemToEdit, setWorkItemToEdit,
        isEditing, setEditing,
        open, setOpen,
        snackbarOpen, setSnackbarOpen,
        closingDialogOpen, setClosingDialogOpen
        } = useWorkItem();

    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [workItemToDelete, setWorkItemToDelete] = useState<WorkItem | null>(null);
    const [printOpen, setPrintOpen] = useState(false);

    const handleNewWorkItem = (e) => {
        let workItem = new WorkItem();
        setWorkItemToEdit(workItem)
        setOpen(true)
        setEditing(true)
    }
    const handleEditWorkItem = (workItem) => {
        setWorkItemToEdit(workItem);
        setEditing(true);
        setOpen(true);
    };

    const handleCloseModals = (action) => {
        setEditing(false);
        setOpen(false);
        setClosingDialogOpen(false);
        setWorkItemToEdit(new WorkItem());
        if (action === 'save') {
            setSnackbarOpen(true);
        }
    };

    const handleConfirmDelete = async () => {
        if (workItemToDelete) {
            try {
                await workItemService.deleteWorkItem(workItemToDelete.id);
                setConfirmDeleteOpen(false);
                toggleMasterRefresh();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleDeleteWorkItemClicked = (workItem) => {
        setWorkItemToDelete(workItem);
        setConfirmDeleteOpen(true);
    };

    return (
      <>
          {!isEditing && (
            <div>
                <PageHeader
                  title={t('work-items')}
                  actionButtonName={t('new-work-item')}
                  onActionClicked = {handleNewWorkItem}
                  secondaryButtonName={t('print-work-items')}
                  onSecondaryClicked={() => setPrintOpen(true)}
                  showSecondary={isMobile ? false : true}
                />
                <Box mt={3} mb={'120px'}>
                    <WorkItemList
                      onEdit={handleEditWorkItem}
                      onDelete={handleDeleteWorkItemClicked}
                    />
                </Box>
            </div>
          )}
          {open && workItemToEdit &&
            <EditWorkItem
              open={open}
              onActionClicked={handleCloseModals}
            />
          }
          {printOpen &&
            <PrintableWorkItems
              open={printOpen}
              onActionClicked={() => setPrintOpen(false)}
            />
          }
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={800}
            onClose={() => setSnackbarOpen(false)}
            message="Work Item Saved"
          />
          {workItemToDelete && confirmDeleteOpen && (
              <ConfirmationDialog
              isOpen={confirmDeleteOpen}
              setIsOpen={setConfirmDeleteOpen}
              message="Delete Work Item?"
              description={`Are you sure you want to delete this work item? This action cannot be undone.`}
              onConfirmClicked={handleConfirmDelete}
              />
          )}
          {closingDialogOpen && workItemToEdit &&
            <CloseWorkItemModal
              open={closingDialogOpen}
              onActionClicked={handleCloseModals}
            />
          }
      </>
    )

}

export default WorkItemsPage
