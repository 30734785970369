import React from "react";
import { AppBar, Dialog, IconButton, Typography, Toolbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ModalTransition from "../../../../../../shared/components/page/transitions/ModalTransition";
import InspectionsPage from "../../../../../inspections/InspectionsPage";


const JointInspectionModal = ({ open, onActionClicked }) => {

  return (
    <Dialog fullScreen open={open} onClose={() => onActionClicked('close')} TransitionComponent={ModalTransition} PaperProps={{ style: { overflowX: 'hidden' } }}>
      <AppBar sx={{ position: 'relative', mb: 2 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div style={{padding: '25px'}}>
        <InspectionsPage jointOnly={true}/>
      </div>

    </Dialog>
  );
};

export default JointInspectionModal;