import React, { useEffect, useState } from "react";
import useAppStore from "../../../../../appStore";
import { CasesService } from "../../../../../shared/services/cases/casesService";
import { GroupEntry } from "../../../../../shared/models/groupEntry.model";
import { Media } from "../../../../../shared/models/media.model";
import { Button, FormControl } from "@mui/material";
import Box from "@mui/material/Box";
import RelatedEmployeesField from "./reusable-fields/RelatedEmployeesField";
import { CaseType } from "../../../../../shared/models/caseType.model";
import useCasesStore from "../../../caseStore";
import TextAreaField from "./reusable-fields/TextAreaField";
import UserItemsTable from "../../../../sage-virtual-assistant/created-items-assist/UserItemsTable";
import DateField from "./reusable-fields/DateField";
import SageCaseSummary from "./summary-section/SageCaseSummary";
import RadioButtonField from "./reusable-fields/RadioButtonField";
import BaseCaseDetails from "./reusable-fields/BaseCaseDetails";
import SelectDropdownField from "./reusable-fields/SelectDropdownField";
import CustomError from "../../../../../shared/components/page/popup-dialog/CustomError";

const SageCaseCreate = ({ sections, error }) => {
  const {
    caseToEdit,
    setCaseToEdit,
    activeSection,
    setActiveSection,
    setIsTyping,
    initialSteps
  } = useCasesStore();

  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { selectedLocation } = useAppStore();
  const service = new CasesService();
  const [departments, setDepartments] = useState<GroupEntry[]>([]);
  const [caseTypes, setCaseTypes] = useState<CaseType[]>([]);
  const [media, setMedia] = useState<Media[]>(caseToEdit.media);

  useEffect(() => {
    if (caseToEdit.id === null || caseToEdit.id === 0) {
      setCaseToEdit((prevWorkItem) => ({
        ...prevWorkItem,
        created_by: user.employee,
        location: {
          ...prevWorkItem.location,
          id: selectedLocation.id || 0,
        },
        provider: {
          ...prevWorkItem.provider,
          id: user.organization?.id || 0,
        },
      }));
    }
  }, [caseToEdit.id]);

  useEffect(() => {
    setCaseToEdit((prev) => ({
      ...prev,
      media: media,
    }));
  }, [media]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const depts = await service.getDepartments(user?.organization);
        setDepartments(depts);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments().then();
  }, [user?.organization?.id]);

  useEffect(() => {
    if (caseToEdit.department?.id) {
      const fetchTypes = async () => {
        try {
          const types = await service.getCaseTypes(
            user?.organization,
            caseToEdit.department.id
          );
          setCaseTypes(types);
        } catch (error) {
          console.error("Error fetching case types:", error);
        }
      };

      fetchTypes();
    }
  }, [caseToEdit.department]);

  const handleDepartmentChange = (dept: GroupEntry) => {
    setCaseToEdit({
      ...caseToEdit,
      department: dept,
    });
    setIsTyping(true);
    setActiveSection(activeSection + 1);
  };

  const handleCaseTypeChange = (type: CaseType) => {
    caseToEdit.department = type.department;
    setCaseToEdit({
      ...caseToEdit,
      department: type.department,
    });
    setCaseToEdit({
      ...caseToEdit,
      case_type: type,
    });
    setIsTyping(true);
    setActiveSection(activeSection + 1);
  };

  const handleNextStep = () => {
    setIsTyping(true);
    setActiveSection(activeSection + 1);
  };

  const handleCustomFormInputChange = (fieldName, value) => {
    setCaseToEdit((prevCaseToEdit) => ({
      ...prevCaseToEdit,
      form_data: {
        ...prevCaseToEdit.form_data,
        [fieldName]: value,
      },
    }));
  };

  // Determine which content to render based on currentStep
  const renderContent = () => {
    if (activeSection < initialSteps) {
      return renderInitialStep(activeSection);
    } else if (activeSection < initialSteps + sections.length) {
      const sectionIndex = activeSection - initialSteps;
      const currentSection = sections[sectionIndex];
      return renderCustomSection(currentSection);
    } else {
      return renderEndingStep(activeSection - initialSteps - sections.length);
    }
  };

  const renderInitialStep = (step) => {
    switch (step) {
      case 0:
        return (
          <FormControl fullWidth style={{ marginBottom: 15 }}>
            <Box>
              {departments.map((dept) => (
                <Button
                  key={dept.id}
                  onClick={() => handleDepartmentChange(dept)}
                  fullWidth
                  style={{ marginBottom: 10, fontSize: "1.1rem" }}
                >
                  {dept.name}
                </Button>
              ))}
            </Box>
          </FormControl>
        );
      case 1:
        return (
          <FormControl fullWidth style={{ marginBottom: 15 }}>
            <Box>
              {caseTypes.map((type) => (
                <Button
                  key={type.id}
                  onClick={() => handleCaseTypeChange(type)}
                  fullWidth
                  style={{ marginBottom: 10, fontSize: "1.1rem" }}
                >
                  {type.name}
                </Button>
              ))}
            </Box>
          </FormControl>
        );
      case 2:
        return (
          <div style={{ marginTop: 15 }}>
            <RelatedEmployeesField
              itemToEdit={caseToEdit}
              onSubmit={handleNextStep}
            />
          </div>
        );
      case 3:
        return (
          <div style={{ marginTop: 15 }}>
            <BaseCaseDetails />
          </div>
        );
      default:
        return null;
    }
  };

  const renderCustomSection = (currentSection) => {
    return (
      <>
        {currentSection.fields.map((field, index) => {
          switch (field.type) {
            case "TextArea":
              return (
                <TextAreaField
                  key={index}
                  value={caseToEdit.form_data[field.data_source] || ""}
                  onChange={(e) =>
                    handleCustomFormInputChange(
                      field.data_source,
                      e.target.value
                    )
                  }
                  label={field.name}
                  multiline={true}
                  rows={5}
                />
              );
            case "TextField":
              return (
                <TextAreaField
                  key={index}
                  value={caseToEdit.form_data[field.data_source] || ""}
                  onChange={(e) =>
                    handleCustomFormInputChange(
                      field.data_source,
                      e.target.value
                    )
                  }
                  label={field.name}
                  multiline={false}
                  rows={1}
                />
              );
            case 'Select':
              return (
                <SelectDropdownField
                  key={index}
                  label={field.name}
                  value={caseToEdit.form_data[field.data_source] || ""}
                  onChange={(event) => handleCustomFormInputChange(field.data_source, event.target.value)}
                  options={[
                    { value: 'Abrasion, Scrapes, Cut', label: 'Abrasion, Scrapes, Cut' },
                    { value: 'Sprain, Strain', label: 'Sprain, Strain' },
                    { value: 'Burn', label: 'Burn' },
                    { value: 'Pain', label: 'Pain' },
                    { value: 'Nausea', label: 'Nausea' },
                    { value: 'Headache, Dizziness', label: 'Headache, Dizziness' },
                    { value: 'Difficulty Breathing', label: 'Difficulty Breathing' },
                    { value: 'Slip, Trip, or Fall', label: 'Slip, Trip, or Fall' },
                    { value: 'Heat Exhaustion', label: 'Heat Exhaustion' },
                    { value: 'Drug Use', label: 'Drug Use' },
                    { value: 'Eye / Vision', label: 'Eye / Vision' },
                    { value: 'Other', label: 'Other' },
                  ]} />
              );
            case "Date":
              return (
                <DateField
                  key={index}
                  label={field.name}
                  value={caseToEdit.form_data[field.data_source] || ""}
                  onChange={(newValue) =>
                    handleCustomFormInputChange(field.data_source, newValue)
                  }
                />
              );
            case "Radio":
              return (
                <RadioButtonField
                  key={index}
                  label={field.name}
                  value={caseToEdit.form_data[field.data_source] || "No"}
                  onChange={(event) =>
                    handleCustomFormInputChange(
                      field.data_source,
                      event.target.value
                    )
                  }
                />
              );
            default:
              return null;
          }
        })}
      </>
    );
  };

  const renderEndingStep = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <SageCaseSummary />
          </>
        );
      case 1:
        return (
          <>
            {error !== '' ? (
              <CustomError error={error} />
            ) : (
              <UserItemsTable
                items={[caseToEdit]}
                type={"case"}
                details={false}
              />
            )}
          </>
        );
      default:
        return null;
    }
  };

  return <div className="flex-column-fluid">{renderContent()}</div>;
};

export default SageCaseCreate;


