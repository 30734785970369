import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  AppBar,
  IconButton,
  Typography,
  Toolbar,
  Box,
  ToggleButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputMask from "react-input-mask";
import useAppStore from "../../../../../../../appStore";
import { Person } from "../../../../../../../shared/models/person.model";
import { ContactService } from "../../../../../../../shared/services/location-contacts/contactService";
import { Employee } from "../../../../../../../shared/models/employee.model";
import { LocationModel } from "../../../../../../../shared/models/location.model";
import { GroupEntry } from "../../../../../../../shared/models/groupEntry.model";
import CustomError from "../../../../../../../shared/components/page/popup-dialog/CustomError";
import UserPermissionsPanel from "../../../../../../employees/components/edit-employee/components/form/permissions/UserPermissionsPanel";
import { AppUserModel } from "../../../../../../../shared/models/appUserModel";
import { LocationContact } from "../../../../../../../shared/models/locationContact.model";

const EditLocationContact = ({
                               contactToEdit,
                               setContactToEdit,
                               open,
                               onClose,
                               onSave
                             }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { selectedLocation } = useAppStore();
  const [id, setId] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [selectedTier, setSelectedTier] = useState<GroupEntry | null>(
    contactToEdit.csat_reporting_group_entry || null
  );
  const [tierError, setTierError] = useState("");
  const [notes, setNotes] = useState("");
  const [title, setTitle] = useState("");
  const [appUser, setAppUser] = useState<AppUserModel>(new AppUserModel());

  const [availableTiers, setAvailableTiers] = useState<GroupEntry[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const service = new ContactService();
        const fetched = await service.getContact(contactToEdit);
        setContactToEdit(fetched);
      } catch (error) {
        console.error("Failed to fetch contact details:", error);
      }
    };

    if (contactToEdit.id !== 0) {
      fetchContact().then();
    }
  }, [contactToEdit.id]);

  useEffect(() => {
    if (contactToEdit) {
      setError("");
      setId(contactToEdit?.id || 0);
      setFirstName(contactToEdit?.employee?.person?.first_name || "");
      setLastName(contactToEdit?.employee?.person?.last_name || "");
      setEmail(contactToEdit?.employee?.person?.email || "");
      setPhoneNumber(contactToEdit?.employee?.person?.phone_number || "");
      setSelectedTier(contactToEdit?.csat_reporting_group_entry || null);
      setNotes(contactToEdit?.notes || "");
      setTitle(contactToEdit?.title || "");
      setAppUser(contactToEdit?.employee?.user || null);
    }
  }, [contactToEdit, contactToEdit?.employee?.user]);

  const fetchTiers = useCallback(async () => {
    setIsLoading(true);
    try {
      const service = new ContactService();
      const tiers = await service.getTiers(user?.organization);
      setAvailableTiers(tiers);
    } catch (error) {
      console.error("Error fetching tiers:", error);
    } finally {
      setIsLoading(false);
    }
  }, [user.organization]);

  useEffect(() => {
    fetchTiers().then();
  }, [fetchTiers]);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    const digits = value.replace(/\D/g, "");
    if (digits.length === 10) {
      setPhoneNumberError("");
    } else {
      setPhoneNumberError("Invalid phone number.");
    }
  };

  const handleToggleTier = (tier) => {
    setSelectedTier(selectedTier?.id === tier.id ? null : tier);
    setTierError("");
  };

  const handleSubmit = async () => {
    let hasError = false;

    if (!selectedTier || selectedTier.id === 0) {
      setTierError("Please select a tier before submitting.");
      hasError = true;
    } else {
      setTierError("");
    }

    if (!firstName) {
      setFirstNameError("First name is required.");
      hasError = true;
    } else {
      setFirstNameError("");
    }

    if (!lastName) {
      setLastNameError("Last name is required.");
      hasError = true;
    } else {
      setLastNameError("");
    }

    const digits = phoneNumber.replace(/\D/g, "");
    if (!phoneNumber) {
      setPhoneNumberError("Phone number is required.");
      hasError = true;
    } else if (digits.length !== 10) {
      setPhoneNumberError("Invalid phone number.");
      hasError = true;
    } else {
      setPhoneNumberError("");
    }

    if (hasError) {
      return;
    }

    try {
      let employee = new Employee();
      let location = new LocationModel();
      let person = new Person();

      person.first_name = firstName;
      person.last_name = lastName;
      person.phone_number = phoneNumber;
      person.email = email;
      employee.person = person;
      location.id = selectedLocation.id;

      let contact = new LocationContact();
      contact.id = id;
      contact.employee = employee;
      contact.location = location;
      contact.csat_reporting_group_entry = selectedTier;
      contact.notes = notes;
      contact.title = title;
      contact.employee.user = appUser;

      const service = new ContactService();
      const response =
        id === 0
          ? await service.createContact(contact)
          : await service.updateContact(contact);
      onSave(response);
    } catch (error) {
      console.error("Failed to create or update contact:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {/* Overall Error Message */}
        {error && <CustomError error={error} />}

        {/* Tier Selection */}
        <Typography
          sx={{ flex: 1, fontSize: "0.9rem", color: "#777", ml: "1px" }}
          component="div"
        >
          Contact Tier
        </Typography>

        <Box
          sx={{ display: "flex", flexWrap: "wrap", gap: "10px", marginBottom: "10px" }}
        >
          {availableTiers.map((tier) => (
            <ToggleButton
              key={tier.id}
              value={tier.id}
              selected={selectedTier?.id === tier.id}
              onChange={() => handleToggleTier(tier)}
            >
              {tier.name}
            </ToggleButton>
          ))}
        </Box>
        {tierError && (
          <Typography color="error" variant="body2">
            {tierError}
          </Typography>
        )}

        {/* First Name */}
        <TextField
          margin="dense"
          name="first_name"
          label="First Name"
          type="text"
          fullWidth
          variant="outlined"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
            if (e.target.value) {
              setFirstNameError("");
            }
          }}
          error={!!firstNameError}
          helperText={firstNameError}
        />

        {/* Last Name */}
        <TextField
          margin="dense"
          name="last_name"
          label="Last Name"
          type="text"
          fullWidth
          variant="outlined"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
            if (e.target.value) {
              setLastNameError("");
            }
          }}
          error={!!lastNameError}
          helperText={lastNameError}
        />

        {/* Phone Number */}
        <InputMask
          mask="(999) 999-9999"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        >
          {(inputProps) => (
            <TextField
              {...inputProps}
              margin="dense"
              name="phone_number"
              label="Phone Number"
              type="tel"
              fullWidth
              variant="outlined"
              error={!!phoneNumberError}
              helperText={phoneNumberError}
            />
          )}
        </InputMask>

        {/* Email */}
        <TextField
          margin="dense"
          name="email"
          label="Email Address"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {/* Title */}
        <TextField
          margin="dense"
          name="title"
          label="Title"
          type="title"
          fullWidth
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        {/* Notes */}
        <TextField
          margin="dense"
          name="notes"
          label="Notes"
          type="notes"
          fullWidth
          variant="outlined"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />

        {/* User Permissions Panel */}
        <UserPermissionsPanel
          profile={contactToEdit.employee}
          includeRole={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          {id === 0 ? "Submit" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLocationContact;




