import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { useTranslation } from "react-i18next";

const UrgencyTable = ({ low = 0, medium = 0, high = 0, overdue = 0 }) => {

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const {t} = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Typography
        variant="h5"
        component="div"
        sx={{ padding: 2, textAlign: 'center', backgroundColor: 'primary.main', color: 'primary.contrastText' }}
      >
        {t('urgency')} ({t('coming-soon')})
      </Typography>
      <Table sx={{ ...(isMobile ? {} : { height: '220px' }) }}>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">Overdue</TableCell>
            <TableCell align="right" sx={{color: 'red'}}>{overdue}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">High</TableCell>
            <TableCell align="right" sx={{color: 'red'}}>{high}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">Medium</TableCell>
            <TableCell align="right">{medium}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">Low</TableCell>
            <TableCell align="right">{low}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UrgencyTable;

