import React, { useState } from "react";
import PageHeader from "../../shared/components/page/headers/PageHeader";
import PositionsList from "./components/equipment-list/PositionsList";

const PositionsPage = () => {
  return (
    <>
      <PageHeader title='Positions'
                  showAction={false}
      />
      <PositionsList />
    </>
  );

}

export default PositionsPage