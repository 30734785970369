import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  Alert
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Lightbox from "yet-another-react-lightbox";
import LanguageMediaDisplay from "./components/LanguageMediaDisplay";
import useAppStore from "../../../../../appStore";
import useTrainingStore from "../../trainingStore";
import { LanguageService } from "../../../../../shared/services/language/languageService";
import { LanguageModel } from "../../../../../shared/models/language.model";
import ActionDropdownButton from "../../../../../shared/components/buttons/ActionDropdownButton";
import CourseActionModal from "./components/CourseActionModal";

const EditCourse = ({ open, course, setCourse, onSubmit, onClose, readOnly = false }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { appImages } = useAppStore();
  const service = new LanguageService();
  const [languages, setLanguages] = useState<LanguageModel[]>([]);
  const { selectedCategory } = useTrainingStore();
  const [action, setAction] = useState<string>("none");
  const [courseActionOpen, setCourseActionOpen] = React.useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [media, setMedia] = useState(course.media || []);
  const uploadUrl =
    process.env.REACT_APP_API_FACILITIES_SERVICE_URL + "/media/upload/";
  const [photoOpen, setPhotoOpen] = useState(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    service
      .getLanguages(user.organization.id)
      .then((data) => {
        setLanguages(data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  useEffect(() => {
    if (course.categories) {
      if (!course.categories.includes(selectedCategory)) {
        course.categories = [...course.categories, selectedCategory];
      }
    } else {
      course.categories = [selectedCategory];
    }

    if (course.id === 0) {
      setIsEditing(true);
      course.organization = user?.organization;
    }
  }, [course.id, selectedCategory]);

  const handleSave = () => {
    onSubmit(course);
    onClose();
  };

  const handleUploadComplete = (newItem, langCode) => {
    const language = languages.find((lang) => lang.code === langCode);
    if (!language) {
      setError(
        `Language with code ${langCode} not found in database. Contact dev team.`
      );
      return;
    }

    setError("");

    newItem.language = language;

    setCourse((prevCourse) => {
      const updatedMedia = [...(prevCourse.media || [])];
      const existingIndex = updatedMedia.findIndex(
        (media) => media.language && media.language.code === langCode
      );
      if (existingIndex !== -1) {
        updatedMedia[existingIndex] = newItem;
      } else {
        updatedMedia.push(newItem);
      }

      setMedia(updatedMedia);
      course.media = updatedMedia;

      return {
        ...prevCourse,
        media: updatedMedia,
      };
    });
  };

  const openLightbox = (url) => {
    setPhotoOpen(true);
  };

  const handleAction = (action: string) => {
    setAction(action);
    setCourseActionOpen(true);
  };

  const handleCloseCourseAction = () => {
    setAction("none");
    setCourseActionOpen(false);
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            filter: courseActionOpen ? "blur(2px)" : "none",
            transition: "filter 0.3s",
          },
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <div style={{ flexGrow: 1 }}></div>
            {readOnly && (
              <Typography variant="h6" color="inherit" component="div">
                {course.name}
              </Typography>
            )}
            {!readOnly &&
              <ActionDropdownButton
                options={["Move", "Duplicate", "Delete"]}
                onClick={(selectedOption) => {
                  handleAction(selectedOption);
                }}
              />
            }
          </Toolbar>
        </AppBar>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          {!readOnly && (
            <TextField
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={course.name}
              onChange={(e) => setCourse({ ...course, name: e.target.value })}
            />
          )}

          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
            <LanguageMediaDisplay
              media={media.find((m) => m.language?.code === "en") || {}}
              label="English"
              iconNoPdf={appImages.iconNoPdf}
              uploadUrl={uploadUrl}
              onUploadComplete={(item) => handleUploadComplete(item, "en")}
              onMediaClick={openLightbox}
              readOnly={readOnly}
            />
            <LanguageMediaDisplay
              media={media.find((m) => m.language?.code === "es") || {}}
              label="Español"
              iconNoPdf={appImages.iconNoPdf}
              uploadUrl={uploadUrl}
              onUploadComplete={(item) => handleUploadComplete(item, "es")}
              onMediaClick={openLightbox}
              readOnly={readOnly}
            />
          </Box>
        </DialogContent>
        {!readOnly && (
          <DialogActions>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        )}
      </Dialog>

      {courseActionOpen && (
        <CourseActionModal
          open={courseActionOpen}
          course={course}
          action={action}
          onClose={handleCloseCourseAction}
        />
      )}

      <Lightbox
        open={photoOpen}
        close={() => setPhotoOpen(false)}
        slides={media.filter((m) => m.url).map((m) => ({ src: m.url }))}
      />
    </>
  );
};

export default EditCourse;


