import React from 'react';
import {
  getCaseAssignee,
  getCaseRelatedEmployee,
  getTimeDifferenceString
} from "../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";
import useAppStore from "../../../../../../appStore";
import './CaseCard.css';
import DuotoneCustomIcon from "../../../../../../shared/components/page/row-icons/DuotoneCustomIcon";
import { useTranslation } from "react-i18next";

const CaseCard = (props) => {
  const { item, onEdit } = props;
  const { appImages } = useAppStore();
  const {t} = useTranslation();

  const departmentImageMap = {
    "Human Resources": appImages.iconHrCase,
    "Marketing": appImages.iconMarketingCase,
    "EHS": appImages.iconSafetyCase,
  };

  const cardImage = departmentImageMap[item.department.name] || appImages.iconGenericCase;
  const caseTitle = item?.case_type?.name;
  const caseDepartment = item?.department?.name;
  const caseAging = getTimeDifferenceString(item.create_date);
  const createdBy = item.created_by?.person.first_name + " " + item.created_by?.person.last_name;
  const assignedTo = getCaseAssignee(item);
  const relatedTo = getCaseRelatedEmployee(item);

  return (
    <div className="case-card">
      <div className="card-header">
        <DuotoneCustomIcon photo={cardImage} photoHeight={'57px'} photoWidth={'57px'} colorClass={'bg-light-success'}
                           itemNumber={item.case_number} />
        <div className="case-details">
          <h2 className="case-title">{caseTitle}</h2>
          <p className="case-department text-muted">{caseDepartment}</p>
          <p className="case-number">#{item.case_number}</p>
        </div>
        <div className="ranking-section">
          <ActionButton
            iconType={"view"}
            onClick={(e) => {
              e.preventDefault();
              onEdit(item);
            }}
          />
        </div>
      </div>

      <div className="card-body-2">
        <p className="case-location">
          {t('location')}: <span className="text-primary">{item?.location?.name || ''}</span>
        </p>
        <p className="created-by">
          {t('submitted')}: <span className="text-primary">{caseAging} ({createdBy})</span>
        </p>
        <p className="assigned-to">
          {t('assigned-to')}: <span className="text-primary">{assignedTo || "--"}</span>
        </p>
        <p className="related-to">
          {t('involved-employees')}: <span className="text-primary">{relatedTo || "--"}</span>
        </p>
      </div>


    </div>
  );
};

export default CaseCard;
