import React, { useEffect, useState } from "react";
import WorkItemAnalyticsCard from "./components/WorkItemsAnalyticsCard";
import useAppStore from "../../../../../../../appStore";
import CustomLoadingDots from "../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../../shared/components/page/popup-dialog/CustomError";
import { WorkItemAnalytics } from "../../../../../../../shared/models/workItemAnaytics.model";
import { SiteHealthService } from "../../../../../../../shared/services/site-health/siteHealthService";

const WorkItemsAnalytics = () => {

  const service = new SiteHealthService();
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [workItemAnalytics, setWorkItemAnalytics] = useState<WorkItemAnalytics>(new WorkItemAnalytics());


  useEffect(() => {
    setIsLoading(true);
    setError('');
    service.getWorkItemAnalytics(user.organization?.id)
      .then(analytics => {
        setWorkItemAnalytics(analytics);
      })
      .catch(error => {
        setError(error instanceof Error ? error.message : String(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user.organization?.id]);


  if (isLoading) {
    return <CustomLoadingDots />;
  }
  if (error) {
    return <CustomError error={error} />;
  }


  return (
    <div>
      <WorkItemAnalyticsCard data={workItemAnalytics} />
    </div>
  );
}

export default WorkItemsAnalytics;

