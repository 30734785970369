import React, { useEffect, useState } from "react";
import EmptyEditPage from '../../shared/components/forms/empty/EmptyEditPage';
import PageHeader from "../../shared/components/page/headers/PageHeader";


const MobileLauncherPage = (props) => {

    const {

    } = props;

    useEffect(() => {

    },[]);


    return (
        <>
            <PageHeader title="Launcher"
                        showAction={false} />

            <EmptyEditPage />
        </>
    )

}

export default MobileLauncherPage
