import React from "react";
import useAppStore from "../../../../appStore";
import PageHeader from "../../../../shared/components/page/headers/PageHeader";
import FormList from "./components/table/FormList";
import EditForm from "./components/edit-form/EditForm";
import { useSnapshot } from 'valtio';
import { formState, formActions } from './formStore';

const FormBuilder = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { toggleMasterRefresh } = useAppStore();
  const snap = useSnapshot(formState);

  const handleDialogClose = (action = '') => {
    formActions.setOpen(false);
    formActions.setFormToEdit(null);
    if (action === 'save') {
      toggleMasterRefresh();
    }
  };

  const handleNew = () => {
    let form = new FormData();
    formActions.setFormToEdit(form);
    formActions.setOpen(true);
  };

  const handleEdit = (form) => {
    formActions.setFormToEdit(form);
    formActions.setOpen(true);
  };

  const handleSaveAndRefresh = (response) => {
    handleDialogClose('save');
  }

  return (
    <div className="card w-100">
      <div className="card-body d-flex flex-column" style={{ width: '100%', minHeight: '300px' }}>
        <PageHeader
          title="Custom Forms"
          actionButtonName="New Form"
          onActionClicked={handleNew}
          divider={false}
        />

        <FormList onEdit={handleEdit}/>

        {snap.open && (
          <EditForm
            formToEdit={snap.formToEdit}
            setFormToEdit={formActions.setFormToEdit}
            open={snap.open}
            onClose={() => formActions.setOpen(false)}
            onSave={handleSaveAndRefresh}
          />
        )}
      </div>
    </div>
  );
}

export default FormBuilder;