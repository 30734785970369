import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField, AppBar, IconButton, Typography, Toolbar
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputMask from "react-input-mask";
import { RelationshipService } from "../../../../../shared/services/relationships/relationshipService";
import { Person } from "../../../../../shared/models/person.model";
import { LocationContact } from "../../../../../shared/models/locationContact.model";
import useAppStore from "../../../../../appStore";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { RelationshipContact } from "../../../../../shared/models/relationshipContact.model";

const EditContact = ({ contactToEdit, open, onClose, onSave }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const [id, setId] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [level, setLevel] = useState(1);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  useEffect(() => {
    if (contactToEdit) {
      setId(contactToEdit?.id || 0)
      setFirstName(contactToEdit?.person?.first_name || '');
      setLastName(contactToEdit?.person?.last_name || '');
      setCompanyName(contactToEdit?.company_name || '');
      setEmail(contactToEdit?.person?.email || '');
      setPhoneNumber(contactToEdit?.person?.phone_number || '');
      setLevel(contactToEdit?.relationship_level || 1)
    }
  }, [contactToEdit]);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    const isValidPhoneNumber = value.replace(/\D/g, '').length === 10;
    setPhoneNumberError(!isValidPhoneNumber);
  };

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setLevel(newOption);
    }
  };

  const handleSubmit = async () => {
    if (phoneNumberError) {
      return;
    }

    try {
      let contact = new RelationshipContact();
      let person = new Person();

      person.first_name = firstName;
      person.last_name = lastName;
      person.phone_number = phoneNumber;
      person.email = email;
      contact.id = id;
      contact.person = person;
      contact.company_name = companyName;
      contact.organization = user?.organization;
      contact.relationship_level = level;

      const service = new RelationshipService();
      const response = id === 0
        ? await service.createSalesContact(contact)
        : await service.updateSalesContact(contact);
      onSave(response);
    } catch (error) {
      console.error('Failed to create contact:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Typography sx={{ flex: 1, fontSize: '0.9rem', color: '#777', ml: '1px' }} component="div">
          Relationship Level
        </Typography>
        <ToggleButtonGroup
          value={level}
          exclusive
          onChange={handleOptionChange}
          aria-label="stepSelection"
          sx={{marginBottom: '10px'}}
        >
          <ToggleButton value={1}>Low</ToggleButton>
          <ToggleButton value={2}>Medium</ToggleButton>
          <ToggleButton value={3}>High</ToggleButton>
        </ToggleButtonGroup>
        <TextField
          margin="dense"
          name="first_name"
          label="First Name"
          type="text"
          fullWidth
          variant="outlined"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          margin="dense"
          name="last_name"
          label="Last Name"
          type="text"
          fullWidth
          variant="outlined"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          margin="dense"
          name="company_name"
          label="Company Name"
          type="text"
          fullWidth
          variant="outlined"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
        <InputMask
          mask="(999) 999-9999"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        >
          {(inputProps) => (
            <TextField
              {...inputProps}
              margin="dense"
              name="phone_number"
              label="Phone Number"
              type="tel"
              fullWidth
              variant="outlined"
              error={phoneNumberError}
              helperText={phoneNumberError ? "Invalid phone number" : ""}
            />
          )}
        </InputMask>
        <TextField
          margin="dense"
          name="email"
          label="Email Address"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          {id === 0 ? 'Submit' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditContact;

