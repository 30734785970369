import React from "react";
import useInspectionsStore from "../../../../inspectionsStore";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import InspectionSpaces from "./components/InspectionSpaces";
import { Button, Box, Typography, Chip, useMediaQuery, useTheme } from "@mui/material";
import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import { format } from "date-fns";
import { getPacificTime } from "../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import { useTranslation } from "react-i18next";
import useAppStore from "../../../../../../appStore";

const InspectionCard = ({ inspection }) => {
  const { handleEditItem } = useInspectionsStore();
  const {whiteLabelProps} = useAppStore();
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const formattedDate = format(new Date(inspection.inspected_date), 'MMM d, yyyy');
  const isBaseline = inspection.is_baseline;
  const isJoint = inspection.is_joint_inspection;
  const { t } = useTranslation();

  const handleViewInspectionClicked = () => {
    if (handleEditItem === null) {
      return;
    }
    handleEditItem(inspection);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: '15px',
        marginBottom: '15px',
        boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
        position: 'relative'
      }}
    >
      {/* Date + Baseline/Joint Container */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: 'flex-start'
        }}
      >
        {/* Date Box */}
        <Box
          sx={{
            backgroundColor: '#f1f1f1',
            color: 'black',
            padding: '8px 16px',
            borderRadius: '0 0 4px 0'
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#555' }}>
            {formattedDate}
          </Typography>
        </Box>

        {/* Baseline/Joint Box (shows only if isBaseline or isJoint) */}
        {(isBaseline || isJoint) && (
          <Box
            sx={{
              backgroundColor: whiteLabelProps.primary_color,
              color: 'white',
              padding: '8px 16px',
              borderRadius: '0 0 4px 4px',
              marginLeft: '8px'
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#fff' }}>
              {isBaseline ? 'Baseline' : 'Joint'}
            </Typography>
          </Box>
        )}
      </Box>

      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '10px',
            alignItems: 'center'
          }}
        >
          {/* Left Column */}
          <Box sx={{ width: '65%', paddingTop: '10px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {!isMobile &&
                <img
                  src={toAbsoluteUrl("/white-labeling/verde/media/menu-icons/inspectorProfile.png")}
                  style={{ width: "65px", height: "65px", marginRight: "10px" }}
                  alt="Inspector"
                />
              }
              <Box>
                <Typography variant="h6" sx={{ fontWeight: "bold", color: "#555", fontSize: isMobile ? '1.1rem' : '1.2rem' }}>
                  {inspection.inspected_by.first_name} {inspection.inspected_by.last_name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {t('inspected')} {getPacificTime(inspection.inspected_date)}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Right Column */}
          <Box sx={{ width: '150px', textAlign: 'right', marginTop: '5px' }}>
            <Button
              variant="contained"
              style={{ backgroundColor: '#F4F4F4', color: 'gray', maxHeight: '30px', boxShadow: 'none', width: '150px' }}
              onClick={(e) => {
                e.preventDefault();
                handleViewInspectionClicked();
              }}
            >
              <i className="fas fa-eye fs-5 me-2"></i>
              {t('view-details')}
            </Button>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '150px', justifyContent: 'end' }}>
              <div className="badge py-3 fs-7 badge-light-success justify-content-center mt-2">
                {`${t('average')}: `}{inspection.score}
              </div>
              {!isMobile &&
                <div className="badge py-3 fs-7 badge-light-primary justify-content-center mt-2">
                  {t("spaces")}: {inspection.inspected_entities_count}
                </div>
              }
            </Box>
          </Box>
        </Box>
        <InspectionSpaces inspection={inspection} />
      </CardContent>
    </Card>
  );

};

export default InspectionCard;
