import React, {useState} from 'react'
import ProjectsList from "../../project-list/ProjectsList";
import { useSnapshot } from "valtio/index";
import { projectStore } from "../../../projectsStore";


const ProjectsCloseTab = () => {
  const snap = useSnapshot(projectStore);
  const { selectedProject, open, isEditing } = snap;

  const handleNewProject = () => {
    projectStore.selectedProject = null;
    projectStore.isEditing = true;
  };

  const handleCloseProject = (project) => {
    projectStore.selectedProject = project;
    projectStore.isEditing = true;
    projectStore.open = true;
  };

  const handlerDeleteItem = async (itemToDelete) => {
    // implement this as needed
  };

  const handlerItemEditorAction = (action) => {
    projectStore.isEditing = false;
    projectStore.open = false;
  };

  return (
    <>
        <ProjectsList
          onEdit={handleCloseProject}
          onDelete={handlerDeleteItem}
        />
      {/*{open && (*/}
      {/*  <CloseProject*/}
      {/*    open={open}*/}
      {/*    onActionClicked={handlerItemEditorAction}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );

}

export default ProjectsCloseTab
