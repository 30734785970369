import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { EmployeeSerializer } from "../../../models/serializers/employeeSerializer";


export class CommandSearchEmployees {

  EMPLOYEE_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.EMPLOYEE_SERVICE}/employees/`;
  }

  async run(provider_id, search, location_id: number | undefined = undefined) {

    const url = this.getUrl()

    const params = {
      params: {
        provider: provider_id,
        search: search,
        location: location_id
      }
    }

    const tracer = useTracer.getState()
    tracer.trace('Employees', 'Api', `GET ${url}`)

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Employees', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('Employees', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new EmployeeSerializer()
    const employees = serializer.deserializeList(data)

    return employees
  }

}

