import React, { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { fetchProjects, projectStore } from "../../projectsStore";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import ProjectsTable from "./table/ProjectsTable";


const ProjectsList = (props) => {
    const { onEdit, onDelete } = props;
    const snap = useSnapshot(projectStore);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const projects = await fetchProjects();
                projectStore.projects = projects; // Directly set projects in store
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    if (isLoading) {
        return <CustomLoadingDots />;
    }
    if (error) {
        return <CustomError error={error} />;
    }

    return (
      <>
          {/* You might pass onEdit, onDelete to ProjectsPlanTable if needed */}
          <ProjectsTable
            projects={snap.projects}
            onEdit={onEdit}
            onDelete={onDelete}
          />
      </>
    );
};

export default ProjectsList;
