import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SubHeader from "../headers/SubHeader";
import Typography from "@mui/material/Typography";
import React from "react";

const GenericValueCard = ({ title, value, center=false }) => (
  <Card variant="outlined" sx={{ height: '120px' }}>
    <CardContent
      sx={ center ? {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: center ? 'center' : 'start',
        alignItems: center ? 'center' : 'left',
        height: '100%',
        padding: '16px',
        '&:last-child': { paddingBottom: '16px' }
      } : {}}
    >
      <SubHeader title={title} showAction={false} />
      <Typography variant="h6" sx={{ textAlign: center ? 'center' : '' }}>{value}</Typography>
    </CardContent>
  </Card>
);

export default GenericValueCard;
