import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import ActionButton from "../../../../../../../shared/components/buttons/ActionButton";
import MediaRowSquares from "../../../../../../../shared/components/media/media-viewers/photos/MediaRowSquares";
import { useTranslation } from "react-i18next";

const InspectedEntityCard = (props) => {
  const { inspected_entity, onEdit, onDelete, isEditing } = props;
  const {t} = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [openPopup, setOpenPopup] = useState(false);

  console.log(inspected_entity);

  const failedServiceItems = inspected_entity.service_item_scores.filter(
    (item) => item.score === 1
  );
  const failCount = failedServiceItems.length;

  const handleEditClicked = () => {
    onEdit(inspected_entity);
  };

  const handleFailedItemsClick = () => {
    setOpenPopup(true);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: "15px",
        marginBottom: "15px",
        boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
        position: "relative",
        breakInside: "avoid",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "#f9f9f9",
          color: "black",
          padding: "8px 8px",
          borderRadius: "0 0 6px 0",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          borderBottom: "1px solid #e8e8e8",
        }}
      >
        <div
          className="badge py-3 badge-light-success"
          style={{ width: "95px", fontSize: "1.2rem" }}
        >
          {t('score-alt')}: {inspected_entity.score}
        </div>
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          padding: "8px 8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {isEditing && (
          <div className="d-flex flex-row align-items-center">
            <ActionButton iconType="pencil" onClick={handleEditClicked} />
            <ActionButton
              iconType="trash"
              onClick={(e) => {
                onDelete(inspected_entity);
              }}
            />
          </div>
        )}
      </Box>

      <CardContent
        sx={{
          padding: 0,
          "&:last-child": {
            paddingBottom: 0,
            display: "flex",
            flexDirection: "row",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            borderRight: "1px solid #e8e8e8",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingTop: "50px",
            }}
          >
            {/* Left Column */}
            <Box sx={{ width: "100%", padding: "16px" }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  color: "#555",
                  fontSize: "1.2rem",
                  marginRight: "3px",
                }}
              >
                {inspected_entity.getRootSpace().name +
                  " / " +
                  inspected_entity.getChildSpacePath()}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ fontSize: "1.2rem", marginTop: "2px" }}
              >
                {inspected_entity.entity_type.name}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", justifyContent: "start" }}
            >
              <MediaRowSquares
                itemMedia={inspected_entity.media}
                size={isMobile ? 45 : 100}
              />
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingTop: "50px",
            paddingLeft: "16px",
          }}
        >
          {/* Right Column */}
          <Typography
            variant="h4"
            color="primary"
            sx={{
              fontSize: "1.1rem",
              marginBottom: "8px",
              paddingTop: "16px",
              fontWeight: "bold",
              fontStyle: "italic",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleFailedItemsClick}
          >
            {`${failCount} ${t('failed-service-items')}`}
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              padding: "12px",
              marginRight: "100px",
              borderRadius: "4px",
              boxSizing: "border-box",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "#555",
                fontSize: "1.1rem",
                wordWrap: "break-word",
                lineHeight: 1.5,
              }}
            >
              {inspected_entity.comments && inspected_entity.comments.trim() !== ""
                ? inspected_entity.comments
                : t('no-notes-given')}
            </Typography>
          </Box>
        </Box>
      </CardContent>

      {/* Popup Dialog */}
      <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
        <DialogTitle>{t('failed-service-items')}</DialogTitle>
        <DialogContent>
          {failCount > 0 ? (
            <ul>
              {failedServiceItems.map((item, index) => (
                <li key={index}>{item.service_item.entity_type.name}</li>
              ))}
            </ul>
          ) : (
            <Typography>N/A</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPopup(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default InspectedEntityCard;
