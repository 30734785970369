import React, { useState } from "react";
import useInspectionsStore from "../../../../inspectionsStore";
import { Card, Typography } from "@mui/material";
import CustomDatePicker from "../../../../../../shared/components/forms/date-time/date-picker/CustomDatePicker";
import { formatDateTime } from "../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import { useTranslation } from "react-i18next";

const InspectionDatePicker = ({ isEditing }) => {

  const {
    inspection,
    setInspection
  } = useInspectionsStore();

  const { t } = useTranslation();


  const handleDateSelected = (newValue) => {
      inspection.inspected_date = newValue
      setInspection(inspection)
  };

  if (isEditing) {
    return (
      <Card variant="outlined" sx={{marginTop: '15px', boxShadow: '0 1px 2px rgba(0,0,0,0.1)'}}>
        <div className="card-header p-8">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-800">{t('inspection-date')}</span>
          </h3>
        </div>
        <div className="card-body p-7 pt-4">
          <div className="mb-10">
            <CustomDatePicker value={inspection.inspected_date} onChange={handleDateSelected} quickPick={false} />
          </div>
        </div>
      </Card>
    );
  } else {
    return (
      <Card variant="outlined" sx={{marginTop: '15px', boxShadow: '0 1px 2px rgba(0,0,0,0.1)'}}>
        <div className="card-header p-8">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-800">{t('inspection-date')}</span>
          </h3>
        </div>
        <div className="card-body p-7 pt-1 mb-7">
          <Typography>{formatDateTime(inspection.inspected_date)}</Typography>
        </div>
      </Card>
    );
  }
};

export default InspectionDatePicker;