import React, { useEffect, useState } from 'react';
import useAppStore from '../../../../../../appStore';
import InspectionDualBarChart from "./components/InspectionDualBarChart";
import { fetchScoresByType } from "../../../../../../shared/utils/data-fetch/analyticDataFetchHelper";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { useTranslation } from "react-i18next";

type ChartHeaderRow = [string, string, string, string];
type ChartDataRow = [string, number, number, number];
type ChartDataState = Array<ChartHeaderRow | ChartDataRow>;

const ScoresBySpaceType = ({ location_id, sortOrder, startDate=null, endDate=null }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const {t} = useTranslation();
  const { whiteLabelProps, selectedLocation } = useAppStore();
  const [chartData, setChartData] = useState<ChartDataState>([['Space Type', 'Average', 'Joint Average', 'ID']]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setIsLoading(true);
    setError('');
    fetchScoresByType(user.organization.id, location_id, startDate, endDate)
      .then(data => {
        setChartData([...data]);
      })
      .catch(error => {
        setError(error instanceof Error ? error.message : String(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user.organization.id, location_id, startDate, endDate]);

  if (isLoading) {
    return <CustomLoadingDots />
  }

  return (
    <InspectionDualBarChart
      chartData={chartData}
      chartTitle={t('space-type-scores')}
      primaryColor={whiteLabelProps.primary_color}
      secondaryColor={location_id === 0 ? whiteLabelProps.primary_color_light : undefined}
      max={5}
      sortOrder={sortOrder}
      type={"space_type"}
      location_id={location_id}
  />
  );
};

export default ScoresBySpaceType;

