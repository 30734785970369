// serializers/errorSerializer.ts
import { BaseSerializer } from "./baseSerializer";
import { SageError } from "../sageError.model";

export class ErrorSerializer extends BaseSerializer {
  public deserialize(error: any): SageError {
    let status = 500;
    let message = "An unknown error occurred";

    if (error.response) {
      status = error.response.status;

      if (typeof error.response.data === "string") {
        // Extract the message from the traceback if it exists
        const match = error.response.data.match(/ValueError: (.+?)\n/);
        if (match && match[1]) {
          message = match[1];
        }
      } else if (error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }

    return new SageError(status, message);
  }
}
