import { toAbsoluteUrl } from "../../../_metronic/helpers";

export class AppImages {

    logoWhite: string;
    mascotBanner: string;
    iconVirtualAssistant: string;
    iconPdf: string;
    iconPdfLarge: string;
    iconLaunchMenu: string;
    iconMenuExit: string;
    iconAdmin: string;
    iconGoBack: string;
    iconCases: string;
    iconHeart: string;
    iconHome: string;
    iconInspections: string;
    iconInspectionAlt: string;
    iconInspectionAlt2: string;
    iconOrgChart: string;
    iconSafety: string;
    iconAnalytics: string;
    iconWorkItems: string;
    iconRating: string;
    iconPassed: string;
    iconFailed: string;
    iconGreyedRating: string;
    iconHalvedRating: string;
    iconWorkDone: string;
    iconPencilPaper: string;
    iconSds: string;
    iconEquipment: string;
    iconContacts: string;
    iconSlipsTrips: string;
    iconHuddle: string;
    iconSent: string;
    iconReceived: string;
    iconAvgScore: string;
    iconService: string;
    iconTraining: string;
    iconMenuDropdown: string;
    iconSummary: string;
    iconRelationships: string;
    iconProfileEmpty: string;
    iconNoData: string;
    iconNoPdf: string;
    iconAlert: string;
    iconGenericCase: string;
    iconHrCase: string;
    iconSafetyCase: string;
    iconMarketingCase: string;
    iconItemCancelled: string;
    iconHospital: string;
    iconType: string;
    iconBlankProfilePic: string;
    workItemRow: string;
    iconEmployeeTraining: string;
    iconShadow: string;
    sageLoader: string;



    constructor() {

        this.logoWhite = toAbsoluteUrl('/white-labeling/verde/media/logos/verde_logo_white.png');
        this.mascotBanner = toAbsoluteUrl('/white-labeling/verde/media/logos/sage_v_sign.png');
        this.iconVirtualAssistant =  toAbsoluteUrl('/white-labeling/verde/media/menu-icons/virtual-assistant.png');
        this.iconPdf = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/pdf-icon.png');
        this.iconPdfLarge = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/pdf-icon-large.png')
        this.iconLaunchMenu = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/fab-menu.png');
        this.iconGoBack = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-back.png');
        this.iconMenuExit = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/fab-exit.png');
        this.iconAdmin = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-admin.png');
        this.iconCases = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-cases.png');
        this.iconHeart = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-heart.png');
        this.iconHome = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-home.png');
        this.iconInspections = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-inspections-2.png');
        this.iconOrgChart = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-org-chart.png');
        this.iconSafety = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/safety-alt.png');
        this.iconAnalytics = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-analytics.png');
        this.iconWorkItems = toAbsoluteUrl('/white-labeling/verde/media/logos/sage_safety.png');
        this.iconSds = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/sds.png');
        this.iconWorkDone = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/work-done.png');
        this.iconPencilPaper = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/pencilpaper.png');
        this.iconRating = toAbsoluteUrl('/white-labeling/verde/media/images/inspections/inspection-rating.png');
        this.iconGreyedRating = toAbsoluteUrl('/white-labeling/verde/media/images/inspections/inspection-rating-empty.png');
        this.iconFailed = toAbsoluteUrl('/white-labeling/verde/media/logos/thumbsdown.png');
        this.iconPassed = toAbsoluteUrl('/white-labeling/verde/media/logos/thumbsup.png');
        this.iconHalvedRating = toAbsoluteUrl('/white-labeling/verde/media/images/inspections/inspection-rating-half.png');
        this.iconEquipment = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/hardhat.png');
        this.iconContacts = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/contactsIcon.png');
        this.iconSlipsTrips = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/slipsIcon.png');
        this.iconHuddle = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/huddle.png');
        this.iconSent = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/sent-icon.png');
        this.iconReceived = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/recieved-icon.png');
        this.iconInspectionAlt = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/inspection-alt.png');
        this.iconAvgScore = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/avg.png');
        this.iconService = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/mopIcon.png');
        this.iconTraining = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/trainingIcon.png');
        this.iconMenuDropdown = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/dropdownIcon.png');
        this.iconSummary = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/summaryIcon.png');
        this.iconRelationships = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/opportunitiesIcon.png');
        this.iconProfileEmpty = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/profileFiller.png');
        this.iconInspectionAlt2 = toAbsoluteUrl('/white-labeling/verde/media/logos/magnifyingMenuIcon.png');
        this.iconNoData = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/noDataIcon.png');
        this.iconNoPdf = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/no-pdf-icon.png');
        this.iconAlert = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/priorityIcon.png');
        this.iconGenericCase = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/caseRowGeneric.png');
        this.iconHrCase = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/caseRowHR.png');
        this.iconMarketingCase = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/caseRowMarketing.png');
        this.iconItemCancelled = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/cancelledIcon.png');
        this.iconSafetyCase = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/caseRowSafety.png');
        this.iconHospital = toAbsoluteUrl('/white-labeling/verde/media/logos/concentra.png');
        this.iconType = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/type-icon.png');
        this.iconBlankProfilePic = toAbsoluteUrl('/media/avatars/blank.png');
        this.workItemRow = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/workItemRow.png');
        this.iconEmployeeTraining = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/trainingIconCap.png');
        this.iconShadow = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/shadow.png');
        this.sageLoader = toAbsoluteUrl('/white-labeling/verde/media/logos/sageLoader.gif');
    }


}

