export const dummyForms = [
  {
    id: 1,
    name: "Supervisor Incident Intake Form",
    description: "Document supervisor-reported incidents",
    form: {
      id: 1,
      name: "Supervisor Incident Intake Form",
      description: "Supervisor Incident Intake Form",
      sections: [
        {
          id: 1,
          name: "Safety Form",
          sage_prompt: "Please fill out this intake form",
          fields: [
            {
              id: 1,
              name: "Nature of Incident",
              type: "Select",
              data_source: "injury_illness_type",
            },
            {
              id: 2,
              name: "Location of Incident",
              type: "Text",
              data_source: null,
            },
          ],
        },
      ],
    },
    caseType: "Incident Report",
    fieldCount: 2,
  },
  {
    id: 2,
    name: "Employee Feedback Form",
    description: "Gather feedback from employees",
    form: {
      id: 2,
      name: "Employee Feedback Form",
      description: "Collect feedback and suggestions from employees.",
      sections: [
        {
          id: 2,
          name: "Feedback Details",
          sage_prompt: "Provide your feedback in the fields below.",
          fields: [
            {
              id: 3,
              name: "Feedback Topic",
              type: "Text",
              data_source: null,
            },
            {
              id: 4,
              name: "Feedback Description",
              type: "Textarea",
              data_source: null,
            },
          ],
        },
      ],
    },
    caseType: "Feedback",
    fieldCount: 2,
  },
  {
    id: 3,
    name: "Visitor Log Form",
    description: "Log details of visitors",
    form: {
      id: 3,
      name: "Visitor Log Form",
      description: "Document visitor entries for safety and compliance.",
      sections: [
        {
          id: 3,
          name: "Visitor Details",
          sage_prompt: "Please provide visitor details below.",
          fields: [
            {
              id: 5,
              name: "Visitor Name",
              type: "Text",
              data_source: null,
            },
            {
              id: 6,
              name: "Purpose of Visit",
              type: "Select",
              data_source: "visit_purpose",
            },
            {
              id: 7,
              name: "Entry Time",
              type: "Time",
              data_source: null,
            },
          ],
        },
      ],
    },
    caseType: "Visitor Management",
    fieldCount: 3,
  },
];
