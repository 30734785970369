import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import classNames from 'classnames';
import { findTreeNode } from "../../../../../../../shared/components/forms/widgets/searchable-tree-view/utils/findTreeNode";
import useServiceStore from "../../../../../serviceStore";
import { TreeItem, TreeItemClasses } from '@mui/x-tree-view/TreeItem';
import { TreeView } from '@mui/x-tree-view/TreeView';

const ServiceCatalogTree = (props) => {
  const {
    data = [],
    onItemSelected,
    onRenderLabel = null,
    classes = ''
  } = props;

  const treeItemClasses: TreeItemClasses = {
    root: '',
    group: '',
    content: '',
    expanded: '',
    selected: '',
    focused: '',
    disabled: '',
    iconContainer: '',
    label: 'fs-5'
  }

  const { selectedService, setSelectedService, setSelectedServiceItem } = useServiceStore();
  const [selectedItem, setSelectedItem] = React.useState<any>(null);
  const [expandedNodes, setExpandedNodes] = React.useState([]);
  const [serviceData, setServiceData] = React.useState(data);

  const handleServiceSelected = (event, nodeId) => {
    if (event.target.closest('.MuiTreeItem-iconContainer')) {
      return;
    }
    let selectedItem = null;
    for (let item of serviceData) {
      selectedItem = findTreeNode(item, Number(nodeId));
      if (selectedItem !== null) {
        setSelectedItem(selectedItem);
        setSelectedServiceItem(null);
        onItemSelected(selectedItem);
        return;
      }
    }
  };

  const renderLabel = (labelName) => {
    if (onRenderLabel !== null) {
      return onRenderLabel(labelName);
    }

    return (
      <div className='d-flex justify-content-between align-items-center'>
        <span className="fs-4">{labelName}</span>
      </div>
    );
  };

  const handleToggle = (event, nodeIds) => {
    setExpandedNodes(nodeIds);
  };

  const handleDeselect = () => {
    setSelectedItem(null);
    setSelectedService(null);
    setSelectedServiceItem(null);
    setExpandedNodes([]);
  };

  const renderTree = (node, type) => {
    if (node.matched !== undefined && !node.matched) {
      return null;
    }

    return (
      <TreeItem
        key={node.id}
        nodeId={`${node.id.toString()}`}
        label={renderLabel(node.name)}
        classes={treeItemClasses}
        sx={{
          '& .MuiTreeItem-content': {
            paddingTop: '8px',
            paddingBottom: '8px',
          },
          '& .MuiTreeItem-label': {
            fontSize: '1.2rem',
          },
          '& .MuiTreeItem-iconContainer svg': {
            fontSize: '1.5rem',
          },
        }}
      >
        {Array.isArray(node.children)
          ? node.children.map((childNode) => renderTree(childNode, type))
          : null}
      </TreeItem>
    );
  };

  return (
    <>
      <div className={classNames("", classes)}>
        <TreeView
          aria-label="file system navigator"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeSelect={handleServiceSelected}
          onNodeToggle={handleToggle}
          expanded={expandedNodes}
          sx={{ width: '100%', height: '100%' }}
        >
          {serviceData.map((item) => renderTree(item, item.name))}
        </TreeView>
      </div>
    </>
  );
};

export default ServiceCatalogTree;


