import React, {useState} from 'react'
import ProjectsList from "../../project-list/ProjectsList";
import { projectStore } from "../../../projectsStore";
import { useSnapshot } from "valtio/index";
import RankProject from "./components/RankProject";
import ImpactDifficultyChart from "./components/ImpactDifficultyChart";

const ProjectsRankTab = () => {

    const snap = useSnapshot(projectStore);
    const { selectedProject, open, isEditing } = snap;

    const handleRankProject = (project) => {
        projectStore.selectedProject = project;
        projectStore.open = true;
    };

    const handlerDeleteItem = async (itemToDelete) => {
        // implement this as needed
    };

    const handlerItemEditorAction = (action) => {
        projectStore.open = false;
    };

    return (
      <>
          <ImpactDifficultyChart />
            <ProjectsList
              onEdit={handleRankProject}
              onDelete={handlerDeleteItem}
            />
          {open && (
            <RankProject
              open={open}
              onActionClicked={handlerItemEditorAction}
            />
          )}
      </>
    );

}

export default ProjectsRankTab
