import React, { useEffect, useState } from "react";
import { Avatar, Button, Chip } from "@mui/material";
import classNames from "classnames";
import { SupplyService } from "../../../../../../../../../shared/services/supplies/supplyService";
import useAppStore from "../../../../../../../../../appStore";
import { Supply } from "../../../../../../../../../shared/models/supply.model";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import SuppliesListDialog from "./SupplyListDialog";

const suppliesService = new SupplyService();

const SuppliesCard = ({ classes = '', isEditing = false }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { masterRefresh } = useAppStore();
  const [supplies, setSupplies] = useState<Supply[]>([]);
  const [itemSupplies, setItemSupplies] = useState<Supply[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (isEditing) {
      const fetchSupplies = async () => {
        try {
          const supplyData = await suppliesService.getSupplies(user.organization?.id);
          setSupplies(supplyData);
        } catch (error) {
          console.error('Error fetching supplies:', error);
        }
      };

      fetchSupplies().then();
    }
  }, [isEditing, masterRefresh]);

  const handleSelectSupply = (selectedSupply: Supply) => {
    setItemSupplies([...itemSupplies, selectedSupply]);
    setIsDialogOpen(false);
  };

  return (
    <>
      <div className={classNames("flex-column", classes)}>
        <div className="flex-row-fluid">
          <div className="d-flex flex-row align-items-center justify-content-between" style={{ paddingLeft: 0 }}>
            <h3 className="card-title align-items-center flex-row">
              <span className="card-label fw-bold text-gray-600">Supplies</span>
            </h3>
            {isEditing && (
              <Button variant="contained" color="primary" onClick={() => setIsDialogOpen(true)} sx={{ marginRight: '10px' }}>
                {"+ SUPPLY"}
              </Button>
            )}
          </div>
        </div>

        <div className="table-responsive flex-row-fluid mt-5">
          <table className="table table-row-solid align-middle gs-0 gy-3 my-0">
            <tbody>
            <Chip avatar={<Avatar><HomeRepairServiceIcon fontSize={"small"} /></Avatar>} label="Example" sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }} />
            <Chip avatar={<Avatar><HomeRepairServiceIcon fontSize={"small"} /></Avatar>} label="Example" sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }} />
            <Chip avatar={<Avatar><HomeRepairServiceIcon fontSize={"small"} /></Avatar>} label="Example" sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }} />
            {itemSupplies.map(supply => (
              <Chip
                avatar={<Avatar><HomeRepairServiceIcon fontSize={"small"} /></Avatar>}
                key={supply?.id}
                label={supply?.name}
                sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
              />
            ))}
            </tbody>
          </table>
        </div>
      </div>
      {isDialogOpen && (
        <SuppliesListDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          supplies={supplies}
          onSelect={handleSelectSupply}
        />
      )}
    </>
  );
};

export default SuppliesCard;