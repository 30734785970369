import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { EmployeeSerializer } from "../../../models/serializers/employeeSerializer";
import { ErrorSerializer } from "../../../models/serializers/errorSerializer";

export class CommandCreateEmployee {
    EMPLOYEES_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {
        return `${this.EMPLOYEES_SERVICE}/employees/`;
    }

    async run(employee, organization_id, location_id) {
        const url = this.getUrl();

        const person = employee.person
          ? {
              first_name: employee.person.first_name,
              last_name: employee.person.last_name,
              photo_url: employee.person.photo_url,
              phone_number: employee.person.phone_number,
              email: employee.person.email,
          }
          : {};

        const worksAt = Array.isArray(employee.works_at)
          ? employee.works_at.map((work) => ({
              location: { id: location_id },
              position: work.position ? { id: work.position.id } : {},
          }))
          : [];

        const user = employee.user
          ? {
              role: employee.user.role,
              country_code: employee.user.country_code,
              is_active: employee.user.is_active,
              permission_profiles: employee.user.permission_profiles,
          }
          : {};

        const payload = {
            organization: { id: organization_id },
            person: person,
            country_code: employee.country_code,
            phone_number: employee.phone_number,
            works_at: worksAt,
            user: user,
        };

        const tracer = useTracer.getState();
        tracer.trace("Employees", "Api", `POST ${url}`);

        try {
            const response = await httpClient.post(url, payload);
            tracer.trace("Employees", "Api", "Success");
            return this.deserialize(response.data);
        } catch (error) {
            const errorSerializer = new ErrorSerializer();
            const serializedError = errorSerializer.deserialize(error);
            tracer.trace("Employees", "Error", `${serializedError.message}`);
            console.error("Error:", serializedError);
            throw serializedError;
        }
    }

    deserialize(data) {
        const serializer = new EmployeeSerializer();
        return serializer.deserialize(data);
    }
}
