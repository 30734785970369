import React, { useState } from 'react';
import { Divider, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import useAppStore from "../../appStore";
import LocationSettings from "./components/settings/LocationSettings";
import LocationSpaceTypes from "./components/space-types/LocationSpaceTypes";
import LocationBuildings from "./components/spaces/LocationBuildings";
import LocationContracts from "../location-summary/components/contracts/LocationContracts";
import LocationContacts from "./components/poc/LocationContacts";
import FormBuilder from "./components/form-builder/FormBuilder";

interface TabItem {
  label: string;
  value: number;
  component: JSX.Element;
}

const LocationProfilePage: React.FC = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { whiteLabelProps } = useAppStore();
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  const availableTabs: TabItem[] = [];

  if (user.permissions.hasAccess("Location Space Types Admin")) {
    availableTabs.push({ label: "Space Types", value: 1, component: <LocationSpaceTypes /> });
  }
  if (user.permissions.hasAccess("Location Buildings Admin")) {
    availableTabs.push({ label: "Buildings", value: 2, component: <LocationBuildings /> });
  }
  if (user.permissions.hasAccess("Location Contracts Admin")) {
    availableTabs.push({ label: "Contracts", value: 3, component: <LocationContracts /> });
  }
  if (user.permissions.hasAccess("Location POC Admin")) {
    availableTabs.push({ label: "POC Admin", value: 4, component: <LocationContacts /> });
  }
  if (user.permissions.hasAccess("Form Builder")) {
    availableTabs.push({ label: "Form Builder", value: 5, component: <FormBuilder /> });
  }
  if (user.permissions.hasAccess("Location Settings Admin")) {
    availableTabs.push({ label: "Settings", value: 6, component: <LocationSettings /> });
  }

  // Initialize 'tab' state with the value of the first available tab
  const [tab, setTab] = useState<number | null>(
    availableTabs.length > 0 ? availableTabs[0].value : null
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  if (isMobile) {
    return (
      <div
        style={{
          flex: 1,
          textAlign: "center",
          padding: "20px",
          marginTop: "100px",
        }}
      >
        <p
          style={{
            fontSize: "21px",
            fontWeight: "semibold",
            marginBottom: "0px",
            color: whiteLabelProps.primary_color,
          }}
        >
          Page Unavailable
        </p>
        <p style={{ fontSize: "14px", color: "gray" }}>
          Location Profile is currently only available on larger screens. Try
          viewing on desktop.
        </p>
      </div>
    );
  }

  return (
    <>
      <Tabs value={tab} onChange={handleTabChange}>
        {availableTabs.map((tabItem) => (
          <Tab key={tabItem.value} label={tabItem.label} value={tabItem.value} />
        ))}
      </Tabs>
      <Divider sx={{ bgcolor: "#999", marginBottom: "15px" }} />
      {availableTabs.map(
        (tabItem) => tab === tabItem.value && tabItem.component
      )}
    </>
  );
};

export default LocationProfilePage;


