import { proxy } from 'valtio';

export const formState = proxy({
  formToEdit: null,
  open: false,
  error: '',
  isLoading: false,
});

// Actions to update the store
export const formActions = {
  setFormToEdit(form) {
    formState.formToEdit = form;
  },
  setOpen(val) {
    formState.open = val;
  },
  setError(err) {
    formState.error = err;
  },
  setIsLoading(val) {
    formState.isLoading = val;
  },
};