import React, { useState } from "react";
import ActionButton from "../../../../../../../shared/components/buttons/ActionButton";
import { ValidationDialog } from "../../../../../../../shared/components/page/popup-dialog/ValidationDialog";
import { Box } from "@mui/material";
import useAppStore from "../../../../../../../appStore";
import { ConfirmationDialog } from "../../../../../../../shared/components/page/popup-dialog/ConfirmationDialog";
import { ContactService } from "../../../../../../../shared/services/location-contacts/contactService";

const ContactRow = (props) => {

  const {
    item,
    onEdit,
    onDelete,
    children,
    value,
    index,
    canEdit,
    isMobileView,
    ...other } = props;

  const {whiteLabelProps, toggleMasterRefresh} = useAppStore();
  const [deleting, setDeleting] = useState(false);
  const service = new ContactService()

  const handleDeleteContact = async () => {
    try {
      await service.deleteContact(item.id);
      setDeleting(false);
      toggleMasterRefresh();
    } catch (error) {
      console.error(error);
    }
  }

  const handleDeleteClicked = () => {
    setDeleting(true);
  }

  return (
    <>
      <tr>
        <td>
          <div className="d-flex align-items-center">
            <Box sx={{
              width: '40px',
              height: '40px',
              backgroundColor: whiteLabelProps.primary_color,
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'bold',
              fontSize: '1.2rem',
              borderRadius: '4px',
              marginRight: '10px',
              padding: '1px',
            }}>
              {`${item?.employee?.person.first_name[0]}${item?.employee?.person.last_name[0]}`}
            </Box>
            <div className="d-flex justify-content-start flex-column">
              <a className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                {item.employee?.person.first_name} {item.employee?.person.last_name}
              </a>
              <span className="text-gray-400 fw-semibold d-block fs-7">{item.location.name || "--"} {item.title}</span>
            </div>
          </div>
        </td>

        <td>
          <div className="d-flex align-items-center">
            <div className="d-flex justify-content-start flex-column">
              <a className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                {item.employee?.person.email}
              </a>
              <span className="text-gray-400 fw-semibold d-block fs-7">{item.employee?.person.phone_number}</span>
            </div>
          </div>
        </td>

        <td>
          <a className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
            {item?.csat_reporting_group_entry?.name || '--'}
          </a>
        </td>

        {canEdit && (
          <td>
            <div className="d-flex justify-content-end">
              <ActionButton iconType="view" onClick={(e) => {
                e.preventDefault();
                onEdit(item);
              }} />
              <ActionButton iconType="trash" onClick={(e) => {
                e.preventDefault();
                handleDeleteClicked();
              }} />
            </div>
          </td>
        )}

      </tr>
      {deleting && (
        <ConfirmationDialog
          isOpen={deleting}
          setIsOpen={setDeleting}
          message="Delete Contact?"
          description={`Are you sure you want to delete this contact? This action cannot be undone.`}
          onConfirmClicked={handleDeleteContact}
        />
      )}
    </>
  )
}

export default ContactRow

