import React, { useEffect, useState } from "react";
import TraceRow from "./TraceRow";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { TextFieldButton } from "../../../shared/components/buttons/TextFieldButton";
import { Trace } from "../../../shared/models/trace.model";
import { TraceService } from "../../../shared/services/trace-thread/traceService";
import useAppStore from "../../../appStore";
import CustomLoadingDots from "../../../shared/components/page/popup-dialog/CustomLoadingDots";
import TableHeader from "../../../shared/components/page/headers/TableHeader";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import LoadMoreButton from "../../../shared/components/buttons/LoadMoreButton";
import { Employee } from "../../../shared/models/employee.model";
import EmployeeSelect from "../../../shared/components/forms/widgets/selection-forms/EmployeeSelect";
import ClearIcon from '@mui/icons-material/Clear';

const TraceList = () => {
  const { t } = useTranslation();
  const { masterRefresh } = useAppStore();
  const service = new TraceService();

  const [isLoading, setIsLoading] = useState(false);
  const [traces, setTraces] = useState<Trace[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [dateSelectOpen, setDateSelectOpen] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const [employeeSelectOpen, setEmployeeSelectOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);

  const fetchTraces = async (page: number) => {
    setIsLoading(true);
    try {
      const offset = page * 10;
      if (page === 0) {
        setTraces([]);
      }
      const newData = await service.getTraces(searchQuery, offset, selectedEmployee);
      setTraces((prev) => page === 0 ? newData : [...prev, ...newData]);
      setShowLoadMore(newData.length === 20);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(0);
    setSearchQuery('');
    fetchTraces(0);
  }, [masterRefresh, selectedEmployee]);

  const handleOpenEmployeeFilter = () => {
    setEmployeeSelectOpen(true);
  };

  const handleCloseEmployeeFilter = async () => {
    setCurrentPage(0);
    setEmployeeSelectOpen(false);
    fetchTraces(0);
  };

  const handleSearch = () => {
    setCurrentPage(0);
    fetchTraces(0);
  };

  const handleShowMore = () => {
    const newPage = currentPage + 1;
    setCurrentPage(newPage);
    fetchTraces(newPage);
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      >
        <div className="d-flex flex-row mt-5 mb-5">
          <TextField
            variant="outlined"
            placeholder={t("Search requests...")}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              width: "90%",
              "& .MuiOutlinedInput-root": {
                background: "white",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                borderRight: "none",
                height: "45px",
                "& .MuiInputBase-input": {
                  height: "100%",
                  padding: "16.5px 14px",
                },
              },
            }}
          />
          <TextFieldButton type="submit">{t("search")}</TextFieldButton>
        </div>
      </form>

      <div className="card mb-3">
        <div className="card-body">
          <TableHeader
            tableName={'Trace Thread'}
            onAction={handleOpenEmployeeFilter}
            onAltAction={() => setSelectedEmployee(null)}
            actionButtonName={<PersonSearchIcon />}
            altButtonName={selectedEmployee ? `[X] ${selectedEmployee.person.first_name} ${selectedEmployee.person.last_name}` : '' }
          />
          <div className="table-responsive">
            <table className="table table-row-dashed align-middle gs-0 gy-3">
              <thead>
              <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                <th className="p-0 pb-3 min-w-215px text-start">{t("executed-by").toUpperCase()}</th>
                <th className="p-0 pb-3 min-w-215px text-start">{t("request").toUpperCase()}</th>
                <th className="p-0 pb-3 min-w-100px text-start">{t("status").toUpperCase()}</th>
                <th className="p-0 pb-3 min-w-100px text-start">{t("time").toUpperCase()}</th>
                <th className="p-0 pb-3 min-w-50px text-end">{t("actions").toUpperCase()}</th>
              </tr>
              </thead>
              <tbody>
              {traces.map((trace) => (
                <TraceRow key={trace.id} trace={trace} />
              ))}
              </tbody>
            </table>
            {isLoading && <CustomLoadingDots />}
          </div>
        </div>
      </div>
      {(showLoadMore || isLoading) && <LoadMoreButton onClick={handleShowMore} isLoading={isLoading} />}
      {employeeSelectOpen && (
        <EmployeeSelect
          open={employeeSelectOpen}
          onClose={handleCloseEmployeeFilter}
          selection={selectedEmployee}
          setSelection={setSelectedEmployee}
          singleSelect={true}
        />
      )}
    </>
  );
};

export default TraceList;
