import { Person } from "./person.model";
import { Work } from "./work.model";
import { Organization } from "./organization.model";
import { AppUserModel } from "./appUserModel";

export class Employee {
    id: number;
    uuid: string;
    employee_id: string;
    country_code: string;
    phone_number: string;
    email: string;
    organization: Organization;
    person: Person;
    user: AppUserModel;
    works_at: Work[];

    constructor() {
        this.id = -1;
        this.uuid = '';
        this.country_code = '';
        this.phone_number = '';
        this.email = '';
        this.employee_id = '';
        this.organization = new Organization();
        this.person = new Person();
        this.user = new AppUserModel();
        this.works_at = [];
    }

    setPosition(position_id){

        if( this.works_at.length === 0 ){
            this.works_at.push(new Work())
        }

        this.works_at[0].position.id = position_id

    }

    setLocation(location_id){

        if( this.works_at.length === 0 ){
            this.works_at.push(new Work())
        }

        this.works_at[0].location.id = location_id

    }

}
