import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import useCasesStore from "../../../../caseStore";
import useAppStore from "../../../../../../appStore";
import { Media } from "../../../../../../shared/models/media.model";
import PhotoUploaderViewer from "../../../../../../shared/components/media/media-viewers/photos/PhotoUploaderViewer";
import { LocationModel } from "../../../../../../shared/models/location.model";

const BaseCaseDetails = () => {

  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const {caseToEdit, setCaseToEdit} = useCasesStore();
  const [media, setMedia] = useState<Media[]>(caseToEdit.media);
  const {selectedLocation} = useAppStore();


  useEffect(() => {
    caseToEdit.location = selectedLocation;
    setCaseToEdit(prev => {
      const updated = {
        ...prev,
        media: media
      };
      return updated;
    });

  }, [media]);

  const handleDescChange = (event) => {
    setCaseToEdit(prev => ({ ...prev, description: event.target.value }));
  };

  const handleLocationChange = (location: LocationModel) => {
    caseToEdit.location = location;
    setCaseToEdit({
      ...caseToEdit,
      location: location,
    });
  };


  return (
    <>
      <FormControl fullWidth style={{ marginBottom: 15 }}>
        <span className="text-gray-700">Location</span>
        <Select
          value={caseToEdit.location?.id || ''}
        >
          {user?.permissions.locations.map((location) => (
            <MenuItem key={location.id} value={location.id} onClick={() => handleLocationChange(location)}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ marginBottom: 15 }}>
        <span className="text-gray-700">Description</span>
        <TextField
          name="Description"
          value={caseToEdit.description}
          onChange={handleDescChange}
          fullWidth
          multiline
          rows={5} />
      </div>
      <div style={{ marginTop: 15, marginBottom: 15 }}>
        <span className="text-gray-700">Attachments</span>
        <PhotoUploaderViewer
          item={caseToEdit}
          setMedia={setMedia}
          captions={caseToEdit.status === "Closed"}
          module={"cases"}
        />
      </div>
    </>

  );
};

export default BaseCaseDetails;
