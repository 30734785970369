import React, {useEffect, useState,} from "react";
import useAppStore from "../../../../appStore";
import SurveyCampaignTable from "./components/SurveyCampaignTable";
import { SurveyService } from "../../../../shared/services/surveys/surveyService";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import { useTranslation } from "react-i18next";

const SurveyCampaignList = (props) => {

    const {
        triggerRefresh
    } = props

    const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
    const {selectedLocation, appImages} = useAppStore();
    const {t} = useTranslation();


    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const [campaigns, setCampaigns] = useState<any>([]);

    useEffect(() => {

        const fetchCampaigns = async () => {

            setIsLoading(true);

            try {

                const service = new SurveyService();
                const campaigns = await service.getSurveyCampaigns(user.organization.id, selectedLocation.id);

                setCampaigns(campaigns);

            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            }finally {
                setIsLoading(false);
            }
        };

        fetchCampaigns().then();

    }, [selectedLocation, triggerRefresh]);
    if (isLoading) {
        return <CustomLoadingDots />;
    }
    if (error) {
        return <CustomError error={error} />;
    }

    return (
        <>
            {campaigns.length === 0 && !isLoading ? (
              <div style={{ textAlign: "center", margin: "20px", padding: "20px" }}>
                  <img
                    src={appImages.iconAlert}
                    style={{ width: '150px', height: '150px', marginBottom: '10px' }}
                  />
                  <p style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "15px" }}>
                      {t('no-active-surveys')}
                  </p>
                  <p>This location has no active campaigns</p>
              </div>
            ) : (
              <SurveyCampaignTable campaigns={campaigns}/>
            )}
        </>

    )
}

export default SurveyCampaignList


