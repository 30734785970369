import React, { useState } from "react";
import { Popover, Box, FormControlLabel, Checkbox, Button, AppBar, Toolbar, Typography, TextField } from "@mui/material";
import { InspectionPointModel } from "../../../../../../../../../shared/models/inspectionPoint.model";
import { useTranslation } from "react-i18next";

const InspectionPointsSelector = ({ reasons, setReasons, open, anchorEl, setAnchorEl }) => {

  const {t} = useTranslation();
  const id = open ? 'simple-popover' : undefined;

  const handleReasonChange = (event) => {
    const { name, checked } = event.target;
    const reasonId = parseInt(name);

    const updatedReasons = reasons.map((reason) => {
      if (reason.id === reasonId) {
        return { ...reason, is_pass: checked };
      }
      return reason;
    });

    setReasons(updatedReasons);
  };

  const handleDone = () => {
    setAnchorEl(null);
  };

  const selected = reasons.filter(reason => reason.is_pass === true);

  return (
    <>
      <div style={{ cursor: 'pointer', width: '100%', marginTop: '5px' }}>
        <div className="d-flex flex-column justify-content-center" style={{ width: '100%' }}>
          {selected.length > 0 && (
            <div style={{ fontSize: 10 }}>
              {selected[0].name || selected[0].id}{selected.length > 1 ? ` + ${selected.length - 1}` : ""}
            </div>
          )}
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleDone}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{
          marginTop: '10px',
          marginLeft: '15px',
          width: '100%',
        }}
      >
        <Box sx={{ width: 300 }}>
          <AppBar position="static" color="primary">
            <Toolbar>
              <Typography variant="h6" style={{ flexGrow: 1, fontSize: 16, color: 'white' }}>
                {t('select-reasons-for-fail')}
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ p: 2 }}>
            {reasons.map((reason) => (
              <FormControlLabel
                sx={{ width: '100%' }}
                key={reason.id}
                control={
                  <Checkbox
                    checked={reason.is_pass}
                    onChange={handleReasonChange}
                    name={reason.id}
                  />
                }
                label={reason.name || reason.id}
              />
            ))}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleDone}>
                {t('done')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default InspectionPointsSelector;


