import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { OrganizationSerializer } from "../../../models/serializers/organizationSerializer";

export class CommandGetOrgs {

  CONTACT_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.CONTACT_SERVICE}/organizations/`;
  }

  async run(provider) {

    const url = this.getUrl()

    const params = {
      params: {
        provider: provider.id
      }
    }

    const tracer = useTracer.getState()

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Organization', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('Organization', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new OrganizationSerializer()
    const orgs = serializer.deserializeList(data)
    return orgs
  }

}

