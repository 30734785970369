import React, { useEffect, useState } from "react";
import { Box, Button, Divider } from "@mui/material";
import useServiceStore from "../../../../serviceStore";
import ServiceItemEdit from "./ServiceItemEdit";
import useAppStore from "../../../../../../appStore";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ServiceItemList from "./ServiceItemList";
import { ServicesService } from "../../../../../../shared/services/services/servicesService";

const SelectedServicePreview = (props) => {
  const servicesService = new ServicesService();
  const { selectedService, setSelectedServiceItem, selectedServiceItem } = useServiceStore();
  const { t } = useTranslation();
  const { appImages } = useAppStore();
  const [isEditing, setIsEditing] = useState(false);
  const [showEditView, setShowEditView] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setShowEditView(false);
  }, [selectedService]);

  useEffect(() => {
    const fetchServiceItems = async () => {
      try {
        setIsLoading(true);
        const fetched = await servicesService.getServiceItems(selectedService);
        setItems(fetched);
      } catch (error) {
        console.error('Failed to fetch service items:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedService && selectedService.id !== 0) {
      fetchServiceItems().then();
    }
  }, [selectedService]);

  const handleAddServiceItem = () => {
    // Your logic to add a service item
  };

  const handleEditItem = (item) => {
    setSelectedServiceItem(item);
    setShowEditView(true);
  };

  const handleBackToList = () => {
    setShowEditView(false);
    setIsEditing(false);
    setSelectedServiceItem(null);
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div
      className="d-flex flex-column flex-row-fluid"
      key={selectedService ? selectedService.id : 'no-service'}
    >
      {selectedService && !selectedServiceItem && (
        <>
          <div className="d-flex flex-row justify-content-between align-items-center mb-5">
            <div
              className="d-flex flex-row"
              style={{ paddingLeft: '10px', paddingRight: '10px' }}
            >
              <h3 style={{ marginTop: '5px', marginRight: '8px' }}>
                {selectedService?.name}
              </h3>
            </div>
            <Button
              variant="contained"
              onClick={handleAddServiceItem}
              style={{ height: '40px' }}
            >
              <Add sx={{ marginRight: '5px' }} /> Service Item
            </Button>
          </div>
        </>
      )}
      {selectedService?.id ? (
        showEditView ? (
          <>
            {selectedServiceItem &&
              <Button
                variant="text"
                onClick={handleBackToList}
                style={{ marginTop: '10px' }}
              >
                Back to List
              </Button>
            }
            <ServiceItemEdit isEditing={isEditing} setIsEditing={setIsEditing} />
          </>
        ) : (
          <ServiceItemList
            items={items}
            onEditItem={handleEditItem}
            isLoading={isLoading}
          />
        )
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          textAlign="center"
          p={2}
          mt={15}
        >
          <div>
            <img
              src={appImages.iconService}
              style={{ width: '175px', height: '175px', marginBottom: '10px' }}
            />
            <p
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                marginBottom: '15px',
              }}
            >
              {t('no-service-selected')}
            </p>
            <p>{t('select-service')}</p>
          </div>
        </Box>
      )}
    </div>
  );
};

export default SelectedServicePreview;
