import { useRef, useState } from "react";
import useInspectionsStore from '../../../../inspectionsStore';
import useAppStore from '../../../../../../appStore';
import useTracer from '../../../../../../shared/framework/tracer/tracer';
import { WorkItem } from '../../../../../../shared/models/workItem.model';
import { InspectionService } from "../../../../../../shared/services/inspections/inspectionService";

export interface ValidationResult {
    passed: boolean;
    messages: string[];
  }

  export function useEditInspectedEntity() {
    const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
    const { selectedLocation} = useAppStore();
    const scrollableContainerRef = useRef<any>();
    const { inspection, inspectedEntity, updateInspectedEntity, setInspection, newInspectedEntity } = useInspectionsStore();
    const tracer = useTracer();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const [buttonAction, setButtonAction] = useState('save');
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const isMenuOpen = Boolean(menuAnchorEl);
    const [isPrevDisabled, setIsPrevDisabled] = useState(true)
    const [isNextDisabled, setIsNextDisabled] = useState(false)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
    const [canCreateWorkItem, setCanCreateWorkItem] = useState(user.permissions.hasAccess('WorkItems'))
    const [activeStep, setActiveStep] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [prefill, setPrefill] = useState<WorkItem | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [needsWorkItem, setNeedsWorkItem] = useState(false);
    const [validationInfo, setValidationInfo] = useState<ValidationResult>({passed: true, messages: []})

    let biRef = {
      selectionPage: {
        validate: (): ValidationResult => {
          return {passed: true, messages: []}
        }
      },
      scorePage: {
        validate: (): ValidationResult => {
          return {passed: true, messages: []}
        }
      }
    }


    const handleNextClicked = () => {
      if (activeStep === 0 ){
        let valInfo = biRef.selectionPage.validate()
        setValidationInfo(valInfo)
        if (!valInfo.passed) return
      }
      goToStep(activeStep + 1)
    }

    const handlePrevClicked = () => {
      goToStep(activeStep - 1)
    }

    const goToStep = (index) => {

      if( index < 0) index = 0;
      if( index > 1) index = 1;

      setActiveStep(index)
      disableButtons(index)
    }

    const disableButtons = (index) => {

      if( index === 0 ){
        setIsSubmitDisabled(true)
        setIsPrevDisabled(true)
        setIsNextDisabled(false)
      }

      if( index === 1){
        setIsSubmitDisabled(false)
        setIsPrevDisabled(false)
        setIsNextDisabled(true)
      }

    }

    const handleMenuClick = (event) => {
      setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setMenuAnchorEl(null);
    };

    const handleMenuItemClick = (action) => {
      setButtonAction(action);
      handleMenuClose();
    };

    const handleClose = () => {
      goToStep(0)
      validationInfo.passed = true
      validationInfo.messages = []
      setValidationInfo(validationInfo)
    };

    const handleOnIndexChanged = (index, indexLatest, meta) => {
      goToStep(index)
    }

    const handleSubmitClicked = async (keepOpen: boolean) => {

      const validatePageAndSetLoading = () => {
        const valInfo = biRef.scorePage.validate();
        setValidationInfo(valInfo);
        if (!valInfo.passed) return false;
        setIsLoading(true);
        return true;
      };

      const finalize = () => {
        setIsLoading(false);
        setActiveStep(1);
        if (!keepOpen) {
          handlePrevClicked()
        }
      };

      if (!validatePageAndSetLoading()) return;

      tracer.trace('Inspections', 'Trace', 'Creating Inspected Entity');

      try {
        inspection.provider.id = user.organization.id;
        inspection.location.id = selectedLocation ? selectedLocation.id : 0;
        inspection.inspected_by.id = user ? user.employee.id : 0;

        const service = new InspectionService();

        if (!inspection.id) {
          const newInspection = await service.createInspection(inspection);
          setInspection(newInspection);
          updateInspectedEntity(newInspection.inspected_entities[0]);
        } else {
          const entity = inspectedEntity.id ? await service.updateInspectedEntity(inspection, inspectedEntity) : await service.createInspectedEntity(inspection, inspectedEntity);
          updateInspectedEntity(entity);
          if (!keepOpen && isEditing)
            handleClose();

        }

        if (keepOpen) return;
        else newInspectedEntity();

      } catch (error) {
        if (error instanceof Error) setError(error.message);
      } finally {
        finalize();
      }
    };

  return {
    // States
    user,
    scrollableContainerRef,
    biRef,
    isLoading,
    error,
    activeStep,
    isEditing,
    snackbarOpen,
    validationInfo,
    buttonAction,
    isMenuOpen,
    isPrevDisabled,
    isNextDisabled,
    isSubmitDisabled,
    canCreateWorkItem,
    selectedLocation,
    inspection,
    inspectedEntity,
    tracer,
    menuAnchorEl,
    needsWorkItem,
    prefill,
    // Functions
    setIsLoading,
    setError,
    setActiveStep,
    setIsEditing,
    setSnackbarOpen,
    setValidationInfo,
    setButtonAction,
    setMenuAnchorEl,
    setIsNextDisabled,
    setIsPrevDisabled,
    setIsSubmitDisabled,
    setCanCreateWorkItem,
    updateInspectedEntity,
    setInspection,
    setNeedsWorkItem,
    setPrefill,
    newInspectedEntity,
    goToStep,
    disableButtons,
    // Handlers
    handleNextClicked,
    handlePrevClicked,
    handleMenuClick,
    handleMenuClose,
    handleMenuItemClick,
    handleClose,
    handleOnIndexChanged,
    handleSubmitClicked,
  };
}
