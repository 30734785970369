import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../../../app/modules/auth";
import useAppStore from "../../../../../app/appStore";
import { LocationService } from "../../../../../app/shared/services/locations/locationService";
import { LocationModel } from "../../../../../app/shared/models/location.model";
import CustomLoadingDots from "../../../../../app/shared/components/page/popup-dialog/CustomLoadingDots";

export function MenuInner() {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { setSelectedLocation } = useAppStore();
  const defaultLocationId = user?.permissions.locations?.[0]?.id || "";
  const [selectedLocationId, setSelectedLocationId] = useState(defaultLocationId);
  const [allLocations, setAllLocations] = useState<LocationModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchLocations = useCallback(async () => {
    try {
      setIsLoading(true)
      const service = new LocationService();
      const locations = await service.getLocations(user.organization.id);
      setAllLocations(locations);
    } catch (err) {
      console.log("error fetching locations");
    } finally {
      setIsLoading(false)
    }
  }, [user.organization.id]);

  useEffect(() => {
    fetchLocations().then();
  }, [fetchLocations]);

  useEffect(() => {
    const storedLocationId = localStorage.getItem('selectedLocationId');
    const locationId = storedLocationId || defaultLocationId;

    if (locationId) {
      handleOnSelect(locationId);
    }
  }, [defaultLocationId, allLocations]);

  const handleOnSelect = (selectedId: string | number) => {
    let locationId = Number(selectedId);
    let selectedLocation = allLocations.find(location => location.id === locationId);

    if (!selectedLocation && allLocations.length > 0) {
      selectedLocation = allLocations.find(location => location.id === locationId);
    }

    if (selectedLocation) {
      setSelectedLocation(selectedLocation);
      setSelectedLocationId(locationId);
      localStorage.setItem('selectedLocationId', locationId.toString());
    }

  };

  if (isLoading) {
    return (
      <div className="d-flex flex-column-fluid">
        <CustomLoadingDots />
      </div>
    )
  }

  return (
    <div className="d-flex flex-column-fluid">
      <select className='form-select form-select-transparent fw-bold'
              value={selectedLocationId}
              onChange={(event) => handleOnSelect(event.target.value)}
      >
        {user?.permissions.locations.map((location) => (
          <option key={location.id} value={location.id}>{location.name}</option>
        ))}
      </select>
    </div>
  );
}
