import React from "react";
import { Button, Divider } from "@mui/material";
import ServiceCatalog from "./components/left-panel/ServiceCatalog";
import SelectedServicePreview from "./components/right-panel/SelectedServicePreview";
import { useTranslation } from "react-i18next";

const ServiceCatalogDualViewer = (props) => {
  const {
    onAdd,
    onEdit,
  } = props;

  const {t} = useTranslation();

  return (
    <>
      <div className="card">
        <div className="card-body d-flex flex-row" style={{ alignItems: 'flex-start' }}>
          <div className="d-flex flex-column flex-row-auto w-400px" style={{ minHeight: '600px', overflow: 'auto' }}>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h3>{t('services')}</h3>
              <Button variant="contained" color="primary" onClick={onAdd} style={{ height: '35px' }}>
                + {t('service')}
              </Button>
            </div>

            <ServiceCatalog />
          </div>

          <Divider orientation="vertical" flexItem
                   style={{ backgroundColor: 'gray', marginRight: '10px', marginLeft: '10px' }} />


          <SelectedServicePreview onAdd={onAdd} onEdit={onEdit} />

        </div>
      </div>
    </>
  );
};


export default ServiceCatalogDualViewer;
