import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { getTimeDifferenceString } from "../../../../../shared/utils/table-modifier-util/tableDataUtil";
import GenericValueCard from "../../../../../shared/components/page/tiles/GenericValueCard";
import { useTranslation } from "react-i18next";

const SurveySummaryTiles = (props) => {
  const {
    summary
  } = props;

  const {t} = useTranslation();

  return (
    <Box sx={{ '& > :not(style)': { mb: 2 } }}>
      {summary && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6} md={2}>
              <GenericValueCard title={t('avg-score')} value={summary.avg_score?.toFixed(1) ?? 0.0} center={true} />
            </Grid>
            <Grid item xs={6} md={2}>
              <GenericValueCard title={t('total-sent')} value={summary.sent_count} center={true} />
            </Grid>
            <Grid item xs={6} md={2}>
              <GenericValueCard title={t('total-received')} value={summary.received_count} center={true} />
            </Grid>
            <Grid item xs={6} md={2}>
              <GenericValueCard title={t('last-received')} value={getTimeDifferenceString(summary.last_received)} center={true} />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default SurveySummaryTiles;
