import React, { useEffect, useState } from "react";
import PageHeader from "../../../shared/components/page/headers/PageHeader";
import SuppliesList from "../../supplies/components/supplies-list/SuppliesList";
import { Supply } from "../../../shared/models/supply.model";
import ComponentViewerSmall from "../../../shared/components/page/modal/ComponentViewerSmall";
import MediaDisplay from "../../../shared/components/media/media-viewers/photos/MediaDisplay";


const SafetyDataSheets = () => {

  const [supplyToEdit, setSupplyToEdit] = useState<Supply | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);


  useEffect(() => {
    console.log(supplyToEdit)
  }, [supplyToEdit]);


  const handleView = (supply) => {
    setSupplyToEdit(supply);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSupplyToEdit(null);
  };

  return (
    <div style={{ padding: 15, backgroundColor: "white", minHeight: "100vh" }}>
      <PageHeader
        title="Safety Data Sheets"
        showAction={false}
      />

      <SuppliesList
        refreshList={true}
        onEdit={handleView}
        readOnly={true}
      />

      {isDialogOpen && supplyToEdit && (
        <ComponentViewerSmall selectedPage={<MediaDisplay itemMedia={supplyToEdit.media} module={"supplies"} />} onActionClicked={handleDialogClose} />
      )}

    </div>
  );
}

export default SafetyDataSheets;