import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { AppBar, Box, IconButton, Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useAppStore from "../../../../appStore";

const SageAlert = (props) => {
  const {
    open,
    onClose,
  } = props;

  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const imageUrl = "https://static.wixstatic.com/media/0944ee_628e216ed71245c38fa938128516e49c~mv2.png/v1/fill/w_120,h_146,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Verde_Safety_Icon.png";
  const imageStyle = {
    position: 'relative',
    width: 55,
    height: 55,
    objectFit: 'cover',
    objectPosition: 'center',
    border: '3px solid #15aa78',
    backgroundColor: '#e9e9e9'
  };

  const handleClose = () => {
    onClose();
  };

  const handleConfirmed = () => {
    onClose();
    handleAcknowledgeClicked().then();
  };

  const handleAcknowledgeClicked = async () => {
    try {
      await httpClient.post( `${process.env.REACT_APP_API_FACILITIES_SERVICE_URL}/workitems/viewed/`, user);
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: '#e8e8e8', color: '#555' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Sage Alert
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <Box sx={{ ...imageStyle, borderRadius: '50%', overflow: 'hidden', mr: 2 }}>
            <img
              src={imageUrl}
              alt={"Sage"}
              style={{
                marginLeft: '4px',
                height: '100%',
                width: '85%',
                scale: '85%',
                objectPosition: 'center'
              }}
            />
          </Box>
          <div style={{ flexGrow: 1, fontSize: '1.2rem', marginTop: '5px' }}>
            You have new work items
          </div>
        </DialogTitle>
        <DialogActions sx={{mb: 1}}>
          <Button variant={'contained'} onClick={handleConfirmed} sx={{width: '100%'}}>
            View
          </Button>
          <Button variant={'contained'} onClick={handleConfirmed} sx={{width: '100%'}}>
            Acknowledge
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export { SageAlert };
