import CloseIcon from '@mui/icons-material/Close';
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import { projectStore } from "../../../../projectsStore";
import ModalTransition from "../../../../../../shared/components/page/transitions/ModalTransition";
import { AppBar, IconButton, Toolbar, Typography, Slider, Button } from "@mui/material";
import { useSnapshot } from "valtio";

const RankProject = ({ open, onActionClicked }) => {
  const snap = useSnapshot(projectStore);
  const { selectedProject } = snap;

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => onActionClicked('close')} TransitionComponent={ModalTransition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6">
            Project Ranking
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '1.5rem' }} variant="h6">
          {selectedProject?.name || 'Selected Project'}
        </Typography>

        {/* Impact Selection */}
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>Impact</Typography>
          <Slider
            defaultValue={3}
            step={1}
            min={1}
            max={5}
            marks={[
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
              { value: 4, label: '4' },
              { value: 5, label: '5' },
            ]}
            valueLabelDisplay="auto"
          />
        </Box>

        {/* Difficulty Selection */}
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>Difficulty</Typography>
          <Slider
            defaultValue={5}
            step={1}
            min={1}
            max={10}
            marks={[
              { value: 1, label: '1' },
              { value: 5, label: '5' },
              { value: 10, label: '10' },
            ]}
            valueLabelDisplay="auto"
          />
        </Box>

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={() => onActionClicked('save')}>Save</Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RankProject;

