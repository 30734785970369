import React, { useEffect, useState } from 'react';
import useAppStore from '../../../../../../appStore';
import { fetchMonthlyScores } from '../../../../../../shared/utils/data-fetch/analyticDataFetchHelper';
import CustomLoadingDots from '../../../../../../shared/components/page/popup-dialog/CustomLoadingDots';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Card, CardContent, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
import { drilldownState } from "../../drilldownStore";

const MonthScoreBarChart = ({ location_id, startDate = null, endDate = null }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const {t} = useTranslation();
  const { whiteLabelProps, selectedLocation } = useAppStore();
  const [chartData, setChartData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setIsLoading(true);
    setError('');
    fetchMonthlyScores(user.organization.id, location_id, startDate, endDate)
      .then((data) => {
        setChartData(data);
      })
      .catch((error) => {
        setError(error instanceof Error ? error.message : String(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedLocation.id, user.organization.id, startDate, endDate]);

  if (isLoading) {
    return <CustomLoadingDots />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!chartData || chartData.length === 0) {
    return <p>No data available</p>;
  }

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      id: 'monthly-scores',
      stacked: false,
      toolbar: { show: false },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const { seriesIndex, dataPointIndex, w } = config;
          const clickedValue = w.config.series[seriesIndex].data[dataPointIndex];
          const clickedCategory = w.config.xaxis.categories[dataPointIndex];
          const dataEntry = chartData[dataPointIndex];
          const clickedSeriesName = w.config.series[seriesIndex].name;

          const monthDate = dayjs(dataEntry.month);
          const from_date = monthDate.startOf('month').format('YYYY-MM-DD');
          const to_date = monthDate.endOf('month').format('YYYY-MM-DD');
          drilldownState.from_date = from_date;
          drilldownState.to_date = to_date;
          drilldownState.include_regular = clickedSeriesName === 'Internal' ? true : false;
          drilldownState.include_joint = clickedSeriesName === 'Joint' ? true : false;
          drilldownState.include_baseline = clickedSeriesName === 'Baseline' ? true : false;
          drilldownState.sourceId = location_id === 0 ? undefined : location_id;
          drilldownState.drilldownOpen = true;
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
      },
    },
    xaxis: {
      categories: chartData.map((entry: any) => entry.month),
      labels: {
        formatter: (value) => dayjs(value).format('MMM YYYY'),
      },
    },
    yaxis: {
      title: {
        text: 'Score',
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: [whiteLabelProps.primary_color, '#08e68B', '#e8e8e8'],
  };

  const series = [
    {
      name: `${t('internal')}`,
      data: chartData.map((entry: any) =>
        entry.internal_inspection_score ? parseFloat(entry.internal_inspection_score) : 0
      ),
    },
    {
      name: `${t('joint')}`,
      data: chartData.map((entry: any) =>
        entry.joint_inspection_score ? parseFloat(entry.joint_inspection_score) : 0
      ),
    },
    {
      name: `${t('baseline')}`,
      data: chartData.map((entry: any) =>
        entry.baseline_inspection_score ? parseFloat(entry.baseline_inspection_score) : 0
      ),
    },
  ];

  return (
    <Card variant="outlined" sx={{ boxShadow: '0 1px 2px rgba(0,0,0,0.1)' }}>
      <CardContent>
        <Typography component="h1" variant="h6" textAlign="center">
          {t('scores-by-month')}
        </Typography>
        <Chart options={options} series={series} type="bar" height={350} />
      </CardContent>
    </Card>
  );
};

export default MonthScoreBarChart;


