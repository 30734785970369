import React, { useEffect, useState } from "react";
import ContactTable from "./components/ContactTable";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";
import useAppStore from "../../../../../../appStore";
import { ContactService } from "../../../../../../shared/services/location-contacts/contactService";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { LocationContact } from "../../../../../../shared/models/locationContact.model";

const ContactsList = (props) => {
  const { canEdit, onEdit } = props;

  const service = new ContactService();
  const { masterRefresh, selectedLocation } = useAppStore();
  const [error, setError] = useState("");
  const [contacts, setContacts] = useState<LocationContact[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchContacts = async () => {
    setIsLoading(true);
    try {
      const contactsData = await service.getContacts(selectedLocation.id);
      setContacts(contactsData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts().then();
  }, [masterRefresh, selectedLocation.id]);

  if (error) {
    return <CustomError error={error} />;
  }

  if (isLoading) {
    return <CustomLoadingDots />
  }

  return (
    <>
      <ContactTable contacts={contacts} canEdit={canEdit} onEdit={onEdit} />
    </>
  );
};

export default ContactsList;
