import React, { useEffect } from "react";
import useTrainingStore from "./trainingStore";
import useAppStore from "../../../appStore";
import { TrainingCategory } from "../../../shared/models/training-category.model";
import PageHeader from "../../../shared/components/page/headers/PageHeader";
import TrainingCatalogTreeViewer from "./components/catalog-tree-viewer/TrainingCatalogTreeViewer";
import EditCoursePage from "./components/edit-course/EditCourse";
import EditCategory from "./components/edit-category/EditCategory";
import { TrainingService } from "../../../shared/services/training/trainingService";
import { Course } from "../../../shared/models/course.model";
import { useTranslation } from "react-i18next";

const TrainingCatalogPage = () => {

    const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
    const { t } = useTranslation();
    const {masterRefresh, toggleMasterRefresh} = useAppStore();
    const { selectedCategory, setSelectedCategory, fetchCategories, trainingCategories, courseToEdit, setCourseToEdit, isEditingCourse, setIsEditingCourse } = useTrainingStore();
    const [isEditingCategory, setIsEditingCategory] = React.useState(false);


    useEffect(() => {
        const selectedCategoryId = selectedCategory?.id;

        fetchCategories(user.organization?.id).then(() => {
            if (selectedCategoryId) {
                const refreshedCategory = trainingCategories.find((category) => category.id === selectedCategoryId);
                setSelectedCategory(refreshedCategory || null);
            }
        });
    }, [masterRefresh]);



    function handleEditCourse(course) {
        setCourseToEdit(course);
        setIsEditingCourse(true);
    }

    function handleNewCourse() {
        setCourseToEdit(new Course());
        setIsEditingCourse(true);
    }

    function handleCloseModal() {
        setIsEditingCategory(false);
        toggleMasterRefresh();
    }

    function handleCloseCourse() {
        setIsEditingCourse(false);
        toggleMasterRefresh();
    }

    function handleAddCategory() {
        let newCategory = new TrainingCategory();
        newCategory.organization = user?.organization;
        setSelectedCategory(newCategory);
        setIsEditingCategory(true);
    }

    async function handleSaveCourse() {
        try {
            const service = new TrainingService();
            let response;

            if (courseToEdit?.id === 0) {
                response = await service.createCourse(courseToEdit);
            } else {
                response = await service.updateCourse(courseToEdit);
            }

            setCourseToEdit(response);
        } catch (e) {
            console.error('Error in handleSubmit:', e);
        }
    }

    async function handleSaveCategory() {

    }



    return (
            <>
                <PageHeader title={t('training-catalog')}
                            showAction={false}
                />
                <TrainingCatalogTreeViewer
                  selectedCategory={selectedCategory}
                  handleAddCategory={handleAddCategory}
                  handleEditCourse={handleEditCourse}
                  handleNewCourse={handleNewCourse}
                />
                {isEditingCourse && (
                      <EditCoursePage
                        open={isEditingCourse}
                        course={courseToEdit}
                        setCourse={setCourseToEdit}
                        onClose={handleCloseCourse}
                        onSubmit={handleSaveCourse} />
                )}
                {isEditingCategory && (
                  <EditCategory
                    open={isEditingCategory}
                    onClose={handleCloseModal}
                    onSave={handleSaveCategory}
                  />
                )}
            </>
    );

};


export default TrainingCatalogPage;
