import React, { useEffect, useState } from "react";
import useAppStore from "../../../../../../appStore";
import InspectionDualBarChart from "./components/InspectionDualBarChart";
import { fetchIssuesChart } from "../../../../../../shared/utils/data-fetch/analyticDataFetchHelper";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";

type ChartHeaderRow = [string, string];
type ChartDataRow = [string, number];
type ChartDataState = Array<ChartHeaderRow | ChartDataRow>;

const IssuesByServiceItem = ({ location_id, startDate, endDate }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { whiteLabelProps, selectedLocation } = useAppStore();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [chartData, setChartData] = useState<ChartDataState>([['Service Item', 'Issue Count']]);
  const [maxValue, setMaxValue] = useState<number>(0);

  useEffect(() => {
    setIsLoading(true);
    setError('');
    fetchIssuesChart(user.organization.id, location_id, 'inspections', 'issues_by_service_item', startDate, endDate)
      .then(({ transformedData, maxCount }) => {
        setChartData(transformedData);
        setMaxValue(maxCount);
      })
      .catch(error => {
        setError(error instanceof Error ? error.message : String(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [location_id, user.organization.id, startDate, endDate]);

  if (isLoading) {
    return <CustomLoadingDots />
  }

  return (
    <InspectionDualBarChart
      chartData={chartData}
      chartTitle={"Issue Count"}
      primaryColor={whiteLabelProps.primary_color}
      max={maxValue}
      sortOrder={'desc'}
      type={'service-item'}
      location_id={location_id}
    />
  );
};

export default IssuesByServiceItem;

