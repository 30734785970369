import { InspectedEntity } from "./inspectedEntity.model";
import { Person } from "./person.model";
import { LocationModel } from "./location.model";
import { Organization } from "./organization.model";
import { AppDate } from "../utils/date-time/AppDate";
import { Employee } from "./employee.model";
import { Space } from "./space.model";


export class Inspection {

    id: null | number;
    uuid: null | string;
    create_date: AppDate;
    inspected_date: string;
    score: number;
    name: string;
    description: string;
    kind_of: string;
    inspected_by: Person;
    spaces: Space[];
    is_joint_inspection: boolean;
    is_baseline: boolean;
    joint_inspected_by: null | Employee;
    location: LocationModel;
    provider: Organization;
    inspected_entities: InspectedEntity[];
    inspected_entities_count: number;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.create_date =  new AppDate();
        this.inspected_date = new AppDate().dateTime;
        this.score = 0.0;
        this.name = '';
        this.description = '';
        this.kind_of = 'Place';
        this.inspected_by = new Person();
        this.spaces = [];
        this.is_joint_inspection = false;
        this.is_baseline = false;
        this.joint_inspected_by = new Employee();
        this.location = new LocationModel();
        this.provider = new Organization();
        this.inspected_entities = [];
        this.inspected_entities_count = 0;
    }

}

