import React from "react";
import { Divider } from "@mui/material";
import SubHeader from "../../../shared/components/page/headers/SubHeader";

const DashboardSection = ({title, tile, margin=true}) => {

  return (
    <div style={{ marginTop: "25px", marginBottom: '10px' }}>
      <SubHeader title={title} color="#777" showAction={false} />
      <Divider
        sx={{ backgroundColor: "black", marginBottom: margin ? '15px' : '' }} />
      {tile}
    </div>
  );

}



export default DashboardSection;