import React from "react";
import PageHeader from "../../shared/components/page/headers/PageHeader";
import OrgChart from "./components/OrgChart";
import { useTranslation } from "react-i18next";

const OrgChartPage = () => {

    const {t} = useTranslation();

    return (
      <>
        <PageHeader
          title={t('org-chart')}
          showAction={false}
        />
        <OrgChart />
      </>
    );

}

export default OrgChartPage
