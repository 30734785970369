import axios from 'axios'
import { LoggedInUser } from '../../../shared/models/loggedInUser.model'
import {AuthModel, UserModel} from './_models'
import { UserSerializer } from "../../../shared/models/serializers/userSerializer";

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = process.env.REACT_APP_API_ACCOUNT_SERVICE_URL + `/account/verify_token/`
export const LOGIN_URL = process.env.REACT_APP_API_ACCOUNT_SERVICE_URL + '/account/login/'
export const REGISTER_URL = `${API_URL}/account/register/`
export const REQUEST_PASSWORD_URL = `${API_URL}/account/forgot_password/`
export const SCHOOLS = process.env.REACT_APP_API_SCHOOL_SERVICE_URL

// Server should return AuthModel
export function login(username: string, password: string | null) {
  let response = axios.post(LOGIN_URL, {
    username,
    password,
  })

  return response;

}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string, shadowingUser: string | undefined) {
  return axios.post<AuthModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    token: token,
    shadow_user: shadowingUser
  })
}
