import { proxy } from 'valtio';

interface DrilldownState {
  drilldownOpen: boolean;
  include_regular: boolean;
  include_joint: boolean;
  include_baseline: boolean;
  sourceId: number | undefined;
  from_date: string | undefined;
  to_date: string | undefined;
  space: number | undefined;
  space_type: number | undefined;
}

const initialState: DrilldownState = {
  drilldownOpen: false,
  include_regular: true,
  include_joint: true,
  include_baseline: true,
  sourceId: undefined,
  from_date: undefined,
  to_date: undefined,
  space: undefined,
  space_type: undefined,
};

const drilldownState = proxy<DrilldownState>(initialState);

const resetDrilldown = () => {
  drilldownState.drilldownOpen = false;
  drilldownState.include_regular = true;
  drilldownState.include_joint = true;
  drilldownState.include_baseline = true;
  drilldownState.sourceId = undefined;
  drilldownState.from_date = undefined;
  drilldownState.to_date = undefined;
  drilldownState.space = undefined;
  drilldownState.space_type = undefined;
};

export { drilldownState, resetDrilldown };
