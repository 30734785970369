import React, { useEffect, useState } from "react";
import CustomLoadingDots from "../../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { Box, Typography, Grid, Chip } from "@mui/material";
import useSpaceStore from "../../../../spaceStore";
import SubHeader from "../../../../../../../../shared/components/page/headers/SubHeader";
import Button from "@mui/material/Button";
import { EntityService } from "../../../../../../../../shared/services/entities/entityService";
import NameIcon from '@mui/icons-material/DriveFileRenameOutline';
import TypeIcon from '@mui/icons-material/Category';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import ShortTextIcon from '@mui/icons-material/ShortText';
import { capitalizeFirst } from "../../../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import { ConfirmationDialog } from "../../../../../../../../shared/components/page/popup-dialog/ConfirmationDialog";
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import DuotoneCategory from "../../../../../../../../shared/components/page/row-icons/DuotoneCategory";
import useAppStore from "../../../../../../../../appStore";

const SpaceDetailsView = ({ onEdit }) => {
  const { selectedSpace, setSelectedSpace, toggleRefresh, refresh } = useSpaceStore();
  const { whiteLabelProps } = useAppStore();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  useEffect(() => {
    const fetchEntityDetails = async () => {
      setIsLoading(true);
      try {
        const service = new EntityService();
        const entity = await service.getEntity(selectedSpace?.id);
        setSelectedSpace(entity);
      } catch (error) {
        console.error('Failed to fetch space details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedSpace?.id) {
      fetchEntityDetails().then();
    }
  }, [selectedSpace?.id, setSelectedSpace, refresh]);


    const handleConfirmDelete = async () => {
      const service = new EntityService();
      if (selectedSpace?.id) {
        try {
          await service.deleteEntity(selectedSpace.id);
          setConfirmationOpen(false);
          toggleRefresh();
        } catch (error) {
          console.error(error);
        }
      }
    };

  return (
    <>
      <Box className={"mb-5"} style={{ position: "sticky", top: 0, zIndex: 100 }}>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <SubHeader
            title={
              selectedSpace?.parent?.name
                ? `${selectedSpace.parent.name} / ${selectedSpace.name}`
                : selectedSpace?.name
            }
            showAction={false}
          />
          <div>
            <Button variant={'contained'} sx={{marginRight: '5px'}} onClick={() => onEdit(selectedSpace)}>Edit</Button>
            <Button variant={'contained'} onClick={() => setConfirmationOpen(true)}>Delete</Button>
          </div>
        </div>
        {isLoading ? (
          <CustomLoadingDots />
        ) : (
          <>
            <div className="h-md-100 mt-5 d-flex flex-column"
                 style={{ minHeight: "600px", borderTop: "1px dashed #e9e9e9" }}>
              <div className="d-flex flex-row mt-1" style={{ padding: "15px", borderBottom: "1px dashed #e9e9e9"}}>
                <div className="d-flex align-items-center">
                  <DuotoneCategory />
                  <div className="d-flex justify-content-start flex-column">
                    <span className="text-gray-400 fw-semibold d-block fs-7">In Service</span>
                    <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "0.9rem", color: "black" }}>
                      {capitalizeFirst(selectedSpace?.in_service.toString())}
                    </Typography>
                  </div>
                </div>
                <div className="d-flex align-items-center ms-8">
                  <DuotoneCategory />
                  <div className="d-flex justify-content-start flex-column">
                    <span className="text-gray-400 fw-semibold d-block fs-7">Service Logs</span>
                    <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "0.9rem", color: "black" }}>
                      {capitalizeFirst(selectedSpace?.has_service_log.toString())}
                    </Typography>
                  </div>
                </div>
                <div className="d-flex align-items-center ms-8">
                  <DuotoneCategory />
                  <div className="d-flex justify-content-start flex-column">
                    <span className="text-gray-400 fw-semibold d-block fs-7">Inspection Frequency</span>
                    <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "0.9rem", color: "black" }}>
                      Every {selectedSpace?.inspection_frequency} days
                    </Typography>
                  </div>
                </div>
                <div className="d-flex align-items-center ms-8">
                  <DuotoneCategory />
                  <div className="d-flex justify-content-start flex-column">
                    <span className="text-gray-400 fw-semibold d-block fs-7">Joint Inspection Frequency</span>
                    <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "0.9rem", color: "black" }}>
                      Every {selectedSpace?.joint_inspection_frequency} days
                    </Typography>
                  </div>
                </div>
              </div>
              <div style={{ padding: "20px" }}>
                <Grid container spacing={2} alignItems="center" style={{ marginBottom: "20px" }}>
                  <Grid item>
                    <TypeIcon style={{ color: whiteLabelProps.primary_color_light, fontSize: "2rem" }} />
                  </Grid>
                  <Grid item>
                    <span className="text-gray-400 fw-semibold d-block fs-7">Space Type</span>
                    <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "0.9rem", color: "black" }}>
                      {selectedSpace?.entity_type?.name || "Not Selected"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" style={{ marginBottom: "20px" }}>
                  <Grid item>
                    <MeetingRoomIcon style={{ color: whiteLabelProps.primary_color_light, fontSize: "2rem" }} />
                  </Grid>
                  <Grid item>
                    <span className="text-gray-400 fw-semibold d-block fs-7">Space Types Serviced</span>
                    <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "0.9rem", color: "black" }}>
                      {(() => {
                        const types = selectedSpace?.entity_types_to_service
                          .map(type => type?.name)
                          .filter(Boolean);

                        if (!types || types?.length === 0) return "None";

                        const displayedTypes = types.slice(0, 2).join(', ');
                        const additionalCount = types.length > 2 ? ` + ${types.length - 2} others` : '';

                        return `${displayedTypes}${additionalCount}`;
                      })()}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center" style={{ marginBottom: "20px" }}>
                  <Grid item>
                    <EscalatorWarningIcon style={{ color: whiteLabelProps.primary_color_light, fontSize: "2rem" }} />
                  </Grid>
                  <Grid item>
                    <span className="text-gray-400 fw-semibold d-block fs-7">Parent</span>
                    <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "0.9rem", color: "black" }}>
                      {selectedSpace?.parent?.name || "None"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" style={{ marginBottom: "20px" }}>
                  <Grid item>
                    <ShortTextIcon style={{ color: whiteLabelProps.primary_color_light, fontSize: "2rem" }} />
                  </Grid>
                  <Grid item>
                    <span className="text-gray-400 fw-semibold d-block fs-7">Short Name</span>
                    <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "0.9rem", color: "black" }}>
                      {selectedSpace?.short_name || "None"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" style={{ marginBottom: "20px" }}>
                  <Grid item>
                    <NameIcon style={{ color: whiteLabelProps.primary_color_light, fontSize: "2rem" }} />
                  </Grid>
                  <Grid item>
                    <span className="text-gray-400 fw-semibold d-block fs-7">Description</span>
                    <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "0.9rem", color: "back" }}>
                      {selectedSpace?.description || "No description given"}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        )}

        {confirmationOpen && (
          <ConfirmationDialog
            isOpen={confirmationOpen}
            setIsOpen={setConfirmationOpen}
            message="Delete?"
            description={"Are you sure you want to delete this space and all its sub-spaces? This action cannot be undone"}
            onConfirmClicked={handleConfirmDelete}
          />
        )}

      </Box>
    </>
  );
};

export default SpaceDetailsView;
