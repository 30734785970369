import React from "react";
import GreetingDashboardCard from "../components/greeting/GreetingDashboardCard";
import UserWorkItemsList from "../components/user-work-items/UserWorkItemsList";
import SubHeader from "../../../shared/components/page/headers/SubHeader";
import { Divider, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import useAppStore from "../../../appStore";
import SiteHealthCard
  from "../../analytics/components/site-health/components/tabs/corp-overview/components/card/SiteHealthCard";
import { useTranslation } from "react-i18next";

const SiteManagerDashboard = () => {

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const {selectedLocation, whiteLabelProps} = useAppStore();
  const { t } = useTranslation();

  return (
    <div className={'mb-10'}>
      <GreetingDashboardCard />

      <div style={{display: 'flex', flexDirection: 'column', marginTop: isMobile ? '0' : '20px'}}>

        <div style={{ width: '100%' }}>
          <div>
            <Grid container>
              {/*<Grid item xs={12} md={12}>*/}
              {/*  <DashboardSection title={"Priorities"} tile={<Priorities />} />*/}
              {/*</Grid>*/}
              <Grid item xs={12} md={12}>
                <div style={{ marginTop: '35px' }}>
                  <SubHeader title={t('sage-score')} color="#777" showAction={false} />
                  <Divider sx={{ bgcolor: 'black', marginBottom: '15px' }} />
                  {selectedLocation.id !== 0 &&
                    <SiteHealthCard location={selectedLocation}/>
                  }
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <UserWorkItemsList manager={true}/>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteManagerDashboard;