import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Grid, Menu, MenuItem, IconButton } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import VisibilityIcon from '@mui/icons-material/Visibility';
import useAppStore from "../../../../../appStore";
import { formatDate } from "../../../../../shared/utils/table-modifier-util/tableDataUtil";
import { DateTime } from 'luxon';
import { useTranslation } from "react-i18next";
import JointInspectionModal from "./modal/JointInspectionModal";
import { JointInspectionSummaryModel } from "../../../../../shared/models/jointInspectionSummary.model";
import { AnalyticsService } from "../../../../../shared/services/analytics/analyticsService";

const JointInspectionTile = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { selectedLocation, appImages } = useAppStore();
  const service = new AnalyticsService();

  const [summary, setSummary] = useState<JointInspectionSummaryModel>(new JointInspectionSummaryModel());

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [preset, setPreset] = useState("");
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);

  useEffect(() => {
    const fetchSummary = async () => {
      setIsLoading(true);
      setError('');
      try {
        const jointSummary = await service.getJointSummary(
          user.organization,
          selectedLocation,
          fromDate,
          toDate
        );
        setSummary(jointSummary);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchSummary().then();
  }, [user, selectedLocation.id, fromDate, toDate]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePresetChange = (value) => {
    setPreset(value);
    setAnchorEl(null);

    if (value === "all") {
      setFromDate(undefined);
      setToDate(undefined);
    } else {
      setFromDate(DateTime.now().minus({ days: Number(value) }));
      setToDate(DateTime.now());
    }
  };

  return (
    <Card sx={{
      height: "100%",
      boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
      borderBottomLeftRadius: '0', borderBottomRightRadius: '0',
      border: '1px solid #dcdcdc',
      borderBottom: '1px dashed #999'
    }}>
      <CardContent>
        <Grid container justifyContent="space-between" sx={{ marginBottom: '25px' }}>
          <Grid item>
            <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
              {t('joint-inspections')}
            </Typography>
            <Typography variant="caption" component="div" sx={{ fontStyle: 'italic' }}>
              {(preset === "" || preset === "all")
                ? t('all-time')
                : t('last-x-days', { count: Number(preset) })}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <IconButton onClick={handleMenuOpen}>
                <DateRangeIcon sx={{ fontSize: '1.9rem', color: '#c7c7c7' }} />
              </IconButton>
              <IconButton onClick={() => setOpen(true)}>
                <VisibilityIcon sx={{ fontSize: '1.9rem', color: '#c7c7c7' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handlePresetChange("30")}>Last 30 Days</MenuItem>
          <MenuItem onClick={() => handlePresetChange("60")}>Last 60 Days</MenuItem>
          <MenuItem onClick={() => handlePresetChange("90")}>Last 90 Days</MenuItem>
          <MenuItem onClick={() => handlePresetChange("all")}>All time</MenuItem>
        </Menu>
        <Grid container spacing={1}>
          <Grid item xs={4} textAlign="center">
            <div style={{ height: "40px", alignItems: "center", justifyContent: "center" }}>
              <img src={appImages.iconPassed} style={{ width: "34px", height: "34px", marginTop: "3px" }} />
            </div>
            <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
              {summary.joint_inspection_score}
            </Typography>
            <Typography variant="body2" color="textSecondary">{t('average')}</Typography>
          </Grid>
          <Grid item xs={4} textAlign="center">
            <div style={{ height: "40px", alignItems: 'center', justifyContent: 'center' }}>
              <img src={appImages.iconType} style={{ width: "44px", height: "44px" }} />
            </div>
            <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
              {summary.inspected_spaces_count}
            </Typography>
            <Typography variant="body2" color="textSecondary">{t('spaces')}</Typography>
          </Grid>
          <Grid item xs={4} textAlign="center">
            <div style={{ height: "40px", alignItems: 'center', justifyContent: 'center' }}>
              <img src={appImages.iconWorkDone} style={{ width: "36px", height: "36px", marginTop: '3px' }} />
            </div>
            <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
              {summary.last_inspection_date ? formatDate(summary.last_inspection_date.toString()) : 'N/A'}
            </Typography>
            <Typography variant="body2" color="textSecondary">{t('last-inspection')}</Typography>
          </Grid>
        </Grid>        {open && (
          <JointInspectionModal
            open={open}
            onActionClicked={() => setOpen(false)}
          />
        )}
      </CardContent>
    </Card>
  );
}

export default JointInspectionTile;

