import useAppStore from "../../../../appStore";
import React, { useEffect, useState } from "react";
import PositionTable from "./components/PositionTable";
import { EmployeeService } from "../../../../shared/services/employee/employeeService";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";

const PositionsList = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);

  const service = new EmployeeService();
  const [error, setError] = useState("");
  const [positions, setPositions] = useState<any>([]);

  useEffect(() => {
    service.getPositions(user.organization?.id)
      .then(positionsData => {
        setPositions(positionsData);
      })
      .catch(error => {
        setError(error instanceof Error ? error.message : String(error));
      });
  }, [user.organization?.id]);


  if (error) {
    return <CustomError error={error} />;
  }

  return (
    <>
      <PositionTable positions={positions} />
    </>
  );
};

export default PositionsList;