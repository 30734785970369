import React, { useState } from "react";
import EmployeeRow from "./EmployeeRow";
import EditEmployee from "../../edit-employee/EditEmployee";
import { EmployeeService } from "../../../../../shared/services/employee/employeeService";
import { Employee } from "../../../../../shared/models/employee.model";
import { ConfirmationDialog } from "../../../../../shared/components/page/popup-dialog/ConfirmationDialog";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    getCardClass,
    getTitleClass
} from "../../../../../shared/utils/table-modifier-util/tableDataUtil";
import useAppStore from "../../../../../appStore";
import { useTranslation } from "react-i18next";


const EmployeeTable = (props) => {

    const{
        employees,
        canEdit,
    } = props

    const {toggleMasterRefresh} = useAppStore();
    const {t} = useTranslation();
    const [employeeToEdit, setEmployeeToEdit] = useState<Employee | null>(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [employeeToDelete, setEmployeeToDelete] = useState<Employee | null>(null);
    const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
    const employeeService = new EmployeeService();

    const handleEditEmployeeClicked = (employee) => {
        setEmployeeToEdit(employee);
        setIsEditModalOpen(true);
    };

    const handleDeleteEmployeeClicked = (employee) => {
        setEmployeeToDelete(employee);
        setConfirmDeleteOpen(true);
    };

    const handleConfirmDelete = async () => {
        if (employeeToDelete) {
            try {
                await employeeService.deleteEmployee(employeeToDelete.id);
                setConfirmDeleteOpen(false);
                toggleMasterRefresh();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        setEmployeeToEdit(null);
        toggleMasterRefresh();
    };


    return (
      <>
          <div className={getCardClass(isMobile)}>
              <div className="card-body">
                  <div className="table-responsive">
                      <table className="table table-row-dashed align-middle gs-0 gy-3">
                          <thead>
                          <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                              {isMobile ? (
                                <>
                                    <th className="p-0 pb-3 min-w-150px text-start">{t('employee').toUpperCase()}</th>
                                    <th className="p-0 pb-3 min-w-25px text-start">{t('user').toUpperCase()}</th>
                                    <th className="p-0 pb-3 min-w-50px text-end px-3">{t('actions').toUpperCase()}</th>
                                </>
                              ) : (
                                <>
                                    <th className="p-0 pb-3 min-w-215px text-start">{t('employee').toUpperCase()}</th>
                                    <th className="p-0 pb-3 min-w-60px text-start px-3">{t('phone').toUpperCase()}</th>
                                    <th className="p-0 pb-3 min-w-160px text-start px-3">EMAIL</th>
                                    <th className="p-0 pb-3 min-w-50px text-start px-3">ID</th>
                                    <th className="p-0 pb-3 min-w-25px text-center">{t('actions').toUpperCase()}</th>
                                </>
                              )}
                          </tr>
                          </thead>
                          {/* End Table Head */}
                          {/* Begin Table Body */}
                          <tbody>
                          {employees.map((employee) => (
                            <EmployeeRow key={employee.id}
                                         employee={employee}
                                         onEditEmployeeClicked={handleEditEmployeeClicked}
                                         onDeleteEmployeeClicked={handleDeleteEmployeeClicked}
                                         canEdit={canEdit}
                                         isMobileView={isMobile}
                            />
                          ))}
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>

          {isEditModalOpen && (
            <EditEmployee
              isEdit={true}
              open={isEditModalOpen}
              employee_id={employeeToEdit?.id}
              onActionClicked={handleCloseEditModal}
            />
          )}
          {employeeToDelete && confirmDeleteOpen && (
            <ConfirmationDialog
              isOpen={confirmDeleteOpen}
              setIsOpen={setConfirmDeleteOpen}
              message="Delete Employee?"
              description={`Are you sure you want to delete ${employeeToDelete.person.first_name + ' ' + employeeToDelete.person.last_name}? This action cannot be undone.`}
              onConfirmClicked={handleConfirmDelete}
            />
          )}
      </>
    );

}

export default EmployeeTable