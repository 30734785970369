import { Person } from "./person.model";
import { Organization } from "./organization.model";
import { Employee } from "./employee.model";
import { GroupEntry } from "./groupEntry.model";
import { AppUserModel } from "./appUserModel";
import { LocationModel } from "./location.model";

export class LocationContact {
  id: number;
  organization: Organization;
  company_name: string;
  person: Person;
  employee: Employee;
  csat_reporting_group_entry: GroupEntry | null;
  user: AppUserModel | null;
  location: LocationModel | null;
  name: string;
  notes: string;
  title: string;


  constructor() {
    this.id = 0;
    this.organization = new Organization();
    this.company_name = "";
    this.person = new Person();
    this.employee = new Employee();
    this.csat_reporting_group_entry = new GroupEntry();
    this.location = new LocationModel();
    this.user = null;
    this.name = '';
    this.notes = '';
    this.title = '';
  }


}