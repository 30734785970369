import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ProgressTable = ({data}) => {

  const {t} = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Typography
        variant="h5"
        component="div"
        sx={{ padding: 2, textAlign: 'center', backgroundColor: 'primary.main', color: 'primary.contrastText' }}
      >
        {t('progress')}
      </Typography>
      <Table aria-label="Progress table">
        <TableHead>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>Name</TableCell>
            <TableCell align="right" sx={{fontWeight: 'bold'}}>Unassigned</TableCell>
            <TableCell align="right" sx={{fontWeight: 'bold'}}>Assigned</TableCell>
            <TableCell align="right" sx={{fontWeight: 'bold'}}>Closed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">{row.name}</TableCell>
              <TableCell align="center">{row.unassigned}</TableCell>
              <TableCell align="center">{row.in_progress}</TableCell>
              <TableCell align="center">{row.closed}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProgressTable;

