import { InspectionSerializer } from "../../../models/serializers/inspectionSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";


export class CommandGetInspections {

    INSPECTIONS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {
        return  `${this.INSPECTIONS_SERVICE}/inspections/`;
    }

    async run(provider_id: number | undefined,
              location_id: number | undefined,
              offset: number = 0,
              limit: number | undefined = undefined,
              include_regular=true,
              include_joint=true,
              include_baseline=true,
              employee: number | undefined = undefined,
              last_x_days: number | undefined = undefined,
              date_from: string | undefined = undefined,
              date_to: string | undefined = undefined,
              space: number | undefined = undefined,
              space_type: number | undefined = undefined
    ) {

        const url = this.getUrl()

        const params = {
            params: {
                provider: provider_id,
                location: location_id,
                offset: offset,
                limit: limit,
                include_regular: include_regular ? undefined : false,
                include_joint: include_joint ? undefined : false,
                include_baseline: include_baseline ? undefined : false,
                employee: employee,
                last_x_days: last_x_days,
                date_from: date_from,
                date_to: date_to,
                space: space,
                space_type: space_type,
            }
        }

        const tracer = useTracer.getState()
        tracer.trace('Inspections', 'Api', `GET ${url}`)

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            tracer.trace('Inspections', 'Error', `${error}`)
            console.error('Error fetching data:', error);
            throw error;
        }finally {
            tracer.trace('Inspections', 'Api', 'Success')
        }

    }

    deserialize(data) {
        const serializer = new InspectionSerializer()
        const inspections = serializer.deserializeList(data)
        return inspections
    }

}