import React from "react";
import PageHeader from "../../shared/components/page/headers/PageHeader";
import SpaceTypesDualViewer from "./desktop/components/space-type-dual-viewer/SpaceTypesDualViewer";
import { useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const SpaceTypesPage = () => {

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const {t} = useTranslation();

  return (
    <>
      <PageHeader
        title={t('space-types')}
        showAction={false}
      />
      {!isMobile && <SpaceTypesDualViewer />}
    </>
  );
}

export default SpaceTypesPage;





