import React, { useEffect, useRef, useState } from "react";
import { OrganizationChart } from 'primereact/organizationchart';
import { BreadCrumb } from 'primereact/breadcrumb';
import './styles/org-chart-stylesheet.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import useAppStore from "../../../appStore";
import { OrgChartService } from "../../../shared/services/org-chart/orgChartService";
import CustomLoadingDots from "../../../shared/components/page/popup-dialog/CustomLoadingDots";
import NodeTemplate from "./NodeTemplate";

const OrgChart = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const [fullOrgChart, setFullOrgChart] = useState([]);
  const [renderedOrgChart, setRenderedOrgChart] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const orgChartRef = useRef(null);

  const getName = (node) => {
    return node.employee.person.first_name ? node.employee.person.first_name : node.name;
  };

  useEffect(() => {
    const fetchOrgChart = async () => {
      setIsLoading(true);
      try {
        const service = new OrgChartService();
        const fetched = await service.getOrgChartNodes(user.organization?.id);
        setFullOrgChart(fetched);
        setRenderedOrgChart(fetched);
        setSelected(fetched[0]);
        setBreadcrumbs([{ label: getName(fetched[0]), id: fetched[0].id }]);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrgChart().then();
  }, [user, refresh]);

  useEffect(() => {
    if (selected) {
      const node = findNodeById(fullOrgChart, selected.id);
      if (node) {
        setRenderedOrgChart(limitDepth([node], 2));
        setBreadcrumbs(prevBreadcrumbs => {
          const existingIndex = prevBreadcrumbs.findIndex(breadcrumb => breadcrumb.id === node.id);
          if (existingIndex >= 0) {
            return prevBreadcrumbs.slice(0, existingIndex + 1);
          } else {
            return [...prevBreadcrumbs, { label: getName(node), id: node.id }];
          }
        });
      }
    }
  }, [selected, fullOrgChart]);

  useEffect(() => {
    const centerOrgChart = () => {
      const orgChartEl = orgChartRef.current;
      if (orgChartEl) {
        const scrollPosition = (orgChartEl.scrollWidth - orgChartEl.clientWidth) / 2;
        orgChartEl.scrollLeft = scrollPosition;
      }
    };
    centerOrgChart();

  }, [renderedOrgChart]);


  const handleSave = async (node, selectedEmployee) => {
    const service = new OrgChartService();

    if (node.employee?.id === selectedEmployee?.id || selectedEmployee?.id === null) {
      return;
    }

    if (node.employee?.id !== -1 && node.employee?.id !== selectedEmployee?.id) {
      try {
        await service.deleteAssignment(node.id, node.employee.id);
      } catch (error) {
        console.error(error);
      }
    }

    if (selectedEmployee?.id) {
      try {
        await service.createAssignment(node.id, selectedEmployee.id);
      } catch (error) {
        console.error(error);
      }
    }

    setRefresh(prev => !prev);
  };


  const findNodeById = (nodes, id) => {
    for (let node of nodes) {
      if (node.id === id) return node;
      if (node.children) {
        const found = findNodeById(node.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const limitDepth = (nodes, depth) => {
    if (depth === 1 || !Array.isArray(nodes)) {
      return nodes.map(node => ({
        ...node,
        childrenCount: node.children ? node.children.length : 0,
        children: []
      }));
    }
    return nodes.map(node => ({
      ...node,
      childrenCount: node.children ? node.children.length : 0,
      children: node.children ? limitDepth(node.children, depth - 1) : [],
    }));
  };


  const onBreadcrumbClick = (item, index) => {
    if (item.id) {
      setSelected({ id: item.id, label: item.label });
    }
  };

  const breadcrumbItems = breadcrumbs.map((item, index) => ({
    label: item.label,
    command: () => onBreadcrumbClick(item, index)
  }));


  if (isLoading) {
    return <CustomLoadingDots />;
  }

  return (
    <div>
      <BreadCrumb
        model={breadcrumbItems}
        className="custom-breadcrumb"
      />
      <div className="organizationchart-demo">
        <div ref={orgChartRef} className="card p-5">
          {renderedOrgChart && renderedOrgChart.length > 0 && (
            <OrganizationChart
              value={renderedOrgChart}
              nodeTemplate={(node) => <NodeTemplate node={node} setSelected={setSelected} childrenCount={node.childrenCount} onSave={handleSave} />}
              selectionMode="single"
              className="company"
            />
          )}
        </div>
      </div>
    </div>

  );
};

export default OrgChart;
