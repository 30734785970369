import { EmployeeSerializer } from "../../../models/serializers/employeeSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { ErrorSerializer } from "../../../models/serializers/errorSerializer";

export class CommandUpdateEmployee {
  EMPLOYEES_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(employeeId) {
    return `${this.EMPLOYEES_SERVICE}/employees/${employeeId}/`;
  }

  async run(employee) {
    const url = this.getUrl(employee.id);
    const tracer = useTracer.getState();
    tracer.trace('Employees', 'Api', `PUT ${url}`);

    try {
      const data = EmployeeSerializer.serialize(employee);
      const response = await httpClient.put(url, data);
      tracer.trace('Employees', 'Api', 'Success');
      return this.deserialize(response.data);
    } catch (error) {
      const errorSerializer = new ErrorSerializer();
      const serializedError = errorSerializer.deserialize(error);
      tracer.trace("Employees", "Error", `${serializedError.message}`);
      console.error("Error:", serializedError);
      throw serializedError;
    }
  }

  deserialize(data) {
    const serializer = new EmployeeSerializer();
    const item = serializer.deserialize(data);
    return item;
  }
}
