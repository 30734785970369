import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import i18n from "../../../../../../../../../../translation/i18n";

const ProgressBar = ({ progress, label, colorClass, subtext = "" }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const progressBarHeight = isMobile ? 'h-7px' : 'h-8px';
  const fontSizeClass = isMobile ? 'fs-8' : 'fs-7';

  return (
    <div className="progress-bar-container">
      <div className={`d-flex align-items-center flex-column`}>
        <div className={`d-flex justify-content-between ${fontSizeClass} w-100 mb-1`}>
          <span className="fw-bold" style={{color: 'gray'}}>{label}</span>
        </div>
        <div className={`mx-3 w-100 bg-light-danger rounded ${progressBarHeight}`}>
          <div className={`rounded ${progressBarHeight} ${colorClass}`} role="progressbar" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
      {subtext && (
        <div className="text-muted mt-1" style={{fontSize: '.85rem'}}>
          {subtext}
        </div>
      )}
    </div>
  );
};

export default ProgressBar;


