import React, { useState } from "react";
import SiteManagerDashboard from "./site-manager-dashboard/SiteManagerDashboard";
import EmployeeDashboard from "./employee-dashboard/EmployeeDashboard";
import useAppStore from "../../appStore";
import ClientDashboard from "./client-dashboard/ClientDashboard";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Divider, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const DashboardPage = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { t } = useTranslation();
  const managerMode = user?.permissions?.hasAccess("Manager Home") || false;
  const dashSelector = user?.permissions?.hasAccess("Dash Selector") || false;
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const [selectedOption, setSelectedOption] = useState(managerMode ? "Manager" : "Employee");

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  let DashboardComponent;
  if (selectedOption === 'Client') {
    DashboardComponent = ClientDashboard;
  } else if (selectedOption === 'Manager') {
    DashboardComponent = SiteManagerDashboard;
  } else {
    DashboardComponent = EmployeeDashboard;
  }

  return (
    <div style={{ width: isMobile ? "100%" : '50%', maxWidth: '500px' }}>
      {dashSelector &&
        <div>
          <ToggleButtonGroup
            value={selectedOption}
            exclusive
            onChange={handleOptionChange}
            aria-label="stepSelection"
          >
            <ToggleButton value="Employee">{t('employee')}</ToggleButton>
            <ToggleButton value="Client">{t('client')}</ToggleButton>
            <ToggleButton value="Manager">{t('manager')}</ToggleButton>
          </ToggleButtonGroup>
          <Divider sx={{ backgroundColor: 'black', mt: 2, mb: 2 }} />
        </div>
      }


      <DashboardComponent />
    </div>
  );
};

export default DashboardPage;





