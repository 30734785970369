import React from "react";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";
import { getServiceName, getTitle } from "../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import DuotoneCategory from "../../../../../../shared/components/page/row-icons/DuotoneCategory";
import { useSnapshot } from "valtio/index";
import { projectStore } from "../../../../projectsStore";

const ProjectsRow = (props) => {
    const {
        project,
        columns,
        onEdit,
        onDelete,
    } = props;

    const snap = useSnapshot(projectStore);

    return (
      <tr>
          {columns.map((col, colIndex) => (
            <td key={colIndex} className={col.tdClassName || ''}>
                {col.render(project, onEdit, onDelete, snap.mode)}
            </td>
          ))}
      </tr>
    )
}

export default ProjectsRow;