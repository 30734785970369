import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";

const ActionDropdownButton = ({ onClick, options }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const buttonRef = useRef<HTMLAnchorElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, right: 0 });

  useEffect(() => {
    if (showDropdown && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom,
        right: window.innerWidth - rect.right,
      });
    }
  }, [showDropdown]);

  const handleOptionClick = (option) => {
      onClick(option);
  };

  return (
    <div className="dropdown">
      <a
        ref={buttonRef}
        href="#"
        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
        onClick={(e) => {
          e.preventDefault();
          setShowDropdown(!showDropdown);
        }}
      >
        <i className="fa-solid fa-ellipsis-vertical fs-5"></i>
      </a>

      {/* Dropdown menu rendered using a portal */}
      {showDropdown &&
        createPortal(
          <ul
            className="dropdown-menu show"
            style={{
              position: "absolute",
              top: dropdownPosition.top,
              right: dropdownPosition.right, // Use the `right` property instead of `left`
              zIndex: 9999,
            }}
          >
            {options.map((option, index) => (
              <li key={index} className="dropdown-item">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOptionClick(option);
                    setShowDropdown(false);
                  }}
                >
                  {option}
                </a>
              </li>
            ))}
          </ul>,
          document.body
        )}
    </div>
  );
};

export default ActionDropdownButton;
