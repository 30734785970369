import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { LanguageSerializer } from "../../../models/serializers/languageSerializer";


export class CommandGetLanguages {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return  `${this.SERVICE}/language_translators/languages/`;
  }

  async run(provider_id) {

    const url = this.getUrl()

    const params = {
      params: {
        organization: provider_id,
      }
    }

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }

  }

  deserialize(data) {

    const serializer = new LanguageSerializer()
    const langs = serializer.deserializeList(data)

    return langs
  }

}