import React, { useCallback, useEffect, useState } from "react";
import useAppStore from "../../../../appStore";
import PageHeader from "../../../../shared/components/page/headers/PageHeader";
import { ServiceLogArea } from "../../../../shared/models/serviceLogArea";
import { ServiceLogService } from "../../../../shared/services/service-logs/serviceLogService";
import ActionButton from "../../../../shared/components/buttons/ActionButton";
import DuotoneCategory from "../../../../shared/components/page/row-icons/DuotoneCategory";
import ServiceLogViewer from "./components/ServiceLogViewer";
import { useMediaQuery, useTheme } from "@mui/material";
import { Box, Typography, CircularProgress } from '@mui/material';
import ManualLogEntry from "./components/ManualLogEntry";
import { useTranslation } from "react-i18next";

const LocationServiceLog = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { selectedLocation, appImages } = useAppStore();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const [log, setLog] = useState<ServiceLogArea[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logsOpen, setLogsOpen] = useState<boolean>(false);
  const [areaIdToView, setAreaIdToView] = useState<number>(0);
  const [selectorOpen, setSelectorOpen] = useState<boolean>(false);

  const fetchLog = useCallback(async () => {
    setIsLoading(true);
    try {
      const service = new ServiceLogService();
      const locations = await service.getLogList(selectedLocation.id);
      setLog(locations);
    } catch (err) {
      console.log(err instanceof Error ? err.message : String(err));
    } finally {
      setIsLoading(false);
    }
  }, [user.organization.id, selectedLocation.id]);

  useEffect(() => {
    fetchLog().then();
  }, [fetchLog]);

  const handleCloseLogs = () => {
    setLogsOpen(false);
    setAreaIdToView(0);
  }

  const handleCloseSelector = () => {
    setSelectorOpen(false);
  }

  return (
    <div className="card w-100">
      <div className="card-body" style={{ minHeight: '600px', width: '100%' }}>
        <PageHeader title={t('service-logs')} showAction={true} actionButtonName={t('entry')} onActionClicked={() => setSelectorOpen(true)} />
        <div className="card-body">
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : log.length === 0 ? (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
              <img src={appImages.iconNoData} alt="No log entries" style={{ width: '150px', marginBottom: '10px', marginTop: '50px' }} />
              <Typography variant="h6" sx={{ color: '#777' }}>
                No log entries yet!
              </Typography>
            </Box>
          ) : (
            <div className="table-responsive">
              <table className="table table-row-dashed align-middle gs-0 gy-3">
                {!isMobile && (
                  <thead>
                  <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                    <th className="p-0 pb-3 min-w-150px text-start">AREA</th>
                    <th className="p-0 pb-3 min-w-50px text-start px-3">IN SERVICE</th>
                    <th className="p-0 pb-3 min-w-50px text-start px-3">INSPECTION FREQUENCY</th>
                  </tr>
                  </thead>
                )}

                <tbody>
                {log.map((area) => (
                  <tr key={area?.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          <DuotoneCategory />
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                          <a className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                            {area?.entity?.name || ""}
                          </a>
                          {!isMobile &&
                            <span className="text-gray-400 fw-semibold d-block fs-7">
                                {`ID: ${area?.id}`}
                              </span>
                          }
                        </div>
                      </div>
                    </td>

                    {!isMobile && (
                      <>
                        <td className="text-start">
                          <div className="d-flex align-items-center">
                            {area?.entity?.in_service ? "Yes" : "No"}
                          </div>
                        </td>
                        <td className="text-start">
                          <div className="d-flex align-items-center">
                            {`Every ${area?.entity?.inspection_frequency} days` || ""}
                          </div>
                        </td>
                      </>
                    )}

                    <td className="text-center">
                      <div className="d-flex justify-content-center flex-shrink-0">
                        <ActionButton
                          iconType="view"
                          onClick={(e) => {
                            setAreaIdToView(area?.id || 0);
                            setLogsOpen(true);
                            e.preventDefault();
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      {logsOpen && (
        <ServiceLogViewer open={logsOpen} onActionClicked={handleCloseLogs} id={areaIdToView} />
      )}
      {selectorOpen && (
        <ManualLogEntry open={selectorOpen} onClose={handleCloseSelector} />
      )}
    </div>
  );
};

export default LocationServiceLog;

