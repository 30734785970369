import React from 'react'
import PageHeader from "../../shared/components/page/headers/PageHeader";
import icons from "../../../_metronic/helpers/icons-config/icons";
import { KTIcon } from "../../../_metronic/helpers";


const IconReference = () => {


    return (
        <>
            <PageHeader title='KPI Icons'
                        showAction={false} />

            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
                {Object.keys(icons).map((iconName) => (
                  <div key={iconName} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <KTIcon iconName={iconName} />
                      <span>{iconName}</span>
                  </div>
                ))}
            </div>
        </>
    )


}

export default IconReference
