import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { TraceSerializer } from "../../../models/serializers/traceSerializer";

export class CommandGetTraceDetails {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id) {
    return `${this.SERVICE}/traces/${id}/`;
  }

  async run(id) {

    const url = this.getUrl(id)

    try {
      const response = await httpClient.get(url);
      return this.deserialize(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }

  }

  deserialize(data) {

    const serializer = new TraceSerializer()
    const trace = serializer.deserialize(data)
    return trace
  }

}

