import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Divider,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, useTheme, useMediaQuery
} from "@mui/material";
import { UserService } from "../../../../../../../shared/services/users/userService";
import useAppStore from "../../../../../../../appStore";
import { Role } from "../../../../../../../shared/models/role.model";
import { AppUserModel } from "../../../../../../../shared/models/appUserModel";
import LocationSelectionForm from "./selectors/LocationSelectionForm";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { formatPhoneNumber } from "../../../../../../../shared/utils/data-transform/dataUtil";

const UserPermissionsPanel = ({ profile, includeRole=true }) => {
  const userService = new UserService();
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);

  const [locationDialogOpen, setLocationDialogOpen] = useState(false);
  const [roles, setRoles] = useState<any>([]);
  const [role, setRole] = useState(new Role());
  const [isActive, setIsActive] = useState(false);
  const [permissionLocations, setPermissionLocations] = useState<any>([]);
  const [permissions, setPermissions] = useState<any>([]);
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const data = await userService.getRoles(user.organization?.id);
        setRoles(data);
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles().then();
  }, [user.organization?.id]);

  useEffect(() => {
    const setupEmployeeDefaults = () => {
      if (!profile.user) {
        profile.user = new AppUserModel();
      }

      const initialLocations = profile.user.permission_profiles?.[0]?.locations || [];
      const initialRole = profile.user.permission_profiles?.[0]?.role || new Role();
      const initialActiveStatus = profile?.user?.is_active || false;

      setPermissionLocations(initialLocations);
      setRole(initialRole);
      setIsActive(initialActiveStatus);
    };

    setupEmployeeDefaults();
  }, [profile]);

  useEffect(() => {
    if (profile.user) {
      profile.user.role = role;

      if (profile.user.permission_profiles?.length > 0) {
        profile.user.permission_profiles[0] = {
          ...profile.user.permission_profiles[0],
          locations: permissionLocations,
          role: role,
        };
      } else if (isActive) {
        profile.user.permission_profiles = [
          {
            locations: permissionLocations,
            role: role,
          },
        ];
      }
    }
  }, [role, permissionLocations, isActive, profile.user]);

  const handleRoleChange = (event) => {
    const selectedRole = roles.find((roleItem) => roleItem.name === event.target.value);
    setRole(selectedRole || new Role());
    setPermissions(selectedRole?.permissions || []);
  };

  const handleIsActiveSwitch = (event) => {
    const isActive = event.target.checked;
    setIsActive(isActive);

    if (profile.user) {
      profile.user.is_active = isActive;
    }
  };

  const renderPermissionLevel = (level) => {
    return level ? <CheckIcon color="primary" /> : <CloseIcon color="error" />;
  };

  return (
    <Box className="d-flex flex-column mt-6" sx={{ backgroundColor: isActive ? '#ffffff' : '#F0F0F0', borderRadius: '5px', justifyContent: 'space-between', border: '1px solid #e8e8e8' }}>
      <Box className="d-flex flex-row" sx={{ justifyContent: 'space-between' }}>
        <Typography sx={{ fontWeight: 'bold', padding: '15px' }}>
          User Permissions
        </Typography>
        <FormControlLabel
          control={<Switch checked={isActive} onChange={handleIsActiveSwitch} name="isUserToggle" color="primary" />}
          label=""
        />
      </Box>
      <Divider />
      {profile.user.phone_number?.length > 0 &&
        <Box sx={{
          p: 1,
          marginLeft: '10px',
          border: 1,
          borderColor: 'grey.300',
          borderRadius: 1,
          display: 'inline-block',
          width: isMobile ? '40%' : '30%',
        }}>

          <Typography>Using Phone Number</Typography>
          <Typography variant="body1">
            <strong>{formatPhoneNumber(profile.user.phone_number)}</strong>
          </Typography>

        </Box>
      }
      <Box className="p-4">
        {includeRole &&
          <FormControl fullWidth margin="normal" disabled={!isActive}>
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              name="role"
              value={role.name}
              onChange={handleRoleChange}
              label="Role"
            >
              {roles.map((roleItem) => (
                <MenuItem key={roleItem.id} value={roleItem.name}>
                  {roleItem.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        {permissions.length > 0 && (
          <Box className="mt-3">
            <Typography variant="h6">Permissions:</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Permission</TableCell>
                  <TableCell align="center">View</TableCell>
                  <TableCell align="center">Create</TableCell>
                  <TableCell align="center">Edit</TableCell>
                  <TableCell align="center">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {role.name === "Super Admin" ? (
                  <TableRow>
                    <TableCell>All</TableCell>
                    <TableCell align="center"><CheckIcon color="primary" /></TableCell>
                    <TableCell align="center"><CheckIcon color="primary" /></TableCell>
                    <TableCell align="center"><CheckIcon color="primary" /></TableCell>
                    <TableCell align="center"><CheckIcon color="primary" /></TableCell>
                  </TableRow>
                ) : (
                  permissions.map((permission) => (
                    <TableRow key={permission.id}>
                      <TableCell>{permission.name}</TableCell>
                      <TableCell align="center">{renderPermissionLevel(permission.permission_level?.includes('View'))}</TableCell>
                      <TableCell align="center">{renderPermissionLevel(permission.permission_level?.includes('Create'))}</TableCell>
                      <TableCell align="center">{renderPermissionLevel(permission.permission_level?.includes('Edit'))}</TableCell>
                      <TableCell align="center">{renderPermissionLevel(permission.permission_level?.includes('Delete'))}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </Box>
        )}
      </Box>
      <Box className="p-4 d-flex flex-row">
        <Box sx={{ p: 1, border: 1, borderColor: 'grey.300', borderRadius: 1, display: 'inline-block', width: isMobile ? '40%' : '30%', textAlign: 'center' }}>
          <Typography variant="body1">
            <strong>{permissionLocations.length}</strong> Locations Selected
          </Typography>
        </Box>
        <Button variant="contained" color="primary" disabled={!isActive} onClick={() => setLocationDialogOpen(true)} style={{width: isMobile ? '60%' : '30%', marginLeft: '10px'}}>
          Select Locations...
        </Button>
      </Box>
      {locationDialogOpen && (
        <LocationSelectionForm accessLocations={permissionLocations} setAccessLocations={setPermissionLocations} open={locationDialogOpen} onClose={() => setLocationDialogOpen(false)} />
      )}
    </Box>
  );
};

export default UserPermissionsPanel;

