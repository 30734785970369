import React from 'react';
import NavigationIcon from '@mui/icons-material/Navigation';
import RectangleIcon from '@mui/icons-material/Rectangle';
import i18n from "../../../../../translation/i18n";

const getColorForPosition = (position) => {
  const thresholds = [
    { upTo: 10, color: '#ef5350' },
    { upTo: 20, color: '#e57373' },
    { upTo: 30, color: '#ffa726' },
    { upTo: 40, color: '#ffb74d' },
    { upTo: 50, color: '#ffd54f' },
    { upTo: 60, color: '#dce775' },
    { upTo: 70, color: '#aed581' },
    { upTo: 80, color: '#81c784' },
    { upTo: 90, color: '#66bb6a' },
    { upTo: 100, color: '#4caf50' }
  ];

  const threshold = thresholds.find(thresh => position <= thresh.upTo);
  return threshold ? threshold.color : '#FFFFFF';

};

const getTextForPosition = (position) => {
  const thresholds = [
    { upTo: 25, text: 'Needs Serious Attention' },
    { upTo: 75, text: 'Needs Improvement' },
    { upTo: 90, text: 'Pretty Good' },
    { upTo: 100, text: 'Verde Vibed' },
  ];

  const threshold = thresholds.find(thresh => position <= thresh.upTo);

  return threshold ? threshold.text : 'Pretty Good';

};

const SageScoreSlider = ({ score, hideText=false }) => {
  const progress = 100 - score;
  const adjustedScore = progress === 100 ? 95 : progress === 0 ? 5 : Math.round(progress / 5) * 5;
  const scorePosition = (adjustedScore / 100) * 100;

  const segments = new Array(10).fill(null).map((_, index) => ({
    backgroundColor: getColorForPosition((index + 1) * 10),
    isScorePosition: scorePosition > index * 10 && scorePosition <= (index + 1) * 10,
  }));

  const language = i18n.language || 'en';

  return (
    <div className="progress-bar-container">
      <div className="d-flex align-items-center flex-column">
        {!hideText &&
          <div className={`d-flex justify-content-between w-100 mb-1`} style={{ fontSize: '.90rem' }}>
            <span className="fw-bold" style={{ color: 'gray' }}>{progress}/100 {language === 'en' ? `- ${getTextForPosition(progress)}` : ''}</span>
          </div>
        }
        <div className="mx-3 w-100 rounded" style={{ position: 'relative', height: '25px', backgroundColor: '#F0F0F0' }}>
          {segments.map((segment, index) => (
            <div
              key={index}
              style={{
                backgroundColor: segment.backgroundColor,
                border: '1px solid #666',
                width: '10%',
                position: 'absolute',
                left: `${index * 10}%`,
                height: '25px',
              }}
            />
          ))}
          <div style={{
            position: 'absolute',
            left: `${scorePosition}%`,
            transform: 'translateX(-50%)',
            display: 'flex',
            borderRadius: '50%',
            top: '25px',
            height: '20px',
            width: '8%',
            alignItems: 'center',
            flexDirection: 'column'
          }}>
            <NavigationIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SageScoreSlider;
