import React, { useEffect, useState } from "react";
import { Box, Card, Divider, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import ScoreTable from "../../../../../../shared/components/page/tables/ScoreTable";
import useAppStore from "../../../../../../appStore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AnalyticsService } from "../../../../../../shared/services/analytics/analyticsService";
import { Question } from "../../../../../../shared/models/question.model";
import { Respondent } from "../../../../../../shared/models/respondent.model";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomerSurveyTableTabs from "./CustomerSurveyTableTabs";
import { ReportingGroupScore } from "../../../../../../shared/models/reportingGroupScore.model";

const CustomerSurveyTierTable = ({ data }) => {

  const service = new AnalyticsService()
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const {whiteLabelProps,selectedLocation} = useAppStore();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [respondents, setRespondents] = useState<Respondent[]>([]);
  const [selectedTier, setSelectedTier] = useState<ReportingGroupScore | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const respondentsData = await service.getRespondents(user.organization, selectedLocation.id, selectedTier?.id, 'CSat');
        const questionsData = await service.getQuestions(user.organization, selectedLocation.id, selectedTier?.id, 'CSat');
        const sortedRespondents = respondentsData.sort((a, b) => a.avg_score - b.avg_score);
        const sortedQuestions = questionsData.sort((a, b) => a.avg_score - b.avg_score);
        setRespondents(sortedRespondents);
        setQuestions(sortedQuestions);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedTier) {
      fetchData().then();
    }

  }, [user, selectedLocation.id, selectedTier]);

  return (
    <>
      <div className='card card-flush h-md-100 mt-5 mb-10' style={{minHeight: '400px', boxShadow: '0 1px 2px rgba(0,0,0,0.1)'}}>
        <div style={{ display: 'flex', flexDirection: isMobile ? 'row' : 'column' }}>

          <CustomerSurveyTableTabs data={data} setSelectedTier={setSelectedTier} selectedTier={selectedTier} />

          {!isMobile &&
            <Divider flexItem style={{ backgroundColor: "#e8e8e8" }} />
          }

          <div style={{ width: '100%', display: isMobile ? selectedTier ? "" : "none" : "" }}>
            {selectedTier !== null ? (
              <div>
                <Card sx={{ boxShadow: 'none', border: '1px solid #e8e8e8', borderTopLeftRadius: '0px' }}>
                  {isMobile &&
                    <Box className="d-flex flex-row justify-content-between align-items-center"
                         sx={{ padding: 2, backgroundColor: "primary.main", color: "primary.contrastText" }}>
                      <div className='d-flex flex-row align-items-center'>
                        <IconButton edge="start" color="inherit" onClick={() => setSelectedTier(null)} aria-label="close">
                          <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ marginLeft: '5px', fontWeight: 'bold' }}>
                          {selectedTier.name}
                        </Typography>
                      </div>
                    </Box>
                  }

                  {isLoading ? (
                    <CustomLoadingDots />
                  ) : (
                    <div style={{ padding: "20px" }}>
                      <ScoreTable title="Question Averages" data={questions} columns={["question", "avg_score"]} />
                      <ScoreTable title="Respondent Averages" data={respondents} columns={["name", "avg_score"]} />
                    </div>
                  )}
                </Card>
              </div>
            ) : (
              <div style={{ flex: 1, textAlign: "center", padding: "20px", marginTop: "100px" }}>
                <p style={{
                  fontSize: "20px",
                  fontWeight: "semibold",
                  marginBottom: "0px", color: whiteLabelProps.primary_color
                }}>
                  {data.length > 0 ? 'Select a Tier' : 'No Survey Data'}
                </p>
                <p style={{ fontSize: "15px", color: 'gray' }}>{data.length > 0 ? 'to view breakdown' : 'for selected charts'}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );

}

export default CustomerSurveyTierTable