import React from "react";
import InspectionCard from "./components/card/InspectionCard";
import { useWorkItem } from "../../../work-items/context/workItemContext";
import { WorkItem } from "../../../../shared/models/workItem.model";
import EditWorkItem from "../../../work-items/components/edit-work-item/EditWorkItem";

const InspectionsCardList = ({ inspections }) => {

  const {
    workItemToEdit, setWorkItemToEdit,
    setEditing, open, setOpen,
    setSnackbarOpen
  } = useWorkItem();

  const handlerWorkItemEditorAction = (action) => {
    setEditing(false);
    setOpen(false);
    setWorkItemToEdit(new WorkItem());
    if (action === 'save') {
      setSnackbarOpen(true);
    }
  }

  return (
    <>
      {inspections.length === 0 ? (
        <div style={{ marginTop: '25px', marginBottom: '10px' }}>Nothing to show</div>
      ) : (
        inspections.map((inspection) => (
            <InspectionCard key={inspection.id} inspection={inspection} />
        ))
      )}
      {open && workItemToEdit &&
        <EditWorkItem
          open={open}
          onActionClicked={handlerWorkItemEditorAction}
        />
      }
    </>
  );
};

export default InspectionsCardList;

