import React from "react";
import {
  AppBar, Button, Dialog, Grid, IconButton, Toolbar, Typography
} from "@mui/material";
import { Card } from "react-bootstrap";
import { Close as CloseIcon } from "@mui/icons-material";
import ModalTransition from "../../../../../../shared/components/page/transitions/ModalTransition";
import SearchableTreeView from "../../../../../../shared/components/forms/widgets/searchable-tree-view/SearchableTreeView";
import useTrainingStore from "../../../trainingStore";
import { TrainingService } from "../../../../../../shared/services/training/trainingService";
import useAppStore from "../../../../../../appStore";

const CourseActionModal = ({ open, action, course, onClose }) => {

  const [destination, setDestination] = React.useState(null);
  const { trainingCategories, selectedCategory, setSelectedCategory } = useTrainingStore();
  const { toggleMasterRefresh } = useAppStore();

  const handleCompleteAction = async () => {
    if (action === 'Duplicate') {
      course.categories = [...course.categories, destination];
    } else if (action === 'Delete' && selectedCategory !== null) {
      course.categories = course.categories.filter(cat => cat.id !== selectedCategory.id);
    } else if (action === 'Move' && selectedCategory !== null) {
      course.categories = course.categories.filter(cat => cat.id !== selectedCategory.id);
      course.categories.push(destination);
    }
    try {
      const service = new TrainingService();
      await service.updateCourse(course);
      if (destination !== null) {
        setSelectedCategory(destination)
      }
    } catch (e) {
      console.error('Error in handleSubmit:', e);
    } finally {
      toggleMasterRefresh();
      onClose();
    }
  };

  const handleSelect = (dest) => {
    setDestination(dest);
  };

  return (
    <Dialog fullWidth maxWidth={"sm"} open={open} onClose={onClose} TransitionComponent={ModalTransition}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {action} Course
          </Typography>
        </Toolbar>
      </AppBar>

      <div className="card">
        <div className="card-body">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card className="mb-3">
                <Card.Body>
                  {action === 'Delete' ? (
                    <Typography variant="body1" align="center">
                      Are you sure you want to delete this course from the selected category? This action cannot be undone.
                    </Typography>
                  ) : (
                    <SearchableTreeView
                      text={'Select Destination'}
                      data={trainingCategories}
                      onItemSelected={handleSelect}
                      itemToEdit={null}
                      showNone={false}
                    />
                  )}
                </Card.Body>
              </Card>
            </Grid>
          </Grid>
          <div
            style={{
              position: 'relative',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
              width: '100%'
            }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCompleteAction}
              sx={{ width: "100%", height: '50px', borderRadius: 0 }}
              disabled={action !== 'Delete' && !destination}
            >
              {action}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CourseActionModal;
