import React, {useEffect, useState} from 'react'
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import useInspectionsStore from '../../../../../../../inspectionsStore';
import { ServicePlanService } from '../../../../../../../../../shared/services/service-plans/servicePlanService';
import InspectionPointRow from "./InspectionPointRow";
import { InspectionServiceItemScore } from "../../../../../../../../../shared/models/inspectionServiceItemScore.model";
import CustomLoadingDots from "../../../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../../../../shared/components/page/popup-dialog/CustomError";
import { useTranslation } from "react-i18next";


const InspectionPoints = () => {

    const {
      inspectedEntity,
    } = useInspectionsStore()

  const {t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [serviceItems, setServiceItems] = useState<any>([]);


  const handleScoreChange = (id, newScore) => {
    const updatedServiceItems = serviceItems.map(item => {
      if (item.id === id) {
        return { ...item, score: newScore, is_wow: false };
      }
      return item;
    });

    setServiceItems(updatedServiceItems);
    inspectedEntity.service_item_scores = updatedServiceItems;
  };

  const handleWowChange = (id, isWow) => {
    const updatedServiceItems = serviceItems.map(item => {
      if (item.id === id) {
        return { ...item, is_wow: isWow };
      }
      return item;
    });

    setServiceItems(updatedServiceItems);
    inspectedEntity.service_item_scores = updatedServiceItems;

  };

  const handleReasonChange = (id, reasons) => {
    const updatedServiceItems = serviceItems.map(item => {
      if (item.id === id) {
        return { ...item, inspection_points: reasons };
      }
      return item;
    });

    setServiceItems(updatedServiceItems);
    inspectedEntity.service_item_scores = updatedServiceItems;
  };


  useEffect(() => {

    const fetchServiceItems = async () => {

      setIsLoading(true);

      try {

        const service = new ServicePlanService();
        const items = await service.getServiceItems(inspectedEntity.entity_type.id);

        inspectedEntity.service_item_scores = []

        let fake_id = -1;

        items.map((item) => {

            let score = new InspectionServiceItemScore()
            score.id = fake_id--;
            score.service_item = item
            score.inspection_points = item.inspection_points
            inspectedEntity.service_item_scores.push(score)
        })

        setServiceItems(inspectedEntity.service_item_scores)

      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      }finally {
        setIsLoading(false);
      }
    };

    if( inspectedEntity.id === null || inspectedEntity.id == -1){
        fetchServiceItems().then();
    }else{
      setServiceItems(inspectedEntity.service_item_scores)
    }

  }, [inspectedEntity.entity_type, inspectedEntity]);


  if (isLoading) {
    return <CustomLoadingDots />
  }
  if (error) {
    return <CustomError error={error} />;
  }

  return (
    <div className="d-flex flex-column">
      {inspectedEntity.service_item_scores.length == 0 && (
        <div>
          No details found for this space.
        </div>
      )}
      {inspectedEntity.service_item_scores.length > 0 && (
        <>
          <div className="d-flex flex-row" style={{ width: "100%" }}>
            <div style={{ width: "35%", alignItems: "center", padding: "2px", textAlign: "center" }} />
            <div style={{ width: "20%", alignItems: "center", padding: "2px", textAlign: "center" }}>
              <label><b>{t('miss')}</b></label>
            </div>
            <div style={{ width: "20%", alignItems: "center", padding: "2px", textAlign: "center" }}>
              <label><b>{t('meets')}</b></label>
            </div>
            <div className="d-flex justify-content-end pe-6" style={{ width: "25%", alignItems: "center", padding: "2px", textAlign: "center" }}>
              <label><b>{t('wow')}</b></label>
            </div>
          </div>
        </>
      )}
      {serviceItems.map((serviceItem) => (

        <InspectionPointRow key={serviceItem.id}
                            serviceItem={serviceItem}
                            handleScoreChange={handleScoreChange}
                            handleWowChange={handleWowChange}
                            handleReasonChange={handleReasonChange}
            />
          ))}

        </div>
  )
};

export default InspectionPoints;