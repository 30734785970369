import { Person } from "./person.model";
import { Employee } from "./employee.model";

export class User {
  id: number;
  employee: Employee;
  person: Person;


  constructor() {
    this.id = 0;
    this.employee = new Employee();
    this.person = new Person();
  }

}